const LANG:any = {
  kr: {
    t: { 
      "파일이_선택되지_않았습니다." : "파일이 선택되지 않았습니다.",
      "파일을_삭제합니까?" : "파일을 삭제합니까?",
      "해당_파일은" : "해당 파일은 ",
      "스페이스_에서_사용중입니다._공유_해제_후_삭제해주세요." : " 스페이스 에서 사용중입니다.\n공유 해제 후 삭제해주세요.",
      "요청한_파일이_존재하지_않습니다." : "요청한 파일이 존재하지 않습니다.",
      "파일을_하나씩_업로드_해주세요." : "파일을 하나씩 업로드 해주세요.",
      "파일을_업로드_할까요?" : "파일을 업로드 할까요?",
      "업로드_가능한_파일_형식이_아닙니다." : "업로드 가능한 파일 형식이 아닙니다.",
      "썸네일_이미지_생성_중입니다." : "썸네일 이미지 생성 중입니다.",
      "업로드_성공." : "업로드 성공",
      "중복된_파일입니다." : "중복된 파일입니다.",
      "업로드가_불가능한_파일입니다." : "업로드가 불가능한 파일입니다.",
      "선택한_파일을_공유합니다." : "선택한 파일을 공유합니다.",
      "잠시_후_시도해주세요." : "잠시 후 시도해주세요.",
      "웹뷰가_없거나,_웹뷰_처리에서_문제가_발생했습니다." : "웹뷰가 없거나, 웹뷰 처리에서 문제가 발생했습니다.",
      "파일을_검색하세요" : "파일을 검색하세요",
      "팝업_창을_닫습니까?" : "팝업 창을 닫으시겠습니까?",
      "이미지" : "이미지",
      "자세히" : "자세히",
      "정렬" : "정렬",
      "이름" : "이름",
      "확장자" : "확장자",
      "용량" : "용량",
      "업로드_시간" : "업로드시간",
      "오름차순" : "오름차순",
      "내림차순" : "내림차순",
      "지원_확장자" : "지원 확장자",
      "3D_지원_확장자" : "3D 지원 확장자",
      "확인" : "확인",
      "취소" : "취소",
      "사용량" : "사용량",
      "서버와의_통신_오류가_발생했습니다." : "서버와의 통신 오류가 발생했습니다.",
      "파일을_업로드_해주세요" : "파일을 업로드 해주세요",
      "확인되지_않은_사용자입니다." : "확인되지 않은 사용자입니다.",
      "업로드_용량이_초과되어_현재_파일을_업로드할_수_없습니다." : "업로드 용량이 초과되어 현재 파일을 업로드할 수 없습니다.",
      "검색결과가_없습니다." : "검색결과가 없습니다.",
      "입력하신_파일명을_확인해주세요.":"입력하신 파일명을 확인해주세요."
    }
  },
  en: {
    t: {
      "파일이_선택되지_않았습니다." : "No file selected.",
      "파일을_삭제합니까?" : "Are you sure you want to delete this file?",
      "해당_파일은" : "The action can't be completed because this file is used in another Space ",
      "스페이스_에서_사용중입니다._공유_해제_후_삭제해주세요." : ".\nPlease unshare the file and delete it.",
      "요청한_파일이_존재하지_않습니다." : "The requested file could not be found.",
      "파일을_하나씩_업로드_해주세요." : "Only 1 file can be uploaded at the time.",
      "파일을_업로드_할까요?" : "Are you sure you want to upload this file?",
      "업로드_가능한_파일_형식이_아닙니다." : "This is an unsupported file format.",
      "썸네일_이미지_생성_중입니다." : "Thumbnail is being created.",
      "업로드_성공.": "Upload successful",
      "중복된_파일입니다." : "The file already exists.",
      "업로드가_불가능한_파일입니다." : "The file could not be uploaded.",
      "선택한_파일을_공유합니다." : "The selected file will be shared.",
      "잠시_후_시도해주세요." : "Please try again later.",
      "웹뷰가_없거나,_웹뷰_처리에서_문제가_발생했습니다." : "The Webview encountered an internal error and was unable to complete your request.",
      "파일을_검색하세요" : "Search",
      "팝업_창을_닫습니까?" : "Are you sure you want to delete this popup?",
      "이미지": "Image",
      "자세히": "Details",
      "정렬": "Sort by",
      "이름": "Name",
      "확장자": "Formats",
      "용량": "Size",
      "업로드_시간": "Uploaded Time",
      "오름차순": "Ascending",
      "내림차순": "Descending",
      "지원_확장자": "Supported formats",
      "3D_지원_확장자": "Supported 3D formats",
      "확인" : "OK",
      "취소" : "Cancel",
      "사용량": "File usage",
      "서버와의_통신_오류가_발생했습니다." : "There was an error communicating with the server.",
      "파일을_업로드_해주세요": "Upload a file here",
      "확인되지_않은_사용자입니다." : "User is undefined.",
      "업로드_용량이_초과되어_현재_파일을_업로드할_수_없습니다." : "File usage has been exceeded. Could'nt upload files.",
      "검색결과가_없습니다." : "No results found.",
      "입력하신_파일명을_확인해주세요.":"Check the file name and try again."
      }
  },
  jp: {
    t: {
      "파일이_선택되지_않았습니다." : "ファイルが選択されていません。",
      "파일을_삭제합니까?" : "ファイルを削除しますか？",
      "해당_파일은" : "このファイルは ",
      "스페이스_에서_사용중입니다._공유_해제_후_삭제해주세요." : " スペースで使用中です。\n共有解除後、削除してください。",
      "요청한_파일이_존재하지_않습니다." : "要求されたファイルが存在しません。",
      "파일을_하나씩_업로드_해주세요." : "ファイルを一つずつアップロードしてください。",
      "파일을_업로드_할까요?" : "ファイルをアップロードしますか？",
      "업로드_가능한_파일_형식이_아닙니다." : "アップロード可能なファイル形式ではありません。",
      "썸네일_이미지_생성_중입니다." : "サムネイル画像を作成中です。",
      "업로드_성공.": "アップロード成功",
      "중복된_파일입니다." : "重複したファイルです。",
      "업로드가_불가능한_파일입니다." : "アップロードできないファイルです。",
      "선택한_파일을_공유합니다." : "選択したファイルを共有します。",
      "잠시_후_시도해주세요." : "しばらくしてから試してください。",
      "웹뷰가_없거나,_웹뷰_처리에서_문제가_발생했습니다." : "ウェブビューがないか、ウェブビュー処理で問題が発生しました。",
      "파일을_검색하세요" : "ファイルを検索してください。",
      "팝업_창을_닫습니까?" : "ポップアップウィンドウを閉じますか？",
      "이미지": "イメージ",
      "자세히": "詳細",
      "정렬": "整列",
      "이름": "名前",
      "확장자": "拡張子",
      "용량": "容量",
      "업로드_시간": "アップロード時間",
      "오름차순": "昇順",
      "내림차순": "降順",
      "지원_확장자": "サポート拡張子",
      "3D_지원_확장자": "3Dサポート拡張子",
      "확인" : "確認",
      "취소" : "取り消し",
      "사용량": "使用量",
      "서버와의_통신_오류가_발생했습니다." : "サーバーとの通信エラーが発生しました。",
      "파일을_업로드_해주세요": "ファイルをアップロードしてください",
      "확인되지_않은_사용자입니다." : "確認されていないユーザーです。",
      "업로드_용량이_초과되어_현재_파일을_업로드할_수_없습니다." : "アップロード容量が超過したため、現在ファイルをアップロードできません。",
      "검색결과가_없습니다." : "検索結果がありません。",
      "입력하신_파일명을_확인해주세요.":"入力したファイル名を確認してください。"
      }
  },
};
export default LANG;