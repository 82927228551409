// libraries
import styled from 'styled-components';

export const StyledHeaderTitle = styled.h3`
    border-bottom: 2px solid red;
    margin-right: 65%;
    padding-bottom: 2px;
    font-weight: 700;
    margin-bottom: 50px;
`;

export const StyledFileListWrapper = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    flex-wrap: wrap;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
`;
