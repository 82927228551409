import React, { forwardRef, Ref, HTMLAttributes } from 'react';

import { useTranslation } from 'react-i18next';

import CheckMobile from 'components/utils/MobileCheck';

import { StyledAbout } from './style';

import AboutLogo from './images/about_logo.png';
import fullVideo from '../main/videos/MXspace_1080.mp4';

interface AboutSectionProps extends HTMLAttributes<HTMLDivElement> {}

const AboutSection = forwardRef((props: AboutSectionProps, ref: Ref<HTMLDivElement>) => {
  const { t } = useTranslation();

  const isMobile = CheckMobile();

  return (
    <StyledAbout ref={ref}>
      <div className="aboutWrapper">
        <div className="aboutTitleWrapper">
          <div className="logo">
            <p>MOVE TO</p>
            <img src={AboutLogo} alt="" />
          </div>
          <p
            className="aboutText"
            dangerouslySetInnerHTML={{
              __html: isMobile ? t('renew_about_mb_text') : t('renew_about_pc_text'),
            }}
          />
        </div>

        <div className="videoWrapper">
          <video
            src={fullVideo}
            controls
            controlsList="nodownload"
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline
          />
        </div>
      </div>
    </StyledAbout>
  );
});

export default AboutSection;
