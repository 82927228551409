import axios from 'axios';
import {USER_LOGIN, USER_REGISTER, USER_LOGOUT, checkResultCodeSuccess} from './types';
import { initialState } from '../_reducers/UserReducer'
import { alertMessage } from '../components/utils/utils';

export async function loginUser(dataToSubmit: { userid: string; password: string; }) {
    const req =  await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/user/auth/login`, dataToSubmit);
    return {
        type : USER_LOGIN,
        payload : {userData : req.data.user || initialState , loginStatus : checkResultCodeSuccess(req.data.resultCode)}
    }
}

export async function logoutUser(){
    const req =  await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/user/auth/logout`);
    return {
        type : USER_LOGOUT,
        payload : checkResultCodeSuccess(req.data.resultCode)
    }
}
export async function authenticateCheck() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/user/auth/authenticateCheck`);
}

export function registerUser(dataToSubmit: { userId: string; password: string; name: string; }) : any {
    const request = { 
        payload: { 
            result: 1
        }
    }
    return {
        type: USER_REGISTER,
        payload: request
    }
}


