export function getReadableFileSize(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0).toFixed(1) + byteUnits[i];
};

export function getReadableMBFileSize(fileSizeInBytes) {
    fileSizeInBytes = fileSizeInBytes / (1024 * 1024);
    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + 'MB';
};

export const getByteString = (s,b,i,c)=>{
    //한글을 3바이트로
    // for(b=i=0;c=s.charCodeAt(i++);b+=c>>11?3:c>>7?2:1);
    //한글을 2바이트로
    for(b=i=0;c=s.charCodeAt(i++);b+=c>>7?2:1);
    return b;
};
export const sliceByByte = (s,maxByte,b,i,c) => {
	for(b=i=0;c=s.charCodeAt(i++);) {
      b+=c>>7?2:1;
      if (b > maxByte)
      break;
    }
  	return s.substring(0,i);
}
export const getFileExtension = (fileName) => {
  let fileDot = fileName.lastIndexOf('.');
  let fileExtension = fileName.substring(fileDot+1)
  return fileExtension
}
export const getOnlyFileName = (fileName) => {
  let fileDot = fileName.lastIndexOf('.');
  let fileOnlyName = fileName.substring(0, fileDot)
  return fileOnlyName
}
export const arrayToStringJoinComma = (arr) => {
  if(arr.length<1) return '[스페이스] 룸';
  if(arr.length===1) return '['+arr[0]+'] 룸';
  if(arr.length>1) return '['+arr.join('], [')+'] 룸';
}

export const isEmail = email => {
    const emailRegex = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
    // = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    return emailRegex.test(email);
};
export const checkPassword = (str) => {
    let checkNum = /[0-9]/;
    let checkEngC = /[A-Z]/;
    let checkEng = /[a-z]/;
    let checkSpc = /[~!@#$%^&*()_+|<>?:{}]/;
    // let checkKor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    if((str.length>=6) && (str.length<=12) && (checkNum.test(str)) && (checkEngC.test(str)) && (checkEng.test(str)) && (checkSpc.test(str)) ){
        return true;
    } else {
        return false;
    }
}
export const onlyKrAndEng = (str) => {
    let checkKor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/
    let checkEngC = /[A-Z]/;
    let checkEng = /[a-z]/;
    if((checkKor.test(str)) || (checkEngC.test(str)) || (checkEng.test(str))){
        return true;
    } else {
        return false;
    }
}
export const checkBlank = (str) => {
    var blank_pattern = /[\s]/g;
    if(blank_pattern.test(str.value)){
        return false
    } else {
        return true
    }
}
export const onlyKr = (str) => {
    let checkKor = /[가-힣]/;
    let tempArr = str.split('');
    let isCorrect = true;
    for(let i = 0; i < tempArr.length; i++){
        if(!(checkKor.test(tempArr[i]))){
            isCorrect = false
        };
    }
    return isCorrect
}
export const onlyEn = (str) => {
    let checkEng = /[A-Z|a-z]/;
    if((checkEng.test(str))){
        return true;
    } else {
        return false;
    }
}
export const checkKr = (userName) => {
    let validCheck = (onlyKr(userName) || onlyEn(userName)) || (onlyKr(userName) && !onlyEn(userName));
    return validCheck
}
export const checkStrLeng = (str, min=2, max=10) => {
    if((str.length>=min) && (str.length<=max)){
        return true;
    } else {
        return false;
    }
}
export const timeConverterToString = (time) => {
    const minutes = Math.floor((time/60));
    const seconds = time-(60*minutes);
    return `${minutes}분 ${seconds.toString().padStart(2, "0")}초`
}

export const getIndexNumberByPage = (index, currentPage=1, pageSize=5) => {
    return (currentPage-1)*pageSize+index+1
}

export const dateFormmater = (timeValue, type=1000) => {
    let today = new Date(timeValue) || new Date();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    let hour = today.getHours();
    let minute = today.getMinutes();
    let second = today.getSeconds();

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;
    if(type === 1) {
        return today.getFullYear() + '.' + month + '.' + day + '\u00A0\u00A0\u00A0\u00A0' + hour + ':' + minute + ':' + second;
    } else {
        return today.getFullYear() + '.' + month + '.' + day
    }
    }

    
    