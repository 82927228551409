import CheckMobile from 'components/utils/MobileCheck';
import { useEffect, useRef, useState } from 'react';

import {
    MenuCloseIco,
    MenuHamIco,
    StyledPcNewNavBar,
    StyledMbNewNavBar,
    StyledToggleMenu,
} from './style';

import logoWhite from './images/ui_logo_white.png';
import logoBlack from './images/ui_logo_black.png';
import { Link, useNavigate } from 'react-router-dom';

export default function NewNav(props: any) {
    const { goodsTabs, anotherPage, styleType } = props;

    const [scroll, setScroll] = useState(false);
    const [isDropMenuOpen, setDropMenuOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const [toggleMenuStyle, setToggleMenuStyle] = useState({
        transition: 'none',
        top: `-${window.innerHeight}px`,
    });

    const toggleMenuRef = useRef(null);

    const isMobile = CheckMobile();
    const navigate = useNavigate();

    const handleScroll = () => {
        if (window.scrollY >= 10) {
            setScroll(true);
        } else {
            setScroll(false);
        }
    };

    const logoSrc = () => {
        return scroll ? logoBlack : logoWhite;
    };

    const handleOpenMenu = () => {
        const newTransition = '1s top ease';
        const newTop = menuOpen ? `-${window.innerHeight}px` : '0';

        setToggleMenuStyle({
            transition: newTransition,
            top: newTop,
        });

        setMenuOpen(!menuOpen);
    };

    const handleScrollMove = (onMoveToElement: any) => {
        if (isMobile) {
            handleOpenMenu();
        }
        onMoveToElement();
    };

    const moveToMainPage = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        navigate('/');
    };

    useEffect(() => {
        if (!styleType) {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        } else if (styleType === 'scroll') {
            setScroll(true);
        }
    }, []);

    useEffect(() => {
        if (menuOpen) document.body.style.overflow = 'hidden';
        else if (!menuOpen) document.body.style.overflow = 'unset';
    }, [menuOpen]);

    return (
        <>
            {isMobile ? (
                <>
                    <StyledMbNewNavBar $scrolling={scroll}>
                        <img
                            className="logo"
                            src={menuOpen ? logoBlack : logoSrc()}
                            alt=""
                            onClick={moveToMainPage}
                        />
                        {menuOpen ? (
                            <MenuCloseIco onClick={handleOpenMenu} />
                        ) : (
                            <MenuHamIco onClick={handleOpenMenu} scrolling={scroll} />
                        )}
                    </StyledMbNewNavBar>
                    <StyledToggleMenu
                        ref={toggleMenuRef}
                        style={toggleMenuStyle}
                        className="toggleMenu"
                        $menuOpen={menuOpen}
                        $height={`${window.innerHeight}`}
                    >
                        <div className="navButtons">
                            {anotherPage ? (
                                <AnotherPageMenuList items={goodsTabs} />
                            ) : (
                                <MenuList
                                    items={goodsTabs}
                                    clickEvent={(onMoveToElement: any) =>
                                        handleScrollMove(onMoveToElement)
                                    }
                                />
                            )}
                        </div>
                    </StyledToggleMenu>
                </>
            ) : (
                <StyledPcNewNavBar $scrolling={scroll}>
                    <img className="logo" src={logoSrc()} alt="" onClick={moveToMainPage} />
                    <div className="NewNavWrapper">
                        <div className="NavButtons">
                            {anotherPage ? (
                                <AnotherPageMenuList items={goodsTabs} />
                            ) : (
                                <MenuList
                                    items={goodsTabs}
                                    clickEvent={(onMoveToElement: any) =>
                                        handleScrollMove(onMoveToElement)
                                    }
                                />
                            )}
                        </div>
                    </div>
                </StyledPcNewNavBar>
            )}
        </>
    );
}

const MenuList = ({ items, clickEvent }: any) => (
    <>
        {items &&
            items.map((item: any, i: number) => (
                <p
                    className="navTit"
                    key={`menu-${i}`}
                    onClick={() => clickEvent(item.onMoveToElement)}
                >
                    {item.name}
                </p>
            ))}
    </>
);

const AnotherPageMenuList = ({ items }: { items: string[] }) => (
    <>
        {items &&
            items.map((item: string, i: number) => (
                <Link
                    className="navTit"
                    to={`${item === 'CONTACT US' ? '' : '/'} `}
                    state={{ scrollToRef: `${item}` }}
                    key={`menu-${i}`}
                    onClick={() => {
                        if (item === 'CONTACT US') {
                            window.location.reload();
                        }
                    }}
                >
                    {item}
                </Link>
            ))}
    </>
);
