import axios from 'axios';

export async function getGroupList(companyuid: number) {
    return await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/manager/funcgroup/list/${companyuid}`
    );
}

export async function createGroup(createData: any) {
    return await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/manager/funcgroup/create`,
        createData
    );
}

export async function getDetailGroup(fid: any) {
    return await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/manager/funcgroup/getData/${fid}`
    );
}

export async function editGroup(editData: any) {
    return await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/manager/funcgroup/edit`,
        editData
    );
}

export async function deleteGroup(fid: any) {
    return await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/manager/funcgroup/delete/${fid}`
    );
}
