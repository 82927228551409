import axios from 'axios';

export async function getLobbyList() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/lobby/getLobbylist`);
}



export async function getLobbyIndexList() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/lobby/getLobbyIndexValidList`);
}
export async function addLobby(mapToSubmit : any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/lobby/addLobby`, mapToSubmit);
}
export async function editLobby(mapToSubmit : any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/lobby/editlobby`, mapToSubmit);
}
export async function deleteLobby(mapToSubmit : any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/lobby/deletelobby`, mapToSubmit);
}

export async function getCompanyLobbylist() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/lobby/getCompanyLobbylist`);
}

export async function editCompanylobby(mapToSubmit : any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/lobby/editCompanylobby`, mapToSubmit);
}
