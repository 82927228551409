import { Routes, Route, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import HeaderAdmin from './layout/HeaderAdmin';
import SiderAdmin from './layout/SideMenuAdmin';
import Footer from '../Footer';
import ProtectedRoutes from './ProtectedAdminRoutes';
import * as ROUTES from '../../constants/routes';
import Dashboard from './Dashboard/Dashboard';
import RegistCompany from './registCompany/RegistCompany';
import RegistWaitList from './registWaitList/RegistWaitList';
import ManageCompany from './manageCompany/ManageCompany';
import ManageCompanyUser from './manageCompany/ManageCompanyUser';
import ManageGuest from './manageGuest/ManageGuest';
import MapList from './mapList/MapList';
import MapSetting from './mapSetting/MapSetting';
import { useCallback, useEffect } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router';
import axios from 'axios';
import { logoutAdmin } from '../../_actions/AdminAction';
import { useDispatch } from 'react-redux';
import { alertMessage } from '../utils/utils';
import CODE from '../../constants/code';
import { LobbySetting } from './lobbySetting/LobbySetting';
import AppKey from './appKey/AppKey';
import ManageGuestList from './manageGuest/ManageGuestList';
import EditCompany from './manageCompany/EditCompany';
import { Notice } from './notice';
import { CreateNotice } from './notice/CreateNotice';
import { EditNotice } from './notice/EditNotice';
import { Space } from './space';
const BodyLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
`;
const MainLayout = styled.div`
    padding: 1%;
    min-height: calc(100vh - 100px);
    height: 100%;
`;
export default function Main() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const logoutAdminCallback = useCallback(async () => dispatch(await logoutAdmin()), [dispatch]);
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            if (error.response && error.response.status) {
                switch (error.response.status) {
                    case Number(CODE.RCV_UNAUTHORIZED):
                        logoutAdminCallback();
                        navigate(ROUTES.ADMIN_LOGINPAGE);
                        break;
                    case 403:
                        logoutAdminCallback();
                        navigate(ROUTES.ADMIN_LOGINPAGE);
                        break;
                }
            }
            return Promise.reject(error);
        }
    );
    return (
        <div style={{ display: 'flex' }}>
            <SiderAdmin />
            <BodyLayout>
                <HeaderAdmin />
                <MainLayout>
                    <Routes>
                        <Route element={<ProtectedRoutes isAllowed={true} />}>
                            {/* 서비스 계정 관리 */}
                            <Route path={ROUTES.ADMIN_REGISTCOMPANY} element={<RegistCompany />} />
                            <Route
                                path={ROUTES.ADMIN_REGISTWAITLIST}
                                element={<RegistWaitList />}
                            />

                            <Route path={ROUTES.ADMIN_MANAGECOMPANY} element={<ManageCompany />} />
                            <Route
                                path={ROUTES.ADMIN_MANAGECOMPANY_EDIT}
                                element={<EditCompany />}
                            />
                            <Route
                                path={ROUTES.ADMIN_MANAGECOMPANY_USER}
                                element={<ManageCompanyUser />}
                            />
                            <Route
                                path={ROUTES.ADMIN_MANAGECOMPANY_GUEST}
                                element={<ManageGuestList />}
                            />
                            <Route path={ROUTES.ADMIN_SPACE} element={<Space />} />

                            <Route path={ROUTES.ADMIN_APPKEY} element={<AppKey />} />

                            <Route path={ROUTES.ADMIN_LOBBYSETTING} element={<LobbySetting />} />

                            <Route path={ROUTES.ADMIN_MAPLIST} element={<MapList />} />
                            <Route path={ROUTES.ADMIN_MAPSETTING} element={<MapSetting />} />
                            {/* 모니터링 */}
                            <Route path={ROUTES.ADMIN_DASHBOARD} element={<Dashboard />} />
                            <Route path={ROUTES.ADMIN_MANAGEGUEST} element={<ManageGuest />} />
                            <Route path={ROUTES.ADMIN_NOTICE} element={<Notice />} />
                            <Route path={ROUTES.ADMIN_NOTICE_CREATE} element={<CreateNotice />} />
                            <Route path={ROUTES.ADMIN_NOTICE_EDIT} element={<EditNotice />} />

                            <Route
                                path="/*"
                                element={<Navigate replace to={ROUTES.ADMIN_DASHBOARD} />}
                            />
                        </Route>
                    </Routes>
                </MainLayout>
                <Footer />
            </BodyLayout>
        </div>
    );
}
