import { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router';

import { Descriptions, Form, Input, DatePicker, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';

import { infoMessage } from 'components/utils/utils';

import { NoticeDetailType, LanguageType } from 'types/notice';

import { StyledNoticePopupWrapper } from './styles';

interface NoticeDetailProps {
    type: 'create' | 'update';
    noticeData?: NoticeDetailType;
    handleData?: (notice: NoticeDetailType) => void;
    handleDelete?: () => void;
}

export const NoticeDetail = (props: NoticeDetailProps) => {
    const { type, noticeData, handleData, handleDelete } = props;
    const navigate = useNavigate();

    const [popupData, setPopupData] = useState<
        {
            title: string;
            contents: string;
        }[]
    >([]);
    const [popupIdx, setPopupIdx] = useState<number>(0);

    const [form, setForm] = useState<NoticeDetailType>({
        ko: {
            title: '',
            contents: '',
        },
        en: {
            title: '',
            contents: '',
        },
        jp: {
            title: '',
            contents: '',
        },
        exposeStartDt: '',
        exposeEndDt: '',
    });

    const updateForm = useCallback((data: any) => {
        setForm((prevForm) => ({
            ...prevForm,
            ...data,
            ko: {
                ...prevForm.ko,
                ...data.ko,
            },
            en: {
                ...prevForm.en,
                ...data.en,
            },
            jp: {
                ...prevForm.jp,
                ...data.jp,
            },
        }));
    }, []);

    const popupView = () => {
        const data = [];

        if (form.ko.title || form.ko.contents) {
            data.push({
                title: form.ko.title,
                contents: form.ko.contents,
            });
        }

        if (form.en.title || form.en.contents) {
            data.push({
                title: form.en.title,
                contents: form.en.contents,
            });
        }

        if (form.jp.title || form.jp.contents) {
            data.push({
                title: form.jp.title,
                contents: form.jp.contents,
            });
        }

        if (data.length < 1) {
            return infoMessage('제목과 내용을 입력해주세요.');
        }

        setPopupData(data);
    };

    const handleArrowClick = (direction: 'left' | 'right') => {
        if (direction === 'left' && popupIdx > 0) {
            setPopupIdx(popupIdx - 1);
        } else if (direction === 'right' && popupIdx < popupData.length - 1) {
            setPopupIdx(popupIdx + 1);
        }
    };

    const handleDataCheck = () => {
        const { ko, en, jp, exposeStartDt, exposeEndDt } = form;

        let hasContentsError = false;

        if (!ko.title && !ko.contents && !en.title && !en.contents && !jp.title && !jp.contents) {
            return infoMessage('제목과 내용을 입력해주세요');
        }

        const validateLanguage = (language: LanguageType, languageName: string) => {
            const langData = form[language as keyof NoticeDetailType];

            if (typeof langData === 'object') {
                if (!langData.title && langData.contents) {
                    hasContentsError = true;
                    return infoMessage(`${languageName} 제목이 작성되지 않았습니다.`);
                }
                if (langData.title && !langData.contents) {
                    hasContentsError = true;
                    return infoMessage(`${languageName} 내용이 작성되지 않았습니다.`);
                }
            }
            return null;
        };

        const languages: { language: LanguageType; name: string }[] = [
            { language: 'ko', name: '한국어' },
            { language: 'en', name: '영어' },
            { language: 'jp', name: '일본어' },
        ];

        for (const { language, name } of languages) {
            const validationResult = validateLanguage(language, name);
            if (validationResult) {
                return validationResult;
            }
        }

        if (!hasContentsError) {
            if (!exposeStartDt && !exposeEndDt) {
                return infoMessage('기간 설정이 되지 않았습니다.');
            }

            handleData && handleData(form);
        }
    };

    useEffect(() => {
        if (noticeData) {
            setForm(noticeData);
        }
    }, [noticeData]);

    return (
        <>
            <Descriptions bordered>
                <Descriptions.Item label={renderLabel('제목')} span={3}>
                    <Form
                        labelCol={{
                            span: 2,
                        }}
                        wrapperCol={{
                            span: 20,
                        }}
                    >
                        <Form.Item label="한국어">
                            <Input
                                showCount
                                maxLength={20}
                                value={form?.ko.title || ''}
                                onChange={(e) => {
                                    updateForm({
                                        ko: {
                                            ...form.ko,
                                            title: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="English">
                            <Input
                                showCount
                                maxLength={20}
                                value={form?.en.title || ''}
                                onChange={(e) => {
                                    updateForm({
                                        en: {
                                            ...form.en,
                                            title: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Japanese">
                            <Input
                                showCount
                                maxLength={20}
                                value={form?.jp.title || ''}
                                onChange={(e) => {
                                    updateForm({
                                        jp: {
                                            ...form.jp,
                                            title: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </Form.Item>
                    </Form>
                </Descriptions.Item>
                <Descriptions.Item label={renderLabel('내용')} span={3}>
                    <Form
                        labelCol={{
                            span: 2,
                        }}
                        wrapperCol={{
                            span: 20,
                        }}
                    >
                        <Form.Item label="한국어">
                            <TextArea
                                showCount
                                style={{
                                    height: 100,
                                }}
                                maxLength={40}
                                value={form?.ko.contents || ''}
                                onChange={(e) => {
                                    updateForm({
                                        ko: {
                                            ...form.ko,
                                            contents: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="English">
                            <TextArea
                                showCount
                                style={{
                                    height: 100,
                                }}
                                maxLength={40}
                                value={form?.en.contents || ''}
                                onChange={(e) => {
                                    updateForm({
                                        en: {
                                            ...form.en,
                                            contents: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Japanese">
                            <TextArea
                                showCount
                                style={{
                                    height: 100,
                                }}
                                maxLength={40}
                                value={form?.jp.contents || ''}
                                onChange={(e) => {
                                    updateForm({
                                        jp: {
                                            ...form.jp,
                                            contents: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </Form.Item>
                    </Form>
                </Descriptions.Item>
                <Descriptions.Item label={renderLabel('기간설정')} span={3}>
                    <DatePicker.RangePicker
                        value={
                            form?.exposeStartDt && form?.exposeEndDt
                                ? [moment(form?.exposeStartDt), moment(form?.exposeEndDt)]
                                : undefined
                        }
                        onChange={(e, date) => {
                            updateForm({
                                exposeStartDt: date[0] ? date[0] : undefined,
                                exposeEndDt: date[1] ? date[1] : undefined,
                            });
                        }}
                        showTime={{
                            format: 'HH:mm',
                        }}
                        format="YYYY-MM-DD HH:mm"
                    />
                </Descriptions.Item>
            </Descriptions>
            <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={() => navigate(`/admin/notice`)}>목록</Button>
                <div>
                    <Button
                        onClick={() => {
                            popupView();
                        }}
                    >
                        미리보기
                    </Button>
                    {type === 'update' && (
                        <Button
                            type="primary"
                            danger
                            style={{ marginLeft: 10 }}
                            onClick={handleDelete}
                        >
                            삭제
                        </Button>
                    )}
                    <Button style={{ marginLeft: 10 }} onClick={handleDataCheck} type="primary">
                        {type === 'create' ? '등록' : '수정'}
                    </Button>
                </div>
            </div>
            {popupData.length > 0 && (
                <StyledNoticePopupWrapper>
                    <div className="popupWrapper">
                        {popupData.length > 1 && popupIdx > 0 && (
                            <LeftOutlined
                                style={{
                                    fontSize: '1.6rem',
                                    marginRight: '0.5rem',
                                    color: '#1890ff',
                                }}
                                onClick={() => handleArrowClick('left')}
                            />
                        )}

                        <div className="popupBorder">
                            <p className="title">{popupData[popupIdx].title}</p>
                            <p className="contents">{popupData[popupIdx].contents}</p>
                            <div
                                className="popupCloseBtn"
                                onClick={() => {
                                    setPopupData([]);
                                    setPopupIdx(0);
                                }}
                            >
                                OK
                            </div>
                        </div>

                        {popupData.length > 1 && popupIdx < popupData.length - 1 && (
                            <RightOutlined
                                style={{
                                    fontSize: '1.6rem',
                                    marginRight: '0.5rem',
                                    color: '#1890ff',
                                }}
                                onClick={() => handleArrowClick('right')}
                            />
                        )}
                    </div>
                </StyledNoticePopupWrapper>
            )}
        </>
    );
};

const renderLabel = (text: string) => (
    <div style={{ display: 'flex' }}>
        <p style={{ color: 'red' }}>*</p>
        {text}
    </div>
);
