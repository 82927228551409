import styled from "styled-components"
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import LANG from "./i18n";

const BodyNotFileLayout = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-content: centet;
`;

const BodyNotFileIcon = styled.div`
    display: flex;
    margin-bottom: 1rem;
    min-width: 100px;
    min-height: 100px;
    border-radius: 100%;
    background: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const BodyNotFileIcon2 = styled.div`
    display: flex;
    margin-bottom: 1rem;
    min-width: 100px;
    min-height: 100px;
    border-radius: 100%;
    background: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export default function FileManageNotFile({lang, openFileInput}:any) {
    return (
        <BodyNotFileLayout>
            <BodyNotFileIcon onClick={openFileInput}>
                <DriveFolderUploadIcon style={{color: "#00A9FF", fontSize: "2.5rem"}}/>
            </BodyNotFileIcon>
            <div>{LANG[`${lang}`]["t"]["파일을_업로드_해주세요"]}</div>
        </BodyNotFileLayout>
    )
}

export function FileManageNotFile2({lang, openFileInput}:any) {
    return (
        <BodyNotFileLayout>
            <BodyNotFileIcon2 onClick={openFileInput}>
                <DriveFolderUploadIcon style={{color: "#00A9FF", fontSize: "2.5rem"}}/>
            </BodyNotFileIcon2>
            <div>{LANG[`${lang}`]["t"]["파일을_업로드_해주세요"]}</div>
        </BodyNotFileLayout>
    )
}