import { useEffect, useState } from 'react';
import { Modal, Input, Button } from 'antd';
import styled from "styled-components";
import { DataType } from './AppLink';
import { alertMessage, confirmMessage, infoMessage } from '../../utils/utils';
import { editAppLink } from '../../../_actions/AppLinkAction';
import { fileObject } from '../../webview/file/FileManageCommon';
import CODE from '../../../constants/code';

const ModalBodyLayout = styled.div`
    display: grid; 
    grid-template-columns: 25% auto; 
    grid-template-rows: 50px 50px 50px;
    align-items: center;
`;

export default function AppLinkEditModal(props:any) {
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [appLink, setAppLink] = useState<DataType>({} as any);
    const [appLinkSaved, setAppLinkSaved] = useState<DataType>({} as any);
    const [selectedFile, setSelectedFile] = useState<fileObject>();
    
    const showModal = () => {
        setIsModalVisible(true);
    };
    
    const handleOk = () => {
        if(appLink.appName !== appLinkSaved.appName || appLink.appLink !== appLinkSaved.appLink){
        confirmMessage("AppLink를 수정하시겠습니까?", "danger", ()=>{
                editAppLink(appLink).then((res)=>{
                    if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                        infoMessage("AppLink를 수정했습니다.");
                        props.changeReload();
                        setIsModalVisible(false);
                        props.closeEditModal();
                    } else {
                        alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
                    } 
                })
            })
        } else {
            infoMessage("수정 사항이 없습니다.");
            setIsModalVisible(false);
            props.closeEditModal();
        }
        
    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
        props.closeEditModal();
    };

    const onAppNameHandler = (event: { currentTarget: { value: string; }; }) => {
        setAppLink({...appLink, appName: event.currentTarget.value});
    }

    const onShareLinkHandler = (event: { currentTarget: { value: string; }; }) => {
        setAppLink({...appLink, appLink: event.currentTarget.value});
    }
    
    useEffect(() => {
        showModal();
        setAppLink(props.appLinkData);
        setAppLinkSaved(props.appLinkData);
        setSelectedFile(props.selectedFile);
    }, [props.appLinkData, props.selectedFile])
    
    return (
        <>
            <Modal title="APP Link 수정" open={isModalVisible} onOk={()=>handleOk()} onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="ok" type="primary" onClick={()=>handleOk()} 
                    disabled={!appLink.appName ? true : false || !appLink.appLink ? true : false}
                > 
                    OK
                </Button>
            ]}>
                <ModalBodyLayout>
                    <div>File name:</div>
                    <div>
                        <Input value={selectedFile?.filename} disabled />
                    </div>
                    <div>APP Name:</div>
                    <div><Input value={appLink?.appName} onChange={onAppNameHandler} /></div>
                    <div>Share Link:</div>
                    <div><Input value={appLink?.appLink} onChange={onShareLinkHandler} /></div>
                </ModalBodyLayout>
            </Modal>
        </>
    );
};
