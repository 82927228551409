import axios from 'axios';

export async function getAppKeyList() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/appkey/getAppKeyList`);
}
export async function deleteAppKey(mapToSubmit : any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/appkey/deleteAppKey`, mapToSubmit);
}
export async function getCompanyList() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/appkey/getCompanyList`);
}
export async function createAppKey(mapToSubmit : any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/appkey/createAppKey`, mapToSubmit);
}