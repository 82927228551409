import React from 'react'

function Footer() {
    return (
        <div style={{
            width : '100%',
            height: '50px',
            textAlign: 'right', backgroundColor: '#dfe3ee',
            display:'flex', flexDirection:'column', alignItems:'flex-end', justifyContent:'center'}}>
            <img src="/images/ui_logo@3x.png" style={{width: '7%', padding:'1%'}} alt=""/>
        </div>
    )
}

export default Footer
