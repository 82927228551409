import { useCallback, useEffect } from "react";
import { useNavigate, Outlet } from "react-router";
import * as ROUTES from '../../constants/routes'
import { logoutAdmin } from "../../_actions/AdminAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../_reducers";
import AUTH from "../../constants/auth";

const ProtectedRoutes:any = ({children, isAllowed=false, returnPath=null} : any) => {
    const navigate = useNavigate();
    const {adminData, loginStatus} = useSelector((state : RootState) => state.adminReducer);
    useEffect(()=>{
        if(!isAllowed) navigate(ROUTES.FORBIDDEN_PAGE);
        if(!loginStatus || adminData.grade < AUTH.indexOf("시스템관리자"))  navigate(ROUTES.ADMIN_LOGINPAGE);
    }, [adminData, loginStatus])
    return (
        children ? children : <Outlet/>
    )
};

export default ProtectedRoutes;