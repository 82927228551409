import styled from "styled-components";
import { useState } from 'react';
import type { DatePickerProps, RadioChangeEvent } from 'antd';
import { Input, Button, Radio, Select, DatePicker } from 'antd';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { reqRegistCompany, checkCompanyData } from "../../_actions/CompanyAction";
import { infoMessage, alertMessage, confirmMessage } from "../utils/utils";
import { RangePickerProps } from "antd/lib/date-picker";

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const today = moment().format(dateFormat);
let setEnddate = moment(today).add(10,"years").format(dateFormat);

const FullCenterLayout = styled.div`
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const CenterCardLayout = styled.div`
    border: 1px solid #80808045;
    padding: 50px 50px 100px 50px;
`;

const Title = styled.h3`
    border-bottom: 2px solid red;
    margin-right: 35%;
    padding-bottom: 2px;
    font-weight: 700;
    margin-bottom: 50px;
`;

const LineLayout = styled.div`
    display: grid; 
    grid-template-columns: 40px 120px auto;
    align-items: center;
    margin-bottom:20px;
    font-size: 16px;
`;

const LineBody = styled.div`
    display: flex;
`;

const loginHeaderStyle = {
    marginBottom: 25,
    display: "flex", 
    height: "45px", 
    paddingLeft: "20px",
    justifyContent:"flex-start",
    alignContent: "center",
    alignItems: "center",
    color: "#8F8F8F"
}

export interface DataType {
    companyName?: string;
    licenseType?: number;
    licenseStartDate?: string;
    licenseEndDate?: string;
    accountCount?: number;
    managerEmail?: string;
    managerPhone?: string;
    businessNumber?: string;
}
const initDataType = {
    companyName: "",
    licenseType: 0,
    licenseStartDate: today.toString(),
    licenseEndDate: setEnddate.toString(),
    accountCount: 0,
    managerEmail: "",
    managerPhone: "",
    businessNumber: ""
}
const defaultLicenseDate = "0"
export default function ManageCompany() {
    const [dataForm, setDataForm] = useState<DataType | null>(initDataType);
    const [radioValue, setRadioValue] = useState(0);
    const [licenseDate, setLicenseDate] = useState(defaultLicenseDate);
    
    const onChangeRadioButton = (e: RadioChangeEvent) => {
        setRadioValue(e.target.value);
        setDataForm({...dataForm, licenseType: e.target.value})
        if(dataForm?.licenseEndDate !== setEnddate.toString()){
            setLicenseDate(defaultLicenseDate);
            setDataForm({...dataForm, licenseEndDate: setEnddate});
        }
    };

    const onChangeAccountCount = (value: string) => {
        setDataForm({...dataForm, accountCount: Number.parseInt(value)})
    };

    const onChangeLicenseDate = (value: string) => {
        setLicenseDate(value);
        let endDate;
        if(value !== defaultLicenseDate){
            var DateIntValue:number = Number(value.split('_')[0])
            var DateStringValue:string = value.split('_')[1]
            endDate = moment(dataForm?.licenseStartDate)
            .add(DateIntValue, DateStringValue as moment.unitOfTime.DurationConstructor)
            .format(dateFormat).toString();
            setDataForm({...dataForm, licenseEndDate: endDate})
        }
        
    };
    
    const onChangeSelectDate = (
        value: DatePickerProps['value'] | RangePickerProps['value'],
        dateString: [string, string] | string,
        ) => {
            setDataForm({...dataForm, licenseStartDate: dateString[0]});
    };

    const onChangeInitialization = () => {
        setDataForm({...dataForm, licenseStartDate: initDataType?.licenseStartDate, licenseEndDate: initDataType?.licenseEndDate});
        setLicenseDate(defaultLicenseDate);
    };

    const sendDataForm = () => {
        if(licenseDate === "0"){
            alertMessage("기간을 선택해주세요.");
        }else{
            confirmMessage("위 정보로 요청하시겠습니까?", "primary", async ()=>{
                checkCompanyData(dataForm).then((response)=>{
                    let bool = response.data;
                    if(bool){
                        const message = "이미 같은 내용의 요청이 있습니다. 계속 진행하면 중복 요청이 됩니다. 계속 하시겠습니까?";
                        confirmMessage(message, "primary", ()=>{
                            reqRegistCompany(dataForm).then((response) => {
                                infoMessage(response.data.resultMessage);
                            })
                            // .catch((error)=>{
                            //     alertMessage(error.response.data);
                            // });
                        })
                    }
                    else{
                        reqRegistCompany(dataForm).then((response) => {
                            infoMessage(response.data.resultMessage);
                        })
                        // .catch((error)=>{
                        //     alertMessage(error.response.data);
                        // });
                    }
                })
            })
        }
    };

    const onChangeTextInput = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        let val:string = event.currentTarget.value.toString();
        setDataForm({...dataForm, companyName: val})
    }

    const onChangeManagerEmail = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        let val:string = event.currentTarget.value.toString();
        setDataForm({...dataForm, managerEmail: val})
    }

    const onChangeManagerPhone = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        let val:string = event.currentTarget.value.toString();
        setDataForm({...dataForm, managerPhone: val})
    }

    const onChangeBusinessNumber = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        let val:string = event.currentTarget.value.toString();
        setDataForm({...dataForm, businessNumber: val})
    }

    return (
        <FullCenterLayout>
            <div style={loginHeaderStyle}>
                <img src="/images/logo_mxspace2.png" style={{height: "50px"}} alt="" />
                <span style={{marginLeft: "10px", fontWeight: "400"}}>Meta eXperience Space</span>
            </div>
            <CenterCardLayout>
                <Title>MXSPACE 메타버스 신규 계정 정보 요청</Title>

                <LineLayout>
                    <div>1.</div><div>회사명</div>
                    <LineBody>
                        <Input style={{width: "250px"}} onChange={onChangeTextInput} id="manageCompanyTextInput"/> 
                    </LineBody>
                </LineLayout>
                <LineLayout>
                    <div>2.</div><div>계약 타입</div>
                    <LineBody>
                        <Radio.Group onChange={onChangeRadioButton} value={radioValue}>
                            <Radio value={0}>테스트 버전</Radio>
                            <Radio value={1}>정식 버전</Radio>
                        </Radio.Group>
                    </LineBody>
                </LineLayout>
                <LineLayout>
                    <div>3.</div><div>기간 설정</div>
                    <LineBody>
                        <RangePicker
                            style={{ width: 250, marginRight: 10 }}
                            onChange={onChangeSelectDate}
                            // defaultValue={[moment(moment(), dateFormat), moment(moment(), dateFormat)]}
                            value={[moment(dataForm?.licenseStartDate, dateFormat), moment(dataForm?.licenseEndDate, dateFormat)]}
                            disabled={[false, true]}
                        />
                        <Select value={licenseDate} style={{ width: 100 }} onChange={onChangeLicenseDate}>
                            <Option value= "0" >기간 선택</Option>
                            {radioValue === 0 ? <Option value="7_d">7일</Option> : null}
                            {radioValue === 0 ? <Option value="1_M">1달</Option> : <Option value="1_y">1년</Option>}
                            {radioValue === 0 ? <Option value="2_M">2달</Option> : <Option value="2_y">2년</Option>}
                            {radioValue === 0 ? <Option value="3_M">3달</Option> : <Option value="3_y">3년</Option>}
                        </Select>&nbsp;&nbsp;
                        <Button onClick={onChangeInitialization}>초기화</Button>
                    </LineBody>
                </LineLayout>
                <LineLayout>
                    <div>4.</div><div>계정 수량</div>
                    <LineBody>
                        <Select defaultValue={"수량 선택"} style={{ width: 250 }} onChange={onChangeAccountCount}>
                            <Option value="5">5</Option>
                            <Option value="10">10</Option>
                            <Option value="15">15</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                            <Option value="250">250</Option>
                            <Option value="500">500</Option>
                            <Option value="1000">1,000</Option>
                            <Option value="5000">5,000</Option>
                        </Select>
                    </LineBody>
                </LineLayout>
                <LineLayout>
                    <div>5.</div><div>담당자 이메일</div>
                    <LineBody>
                        <Input style={{width: "250px"}} placeholder={"AAA@aaa.aaa"} onChange={onChangeManagerEmail}/> 
                    </LineBody>
                </LineLayout>
                <LineLayout>
                    <div>6.</div><div>담당자 연락처</div>
                    <LineBody>
                        <NumberFormat style={{width: "250px"}} name="business_number" onChange={onChangeManagerPhone} 
                                    customInput={Input} format="###-####-####" autoComplete="off" /><span style={{color:'red', fontSize:'0.85rem', paddingLeft:'3px'}}>*핸드폰 번호만 입력해 주세요.</span><br/>
                        {/* <Input style={{width: "250px"}} placeholder={"숫자만 입력해 주세요."} onChange={onChangeManagerPhone}/>  */}
                    </LineBody>
                </LineLayout>
                <LineLayout>
                    <div>7.</div><div>사업자번호</div>
                    <LineBody>
                        <NumberFormat style={{width: "250px"}} name="business_number" onChange={onChangeBusinessNumber} 
                                customInput={Input} format="###-##-#####" autoComplete="off" /><br/>
                    </LineBody>
                </LineLayout>
                
                <div style={{marginTop:'20px', paddingLeft: "220px"}} >
                    <Button onClick={sendDataForm}>등록</Button>
                </div>
            </CenterCardLayout>
            <div style={{textAlign: 'center'}}>
                    <img src="/images/ui_logo@3x.png" style={{height: "20px", alignSelf: "start", marginTop: 20}} alt=""/>
            </div>
        </FullCenterLayout>
    )
}