import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Button, Input, Space, Table } from 'antd';
import type { ColumnsType, ColumnType, TableProps  } from 'antd/lib/table';
import type { FilterConfirmProps } from 'antd/lib/table/interface';
import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { alertMessage } from "../../utils/utils";
import { getCompanyList } from '../../../_actions/CompanyAction';

const Title = styled.h3`
    margin-bottom: 5%;
    border-bottom: 2px solid red;
    width: 25%;
    padding-bottom: 2px;
    font-weight: 700;
`;

const ManageGuest: React.FC = () => {
    
    interface DataType {
        //dataIndex 이름이랑 맞춰주기
        company_uid : string;
        license_type : string;
        company_name : string;
        max_users : string;
        chatting_hour : string;
        screen_hour : string;
        manager_email : string;
    }
    type DataIndex = keyof DataType;

    const searchInput = useRef<InputRef>(null);

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();

    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={String(selectedKeys[0])}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            confirm({ closeDropdown: false });
                        }}
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
            }
        },

    });

    const [data, setData] = useState();
    useEffect(() => {
        getCompanyList()
        .then(response => {
            // console.log(response);
            setData(response.data);
        }).catch((error)=>{
            alertMessage(error.response.data);
        });
    }, []);

    const columns: ColumnsType<DataType> = [
        {
            title: 'No',
            dataIndex: 'company_uid',
            key: 'company_uid',
            align: 'center' as 'center',
        },
        {
            title: 'Type',
            key: 'license_type', 
            dataIndex: 'license_type',
            align: 'center' as 'center',
            filters: [
                { text: <span>정식</span>, value: '정식' },
                { text: <span>테스트</span>, value: '테스트' },
            ],
            onFilter: (value: any, record) => record.license_type.startsWith(value),
            filterSearch: true,
        },
        {
            title: 'Name',
            dataIndex: 'company_name',
            key: 'company_name',
            align: 'center' as 'center',
            render: (text: any, record:any)  => (<Link to={"./list/" + record.company_uid}>{text}</Link>),
            ...getColumnSearchProps('company_name'),
        },
        {
            title: 'Account',
            dataIndex: 'max_users',
            key: 'max_users',
            align: 'center' as 'center',
        },
        {
            title: 'chatTime',
            dataIndex: 'chatting_hour',
            key: 'chatting_hour',
            align: 'center' as 'center',
        },
        {
            title: 'screenTime',
            dataIndex: 'screen_hour',
            key: 'screen_hour',
            align: 'center' as 'center',
        },
        {
            title: 'manager E-mail',
            dataIndex: 'manager_email',
            key: 'manager_email',
            align: 'center' as 'center',
        },
        // {
        //     title: 'Action',
        //     key: 'action',
        //     align: 'center' as 'center',
        //     render: (text: any, record:any) => (
        //         <Space size="middle">
        //             <Link to= 
        //                 {{
        //                     pathname: "./detail/" + record.company_index   
        //                 }}
        //             >
        //                 Edit
        //             </Link>     
        //         </Space>
        //     ),
        // },
    ];

    const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
        // console.log('params', pagination, filters, sorter, extra);
    };

    return (
        <div style={{marginLeft:'2%'}}>
            <Title>게스트 관리</Title>

            <Table 
                columns={columns} 
                dataSource={data} 
                rowKey={(render)=> render.company_uid}
                onChange={onChange}
            />
        </div>
    )
};

export default ManageGuest
