import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {Table, Select } from 'antd'
import { UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import { alertMessage } from '../../utils/utils';
import { getCompanyLobbylist, editCompanylobby } from '../../../_actions/LobbyAction';
import { DataType } from './LobbySetting'
import CODE from '../../../constants/code';
const { Column } = Table;
const { Option } = Select;
const SubTitle = styled.div`
    width: 100%;
    padding-bottom: 2px;
    font-weight: 700;
    font-size: 1rem;
    display:flex;
    justify-content: space-between;
    div{
        display:flex;
        justify-content: flex-start;
        align-items: center;
    }
`;
export interface CompanydataType {
    companyuid : string;
    companyname : string;
    lobbyid : string;
}
export const CompanyLobbyComponent = (props:any) => {
    const [companyList, setcompanyList] = useState<CompanydataType[]>([])
    const [menuToggle3, setMenuToggle3] = useState(true);
    const [lobbyList, setlobbyList] = useState<DataType[]>([])
    const [reload, setReload] = useState(true);

    useEffect(()=>{
        setlobbyList(props.lobbyList);
    },[props.lobbyList])

    useEffect(() => {
        getCompanyLobbylist()
        .then(res => {
            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                setcompanyList(res.data.companyLobbyinfo);
            } else {
                alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
            }
        })
    }, [reload]);

    const changeCompanyLobby = (lobbyData:any, lobbyid:any) => {
        editCompanylobby({...lobbyData, lobbyid})
        .then((res)=>{
            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                setReload(!reload)
            } else {
                alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
            }
        })
        .catch(error =>{
            console.error(error.response.data);
        });
    }

    return (
        <div>
            <SubTitle>
                <div>
                    <div>회사별 Lobby 설정</div>&nbsp;&nbsp;
                    {menuToggle3 ? <UpCircleOutlined onClick={()=>setMenuToggle3(false)} />
                    : <DownCircleOutlined onClick={()=>setMenuToggle3(true)} />}
                </div>
            </SubTitle>
            <br/>
            <Table dataSource={companyList} rowKey={(render)=> render.companyuid}
            className={`${menuToggle3 ?'lobbyMenuShow':"lobbyMenuHide"}`}>
                <Column
                    title= 'No.'
                    dataIndex= 'companyuid'
                    key= 'companyuid'
                    align= 'center'
                    render= {(text:any, row:any, index:any) => {
                        return(<div>{index+1}</div>)
                    }}
                    width="5%"
                />
                <Column
                    title= '회사명'
                    key= 'companyname'
                    dataIndex= 'companyname'
                    align= 'center'
                    render= {(text:any, row:any, index:any) => {
                        return(<div>{text}</div>)
                    }}
                    width="35%"
                />
                <Column
                    title= 'Lobby'
                    key= 'lobbyid'
                    dataIndex= 'lobbyid'
                    align= 'center'
                    render= {(text:any, row:any, index:any) => {
                        let lobbyData = lobbyList.length>0 ? lobbyList.filter(c=>c.lobbyid === Number(text))[0] : {lobbyname : "등록된 로비 없음", lobbyid : 0}
                        return(
                            <Select value={lobbyData.lobbyname} style={{ width: '60%' }} onChange={(value) => changeCompanyLobby(row, value)}>
                                {lobbyList.length > 0 && lobbyList.map((item: any) => {
                                    return (                                    
                                        <Option key={item.lobbyid} value={item.lobbyid} >
                                            {item.lobbyname}
                                        </Option>
                                    )
                                })}
                            </Select>
                        )
                    }}
                    />
            </Table>
        </div>
    );
};