import React from 'react';

import { useTranslation } from 'react-i18next';

import CheckMobile from 'components/utils/MobileCheck';

import { StyledMainBanner } from './style';

import PCBannerLogo from './images/banner_logo.png';
import MBBannerLogo from './Navbar/images/ui_logo_white.png';
import DownloadBtn from './DownloadBtn';

const BannerSection = () => {
    const { t } = useTranslation();

    const isMobile = CheckMobile();

    return (
        <StyledMainBanner>
            <img className="bannerLogo" src={isMobile ? MBBannerLogo : PCBannerLogo} alt="" />
            <p
                className={`bannerTitle`}
                dangerouslySetInnerHTML={{
                    __html: isMobile ? t('renew_banner_mb_title') : t('renew_banner_pc_title'),
                }}
            />
            <DownloadBtn />
        </StyledMainBanner>
    );
};

export default BannerSection;
