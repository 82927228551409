import { useState } from 'react';
import styled from 'styled-components';
import { AddLobbyComponent } from './AddLobby';
import LobbyData from './LobbyData'
import { CompanyLobbyComponent } from './CompanyLobby'
import './css/LobbySetting.css';

const Title = styled.h3`
    border-bottom: 2px solid red;
    width: 25%;
    padding-bottom: 2px;
    font-weight: 700;
`;
export interface DataType {
    idx : number;
    lobbyid : number;
    lobbyname : string;
}

export const LobbySetting = () => {
    const [lobbyList, setLobbyList] = useState<DataType[]>([])
    const [doneAddLobby, setDoneAddLobby] = useState(false);

    return (
        <div style={{marginLeft:'2%'}}>
            <Title>Lobby Setting</Title><br/>
            <AddLobbyComponent setDoneAddLobby={setDoneAddLobby} doneAddLobby={doneAddLobby} /><br/>
            <LobbyData lobbyList={lobbyList} setLobbyList={setLobbyList} doneAddLobby={doneAddLobby}/><br/>
            <CompanyLobbyComponent lobbyList={lobbyList}/>
        </div>
    );
};