import { DesktopOutlined, MailOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useState, useCallback, useEffect } from 'react';
import * as ROUTES from '../../../constants/routes';
import { useSelector } from 'react-redux';
import { getCurrentVersion, pagemoveSuccess } from '../../../_actions/SideMenuAction';
import { RootState } from '../../../_reducers';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import AUTH from '../../../constants/auth';
import './layout.css';
const { confirm } = Modal;

const ul = styled.ul``;
const li = styled.li``;

export default function Sider(props: any) {
    let { isMapVideoAllowed = false,  koreatechMenu = true} = props;
    let navigate = useNavigate();
    let location = useLocation();
    const { userData } = useSelector((state: RootState) => state.userReducer);
    const { grade } = userData;
    const { isChanged } = useSelector((state: RootState) => state.SideMenuReducer);
    const [currentVersion, setCurrentVersion] = useState();
    const [pathname, setPathname] = useState<String>();
    const dispatch = useDispatch();
    const pagemoveSuccessCallback = useCallback(
        async () => dispatch(await pagemoveSuccess()),
        [dispatch]
    );
    useEffect(() => {
        if (location.pathname) setPathname(location.pathname);
    }, [location.pathname]);
    // useEffect(() => {
    //     getCurrentVersion()
    //     .then((res) => {
    //         setCurrentVersion(res.data);
    //     })
    // }, []);
    function checkPageMove(path: string) {
        if (isChanged) {
            confirm({
                title: '페이지를 이동하시겠습니까? 변경 사항이 저장되지 않을 수 있습니다.',
                okText: '이동',
                okType: 'primary',
                cancelText: '이동 안함',
                onOk() {
                    navigate(path);
                    pagemoveSuccessCallback();
                },
            });
        } else {
            navigate(path);
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                borderRight: '1px solid #f4f4f4',
            }}
        >
            <div>
                <img
                    src="/images/logo_mxspace.png"
                    style={{
                        width: '180px',
                        padding: '20px 5px 20px 5px',
                        backgroundColor: 'white',
                    }}
                    alt=""
                />
                <div className="sideUiLayout">
                    <MailOutlined style={{ paddingRight: 10 }} /> 서비스 관리
                </div>
                {grade !== null && grade >= AUTH.indexOf('사용자관리자') ? (
                    <div
                        className={`sideLiLayout ${
                            pathname === ROUTES.USER_USERLIST_F ? 'selected' : ''
                        }`}
                    >
                        <div onClick={() => checkPageMove(ROUTES.USER_USERLIST_F)}>계정 관리</div>
                    </div>
                ) : (
                    ''
                )}
                {grade !== null && grade >= AUTH.indexOf('사용자관리자') ? (
                    <div
                        className={`sideLiLayout ${
                            pathname === ROUTES.USER_GROUPLIST_F ? 'selected' : ''
                        }`}
                    >
                        <div onClick={() => checkPageMove(ROUTES.USER_GROUPLIST_F)}>그룹 관리</div>
                    </div>
                ) : (
                    ''
                )}
                {koreatechMenu && (
                <>
                <div
                    className={`sideLiLayout ${
                        pathname === ROUTES.USER_APPLINK_F ? 'selected' : ''
                    }`}
                >
                    <div
                        style={{ lineHeight: '1.35rem' }}
                        onClick={() => checkPageMove(ROUTES.USER_APPLINK_F)}
                    >
                        Vuforia Studio
                        <br />
                        app link setting
                    </div>
                </div>
                </>
                )}
                {/* 모니터링 */}
                {isMapVideoAllowed && ( // 23.03.07 wj - 하단 메뉴라 한개 이상 열릴 때 오픈으로 변경
                    // { (A || B) &&
                    <div className="sideUiLayout">
                        <DesktopOutlined style={{ paddingRight: 10 }} />
                        모니터링
                    </div>
                )}
                {isMapVideoAllowed && (
                    <div
                        className={`sideLiLayout ${
                            pathname === ROUTES.USER_MAPVIDEO_MANAGING_F ? 'selected' : ''
                        }`}
                    >
                        <Link to={ROUTES.USER_MAPVIDEO_MANAGING_F}>
                            <div>영상 관리</div>
                        </Link>
                    </div>
                )}
                {koreatechMenu && (
                <>
                <div className="sideUiLayout">
                    <UnorderedListOutlined style={{ paddingRight: 10 }} /> 모델 파일 관리
                </div>
                <div
                    className={`sideLiLayout ${
                        pathname === ROUTES.USER_MODELFILEMANAGE_F ? 'selected' : ''
                    }`}
                >
                    <div onClick={() => checkPageMove(ROUTES.USER_MODELFILEMANAGE_F)}>
                        파일 관리
                    </div>
                </div>
                </>
                )}
            </div>
        </div>
    );
}
