import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {Button, Select, Modal } from 'antd'
import { UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import { alertMessage } from '../../utils/utils';
import { getLobbyIndexList, addLobby } from '../../../_actions/LobbyAction'
import CODE from '../../../constants/code';
const { Option } = Select;

const SubTitle = styled.div`
    width: 100%;
    padding-bottom: 2px;
    font-weight: 700;
    font-size: 1rem;
    display:flex;
    justify-content: space-between;
    div{
        display:flex;
        justify-content: flex-start;
        align-items: center;
    }
`;

export const AddLobbyComponent = (props:any) => {
    const [lobbyName, setlobbyName] = useState("");
    const [menuToggle1, setMenuToggle1] = useState(true);
    const [lobbyIndexList, setLobbyIndexList] =  useState<string[]>([]);
    const [lobbyIndex, setLobbyIndex] = useState(0);

    useEffect(() => {
        getLobbyIndexList()
        .then((res)=>{
            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                setLobbyIndexList(res.data.lobbyIndexlist)
            } else {
                alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
            }
        })
        .catch((error)=>{
            console.error(error)
            alertMessage(error.response.data)
        })
    }, [props.doneAddLobby])

    const onChangeFunction = (e: React.ChangeEvent<HTMLInputElement>) => {
        let textValue = e.target.value;
        if(textValue.length<=20){
            setlobbyName(textValue);
        }
    }

    const addLobbyData = () => {
        let data = {
            lobbyid : lobbyIndex,
            lobbyname : lobbyName
        }
        if(lobbyIndex<1) return alertMessage("Lobby 인덱스를 선택해주세요.")
        addLobby(data)
        .then((res)=>{

            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                Modal.info({
                    content: res.data.resultMessage,
                })
                props.setDoneAddLobby(!props.doneAddLobby)
                setlobbyName("")
                setLobbyIndex(0)
            } else {
                alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
            }

            
        })
    }

    return (
        <div>
            <SubTitle>
                <div>
                    <div>Lobby 등록</div>&nbsp;&nbsp;
                    {menuToggle1 ? <UpCircleOutlined onClick={() => setMenuToggle1(false)} />
                        : <DownCircleOutlined onClick={() => setMenuToggle1(true)} />}
                </div>
            </SubTitle>
            <br/>
            <div className={`${menuToggle1 ?'lobbyMenuShow':"lobbyMenuHide"} lobbyMenuWrapper`}>
                <div className="RegisterSection">
                    <div className="RegisterTitle">1. Lobby 이름</div>
                    <input type="text" name="lobbyName" value={lobbyName} onChange={onChangeFunction} style={{paddingLeft:'10px'}}/>
                </div>
                <div className="RegisterSection">
                    <div className="RegisterTitle">2. Lobby 인덱스</div>
                    <Select value={lobbyIndex} style={{ width: '400px' }} placeholder="Looby Index(win+aos)" onChange={(value) => setLobbyIndex(value)} >
                        {lobbyIndexList.length > 0 && lobbyIndexList.map((item: any) => {
                            return (
                                <Option key={item} value={item}>
                                    {item}
                                </Option>
                            )
                        })}
                    </Select>
                </div>
                <div style={{paddingLeft: "30%"}}>
                    <Button type="primary" disabled={!lobbyName} onClick={addLobbyData}>저장</Button>
                </div>
            </div>
        </div>
    );
};