import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function MXSpaceSetupGuide() {
    const params = useParams();
    const [lang, setLang] = useState(0);
    useEffect(()=>{
        setLang(Number(params.lang || 0))
    }, [])
    return (
        // 동기화 서버에서 0 - 한글, 1 - 영어, 2 - 일어
        <div style={{ display:'flex', justifyContent:'center', padding : "30px 0"}}>
            { lang === 1 ? <img src="/images/MXSetup_en.png" width="100%" height="auto" style={{maxWidth:'620px'}}/>
            :lang === 2 ? <img src="/images/MXSetup_jp.png" width="100%" height="auto" style={{maxWidth:'620px'}}/>
            : <img src="/images/MXSetup_kr.png" width="100%" height="auto" style={{maxWidth:'620px'}}/>
            }
        </div>
    )
}