// libraries
import styled from 'styled-components';

export const TableWrapper = styled.div`
    background-color: #fff;
    border-radius: 2rem;
    margin-top: 2.4rem;

    & .table-header {
        margin-bottom: 1rem;
        .totalCount-txt {
            display: flex;
            align-items: flex-end;
            color: #1e1e1e;
            padding-bottom: 0.6rem;
        }
    }
`;
