import axios from 'axios';

export async function getCompanyUserList() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/manager/users`);
}
export async function setUserOptionsFunc(options: String, uidList:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/manager/users/${options}`, uidList);
}
export async function countAccount() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/manager/company/account`);
}
export async function resetPassword(uid:Number) {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/manager/users`);
}
export async function getUserList(campanyuid: string) {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/manage/userList/`+ campanyuid);
}
export async function block(dataToSubmit: any, companyuid: any) { 
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/manage/account/block/`+ companyuid, dataToSubmit);
}
export async function clearBlock(dataToSubmit: any, companyuid: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/manage/account/clearBlock/`+ companyuid, dataToSubmit);
}
export async function getGuestList(companyuid: any) {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/manage/guestList/`+ companyuid);
}
export async function deleteUser(dataToSubmit: any, companyIndex: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/manage/account/deleteUser/`+ companyIndex, dataToSubmit);
}
export async function saCountAccount(companyuid: any ) {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/manage/account/count/${companyuid}`);
}

