import axios from 'axios';

export async function deleteFile(filepath: any, filelink:any) {
    return await axios.delete(`${process.env.REACT_APP_SERVER_BASE_URL}/user/file/delete/${filepath}/${filelink}`);
}

export async function checkFileSharing(filepath: any, filelink:any, data:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/user/file/sharingCheck/${filepath}/${filelink}`, {uid : data});
}

export async function getFileListByExtension(type: any, uid:number)  {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/user/file/${uid}?type=${type}`);
}
export async function uploadFileByGroup(dataToSubmit: any, group: number, uid:number, config :any)  {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/user/file/upload?uid=${uid}&group=${group}`, dataToSubmit, config);
}

export const getDownloadURL = (filepath: any, filelink:any) => {
    return `${process.env.REACT_APP_SERVER_BASE_URL}/user/file/download/${filepath}/${filelink}`;
}

export const getDownloadThumbnailURL = (filepath: any, filelink:any) => {
    return `${process.env.REACT_APP_SERVER_BASE_URL}/user/file/thumbnail/${filepath}/${filelink}`;
}
