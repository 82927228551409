// base
import React from 'react';

//packages
import ReactDOM from 'react-dom';

// libraries
import { Spin } from 'antd';

// style
import { SpinContainer } from './style';

export const PageLoader: React.FC<{
  text?: string;
  maskCapacity: number;
  textColor?: string;
}> = ({ text, maskCapacity = 0.4, textColor }) => {
  return ReactDOM.createPortal(
    <SpinContainer capacity={maskCapacity}>
      <div className="pageLoader_wrapper">
        <div>
          <Spin />
        </div>
        <div className="pageLoader_wrapper_text" style={{ color: textColor }}>
          {text}
        </div>
      </div>
    </SpinContainer>,
    document.body
  );
};
