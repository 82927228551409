import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as ROUTES from '../../constants/routes';

import { ArrowIco, CallIco, InquiryIco, MailIco, PinIco, StyledFooter } from './style';

const NewFooter = (props: any) => {
    const { showInquiryBtn } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const moveToInquiryPage = () => {
        navigate(ROUTES.INQUIRY_PAGE);
    };
    return (
        <StyledFooter>
            <div>
                <div className="footerAd">
                    <div>
                        <PinIco />
                        <p
                            dangerouslySetInnerHTML={{
                                __html: t('회사_주소'),
                            }}
                        />
                    </div>
                    <div>
                        <CallIco />
                        <p>02-465-3737</p>
                    </div>
                    <div>
                        <MailIco />
                        <p>contact@ymx.co.kr</p>
                    </div>

                    <p>Copyrights © 2021 All Rights Reserved by YMX</p>
                </div>
                <div className="footerIq">
                    {showInquiryBtn && (
                        <div className="inquiryBtn" onClick={moveToInquiryPage}>
                            <div>
                                <InquiryIco />
                                <p>{t('문의하기')}</p>
                            </div>
                            <ArrowIco />
                        </div>
                    )}
                </div>
            </div>
        </StyledFooter>
    );
};

export default NewFooter;
