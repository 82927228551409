import './Main.css';
import footerLogo from './images/ui_logo_white@3x.png'
import { useNavigate } from "react-router-dom";
import * as ROUTES from '../../constants/routes'
import { useTranslation } from 'react-i18next'

const MXSPACEweb_Footer = (props:any) => {
    const { t } = useTranslation()
    let {showInquiryBtn} = props
    let navigate = useNavigate();
    const moveToInquiryPage = () => {
        navigate(ROUTES.INQUIRY_PAGE)
    }
    return (
        <div className="flexRow footerContainer">
            <div>
                <img alt='푸터 로고'className="footerLogo widePc" src={footerLogo}/>
            </div>
            <div className="flexCol footerWrapper">
                <div className="flexRow footerContainer"> 
                    
                    <div className='footerLogoWrapper ipadMinif'>
                        <img alt='푸터 로고'className="footerLogo" src={footerLogo}/>
                        {showInquiryBtn && <div className='footerBtn cursorPointer' onClick={moveToInquiryPage}>{t('문의하기')}</div>}
                    </div>
                    <div className='footerLogoWrapper wideMobilef'>
                        <img alt='푸터 로고'className="footerLogo" src={footerLogo}/>
                        {showInquiryBtn && <div className='footerBtn cursorPointer' onClick={moveToInquiryPage}>{t('문의하기')}</div>}
                    </div>
                    <div className="flexRow footerBody">
                        <div className="flexCol footerBodyRight">
                            <div className="flexRow footerHeader">
                                <span>{t('찾아오시는_길')}</span>|
                                <span>{t('개인정보처리방침')}</span>
                            </div>
                            <div className='footerBody2'>
                                <div>{t('회사_주소')}</div>
                                <div>TEL : 02-465-3737</div>
                                <div>EMAIL : contact@ymx.co.kr</div>
                            </div>
                        </div>
                        {showInquiryBtn && <div className='footerBtn widePc cursorPointer' onClick={moveToInquiryPage}>{t('문의하기')}</div>}
                    </div>
                </div>
                <div className='footerCopyrights'>Copyrights © 2017 All Rights Reserved by YMX.</div>
            </div>
        </div>
    );
};

export default MXSPACEweb_Footer;