import React from 'react';

import { StyledPlatform } from './style';

import platformsImage from './images/platforms.png';
import DownloadBtn from './DownloadBtn';

const PlatformSection = () => {
    return (
        <StyledPlatform>
            <div className="platformWrapper">
                <p className="platformTitle">A VARIETY OF PLATFORMS</p>

                <div className="platformDownload">
                    <img src={platformsImage} alt="" />
                    <DownloadBtn />
                </div>
            </div>
        </StyledPlatform>
    );
};

export default PlatformSection;
