import React, { useMemo, useEffect, useCallback, useRef } from 'react';

// packages
import produce from 'immer';

import { Form, Radio, Button, Col, Row, Checkbox, Select, Input } from 'antd';
import { FormItemProps, FormProps } from 'antd/lib/form';
import { Store, StoreValue } from 'antd/lib/form/interface';
import useForm from 'antd/lib/form/hooks/useForm';

// components
import { SearchDatePicker } from './SearchDatePicker';

import { StyledSearchBarWrapper, StyledSearchbarLabel, StyledSearchbarContent } from './styles';

export type SearchBarTypes = 'input' | 'radio' | 'checkbox' | 'select' | 'date';

export interface SearchBarColumns {
    key: string;
    label: string;
    type: SearchBarTypes;
    disableLabel?: boolean;
    options?: {
        label: string | React.ReactNode;
        value: string | number | boolean;
    }[];
    defaultHidden?: boolean;
    span?: number;
    formItemProps?: FormItemProps;
    initValue?: any;
    customProps?: {};
    placeholder?: string;
}

interface Props extends FormProps {
    columns: SearchBarColumns[];
    onSearch: (values: StoreValue) => void;
    onReset: () => void;
    isStatistics?: boolean;
}

export function SearchBar({
    columns,
    onSearch,
    onReset,
    isStatistics = false,
    ...formProps
}: Props) {
    const [form] = useForm();

    const defaultColumns = useMemo(() => columns.filter((x) => !x.defaultHidden), [columns]);

    const handleFinish = useCallback(
        (values: any) => {
            onSearch(values);
        },
        [columns, onSearch]
    );

    useEffect(() => {
        let newFieldsValue: Store = {};

        if (formProps.form) {
            formProps.form.setFieldsValue(newFieldsValue);
        } else {
            form.setFieldsValue(newFieldsValue);
        }
    }, [columns, formProps.form, form]);

    const parentRef = useRef<HTMLDivElement>(null);

    return (
        <StyledSearchBarWrapper ref={parentRef}>
            <Form {...formProps} form={formProps?.form || form} onFinish={handleFinish}>
                <Row gutter={[12, 12]}>
                    <Col span={21}>
                        <Row gutter={[24, 12]}>
                            {defaultColumns.map((x, index) => {
                                return (
                                    <Col
                                        key={`default-${index}`}
                                        span={x.span}
                                        style={{ display: 'flex' }}
                                    >
                                        {!x.disableLabel && (
                                            <StyledSearchbarLabel>{x.label}</StyledSearchbarLabel>
                                        )}
                                        <StyledSearchbarContent>
                                            <Form.Item
                                                name={x.key}
                                                {...x.formItemProps}
                                                initialValue={{
                                                    key: x.key,
                                                    type: x.type,
                                                    value: x.initValue,
                                                }}
                                            >
                                                <FormInput {...x} isStatistics={isStatistics} />
                                            </Form.Item>
                                        </StyledSearchbarContent>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <Row>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 20,
                            width: '100%',
                            marginTop: 20,
                        }}
                    >
                        <Button
                            onClick={() => {
                                onReset();
                                form.resetFields();
                            }}
                        >
                            초기화
                        </Button>
                        <Button type="primary" htmlType="submit">
                            검색
                        </Button>
                    </div>
                </Row>
            </Form>
        </StyledSearchBarWrapper>
    );
}

// components
const FormInput = ({
    value,
    onChange,
    type,
    options,
    initValue,
    customProps,
    placeholder,
    isStatistics,
}: {
    value?: { key?: string; value?: any; type?: string };
    onChange?: (v: { key?: string; value?: any; type?: string }) => void;
    selectedCategories?: any;
    categoryTreeJson?: any;
    form?: any;
    isStatistics?: boolean;
} & SearchBarColumns) => {
    const commonProps = useMemo(
        () => ({
            ...customProps,
            value: value?.value === true ? 'true' : value?.value === false ? 'false' : value?.value,
            defaultValue: initValue,
        }),
        [customProps, value, initValue]
    );

    const handleChange = useCallback(
        (v: any) => {
            if (onChange) {
                onChange(
                    produce(value || ({} as { key: string; value: any }), (draft) => {
                        draft.value = v === 'true' ? true : v === 'false' ? false : v;
                        if (!value) {
                            draft.key = '';
                        }
                    })
                );
            }
        },
        [onChange, value]
    );

    return (
        <Row style={{ width: '100%' }} gutter={5}>
            <Col span={24}>
                <FormItemComponent
                    {...commonProps}
                    type={type}
                    placeholder={placeholder}
                    onChange={handleChange}
                    isStatistics={isStatistics}
                    options={
                        options &&
                        options.map((x) => {
                            return { label: x.label, value: x.value };
                        })
                    }
                />
            </Col>
        </Row>
    );
};

export const FormItemComponent = ({
    type,
    isStatistics,
    ...props
}: {
    type: string;
    onChange: (v: any) => void;
    options?: {
        label: string | React.ReactNode;
        value: string | number | boolean;
    }[];
    value?: any;
    placeholder?: string;
    isStatistics?: boolean;
}) => {
    switch (type) {
        case 'select':
            return (
                <Select
                    {...props}
                    className="form-item form-item-select"
                    showSearch
                    options={props.options as { label: string; value: string | number }[]}
                />
            );
        case 'radio':
            return (
                <Radio.Group
                    {...props}
                    className="form-item form-item-radio"
                    onChange={(e) => {
                        const v = e.target.value;
                        props.onChange(v);
                    }}
                />
            );
        case 'date':
            return <SearchDatePicker {...props} isStatistics={isStatistics} />;
        case 'checkbox':
            return (
                <Checkbox.Group
                    className="form-item form-item-checkbox"
                    onChange={(checkedValues) => {
                        const v = checkedValues;
                        props.onChange(v);
                    }}
                    options={props.options as { label: string; value: string | number }[]}
                />
            );
        default:
            return (
                <Input
                    {...props}
                    className="form-item form-item-input"
                    onChange={(e) => {
                        const v = e.target.value;
                        props.onChange(v);
                    }}
                />
            );
    }
};
