import axios from 'axios';
import {Dispatch} from 'redux';
import { PAGEMOVE_SUCCESS ,PAGEMOVE_FAIL } from './types'

export type PagemoveType = {
    isChanged:boolean
}

export interface pagemoveFailDispatch {
    type: typeof PAGEMOVE_FAIL;
    payload: PagemoveType
}

export interface pagemoveSuccessDispatch {
    type: typeof PAGEMOVE_SUCCESS;
    payload: PagemoveType
}
export type PagemoveDispatchType = pagemoveFailDispatch | pagemoveSuccessDispatch;

export async function pagemoveSuccess(){
    return {
        type : PAGEMOVE_SUCCESS,
        payload : false
    }
}
export async function pagemoveFail(){
    return {
        type : PAGEMOVE_FAIL,
        payload : true
    }
}

export async function menuVisibleOption() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/manager/menuVisibleOption`);
}

export async function getCurrentVersion() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/manager/web`);
}