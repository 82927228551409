import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Button, Input, Space, Table, Modal, Transfer, Form, Typography, InputNumber, Popconfirm } from 'antd';
import type { TransferProps } from 'antd';
import type { ColumnsType, ColumnType, TableProps  } from 'antd/lib/table';
import type { FilterConfirmProps } from 'antd/lib/table/interface';
import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { alertMessage } from "../../utils/utils";
import { getCompanyList, getCompanyFeature, updateCompanyFeature, getFeatureList, updateFeatureList } from '../../../_actions/CompanyAction';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash, faFloppyDisk, faXmark } from "@fortawesome/free-solid-svg-icons";
import './css/ManageCompany.css'
import CODE from '../../../constants/code';
import { current } from '@reduxjs/toolkit';

const Title = styled.h3`
    margin-bottom: 5%;
    border-bottom: 2px solid red;
    width: 25%;
    padding-bottom: 2px;
    font-weight: 700;
`;

interface RecordType {//회사별 기능 세팅
    key: string;
    title: string;
    permit: string;
}

interface Item {//기능 추가 등록
    idx : string;
    feature : string;
    use:string;
    createdate:string;
    status:string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text';
    record: Item;
    index: number;
    children: React.ReactNode;
}
  
const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  
const ManageCompany: React.FC = () => {

    const [featureList, setFeatureList] = useState<RecordType[]>();//선택할 수 있는 기능
    const [targetKeys, setTargetKeys] = useState([]);//선택된 기능
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [companyuid, setCompanyuid] = useState()
    const [isOpen, setIsOpen] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [featureTitle, setFeatureTitle] = useState("");
    const [sendFeatureData, setSendFeatureData] = useState<{ idx:string; feature: string; use: string; createdate: string; status: string;}[]>([]);
    const [featureTable, setFeatureTable] = useState<{ idx:string; feature: string; use: string; createdate: string; status: string;}[]>([]);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record: Item) => record.idx === editingKey;
  
    const onChangeTransfer: TransferProps<any>['onChange'] = (nextTargetKeys:any, direction:any, moveKeys:any) => {//transfer 칸 이동 할 때
        setTargetKeys(nextTargetKeys);
        moveKeys.forEach((moveKey:any) => {
            const moveFeature = featureList?.find((item:any) => Number(item.key) == moveKey);
            if(moveFeature){
                if(direction == "right"){
                    moveFeature.permit = "1"
                }else{
                    moveFeature.permit = "0"
                }
            }
        });
    };

    const saveCompanyFeature = () => {

        updateCompanyFeature(featureList, companyuid)
        .then((res: any) => {
            if (Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)) {
                setIsOpen(false)
            }
        })
    }   
    const saveFeatureSetting = () => {

        updateFeatureList(sendFeatureData)
        .then((res: any) => {
            if (Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)) {
                setAddModal(false)
            }
        })
    }   
  
    const onSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {//체크박스 선택 시
      setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const onUploadFunc = (companyuid:any) => {

        getCompanyFeature(companyuid)
        .then((response: any) => {

            setCompanyuid(response.data.companyuid)

            const featureData: RecordType[] = response.data.featureList.map((item: any) => ({ key: item.key, title: item.title, permit: item.permit }));//전체 기능 리스트
            setFeatureList(featureData)

            const featurePermitData = response.data.featureList.filter((item:any) => String(item.permit) === "1").map((item: any) => item.key);//업체에 할당된 기능
            setTargetKeys(featurePermitData);

        })
        // .catch((error)=>{
        //     alertMessage(error.response.data);
        // });
        
        setIsOpen(true)
    }

    
    const onOpenAddModal = () => {
        getFeatureList()
        .then(response => {
            const data = response.data.featureList;

            data.forEach((item:any) => {
                item.status = "default";
            });
            setSendFeatureData(data);
            setFeatureTable(data);
        })
        setAddModal(true)
    }
    // const onScroll: TransferProps['onScroll'] = (direction:any, e:any) => {
    //   console.log('direction:', direction);
    //   console.log('target:', e.target);
    // };
    const addFeature = () => {
        if(featureTitle == null || featureTitle == "") return alertMessage("기능명을 입력하여 주세요.");

        const lastFeature = sendFeatureData[sendFeatureData.length - 1];

        const lastIdx = lastFeature.idx;

        const updateFeature =  [...sendFeatureData, { idx: lastIdx+1, feature: featureTitle, use: '1', createdate:'', status:'add' }];
        setSendFeatureData(updateFeature);
        setFeatureTable(sendFeatureData.filter((item) => item.status !== 'delete'));

        setFeatureTitle("")
    }

    const deleteFeature = (idx:any) => {
        
        const index = sendFeatureData.findIndex((item) => idx === item.idx);
        const item = sendFeatureData[index];
        if(item.status == 'default' || item.status == 'edit'){
            item.status = 'delete'
            setSendFeatureData(sendFeatureData)
            setFeatureTable(sendFeatureData.filter((item) => item.status !== 'delete'))
            
        }else{
            const updateFeature = sendFeatureData.filter(item => item.idx !== idx);
            setSendFeatureData(updateFeature)
            setFeatureTable(sendFeatureData.filter((item) => item.status !== 'delete'))
        }

    }

    const cancel = () => {
        setEditingKey('');
    };

    const editFeature = (record: Partial<Item> & { idx: React.Key }) => {
        form.setFieldsValue({ NO: record.idx, '기능명': '',  ...record });
        setEditingKey(record.idx);
    };

    const save = async (key: React.Key) => {
        try {
          const row = (await form.validateFields()) as Item;
    
          const newData = [...featureTable];
          const index = newData.findIndex((item) => key === item.idx);
          if (index > -1) {
            const item = newData[index];
            if(item.status == "default") item.status = "edit";
            newData.splice(index, 1, {
              ...item,
              ...row,
            });
            setSendFeatureData(newData)
            setFeatureTable(sendFeatureData.filter((item) => item.status !== 'delete'));
            setEditingKey('');
          } else {
            newData.push(row);
            setSendFeatureData(newData)
            setFeatureTable(sendFeatureData.filter((item) => item.status !== 'delete'));
            setEditingKey('');
          }
        } catch (errInfo) {
        //   console.log('Validate Failed:', errInfo);
        }
    };
       

    const onFeatureTitle = (event: { currentTarget: { value: any; }; }) => {
        setFeatureTitle(event.currentTarget.value);
    }

    // interface ModalDataType {
    //     //dataIndex 이름이랑 맞춰주기
    //     idx : number;
    //     feature : string;
    //     use:string;
    //     createdate:string;
    // }
    
    interface DataType {
        //dataIndex 이름이랑 맞춰주기
        company_uid : string;
        license_type : string;
        company_name : string;
        max_users : string;
        chatting_hour : string;
        screen_hour : string;
        manager_email : string;
        rtc_url : string;
        rtc_mode : number;
    }
    type DataIndex = keyof DataType;

    const searchInput = useRef<InputRef>(null);

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();

    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={String(selectedKeys[0])}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            confirm({ closeDropdown: false });
                        }}
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
            }
        },

    });

    const [data, setData] = useState();
    useEffect(() => {
        getCompanyList()
        .then(response => {
            setData(response.data);
        }).catch((error)=>{
            alertMessage(error.response.data);
        });

        setFeatureTable(sendFeatureData.filter((item) => item.status !== 'delete'));


    }, [sendFeatureData]);

    const columns: ColumnsType<DataType> = [
        {
            title: 'No',
            dataIndex: 'company_uid',
            key: 'company_uid',
            align: 'center' as 'center',
        },
        {
            title: 'Type',
            key: 'license_type', 
            dataIndex: 'license_type',
            align: 'center' as 'center',
            filters: [
                { text: <span>정식</span>, value: '정식' },
                { text: <span>테스트</span>, value: '테스트' },
            ],
            onFilter: (value: any, record) => record.license_type.startsWith(value),
            filterSearch: true,
        },
        {
            title: 'Name',
            dataIndex: 'company_name',
            key: 'company_name',
            align: 'center' as 'center',
            render: (text: any, record:any)  => (<Link to={"./user/" + record.company_uid}>{text}</Link>),
            ...getColumnSearchProps('company_name'),
        },
        {
            title: 'Account',
            dataIndex: 'max_users',
            key: 'max_users',
            align: 'center' as 'center',
        },
        {
            title: 'chatTime',
            dataIndex: 'chatting_hour',
            key: 'chatting_hour',
            align: 'center' as 'center',
        },
        {
            title: 'screenTime',
            dataIndex: 'screen_hour',
            key: 'screen_hour',
            align: 'center' as 'center',
        },
        {
            title: 'manager E-mail',
            dataIndex: 'manager_email',
            key: 'manager_email',
            align: 'center' as 'center',
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center' as 'center',
            render: (text: any, record:any) => (
                <Space size="middle">
                    <Link to= 
                        {{
                            pathname: "./edit/" + record.company_uid
                        }}
                    >
                        Edit
                    </Link>     
                </Space>
            ),
        },
        {
            title: '기능 설정',
            key: 'setting',
            align: 'center' as 'center',
            render: (text: any, record:any) => (
                <Space size="middle">
                    <Button className='columnBtn' onClick={()=>onUploadFunc(record.company_uid)} style={{border:"none", color:"#1890ff"}}>
                        Setting 
                    </Button>     
                </Space>
            ),
        },
    ];

    const modalColumns = [
        {
          title: 'NO',
          dataIndex: 'idx',
          align: 'center' as 'center',
          key:'idx',
          editable: false,
           render: (row:any, index:any, record:any) => {
                return(<div>{(currentPage - 1) * 10 + record + 1}</div>)
            }
        },
        {
          title: '기능명',
          dataIndex: 'feature',
          align: 'center' as 'center',
          editable: true,
        },
        {
          title: '수정',
          dataIndex: 'edit',
          align: 'center' as 'center',
          render: (_: any, record: Item) => {
            const editable = isEditing(record);
            return editable ? (
              <span>
                <Typography.Link onClick={() => save(record.idx)} style={{ marginRight: 8 }}>
                    <FontAwesomeIcon icon={faFloppyDisk} style={{color:'black', marginRight:'5px'}}/> 
                </Typography.Link>
                <Typography.Link title="Sure to cancel?" onClick={() => cancel()} >
                    <FontAwesomeIcon icon={faXmark} style={{color:'black', cursor:'pointer'}} /> 
                </Typography.Link>
              </span>
            ) : (
              <Typography.Link disabled={editingKey !== ''} onClick={() => editFeature(record)}>
                <FontAwesomeIcon icon={faPenToSquare} style={{color:'black'}}/>
              </Typography.Link>
            );
          },
        },
        {
            title: '삭제',
            key: 'delete',
            align: 'center' as 'center',
            render: (text: any, record:any) => (
                <Space size="middle">
                    <Button id='columnBtn1' onClick={()=>deleteFeature(record.idx)} style={{border:"none"}}>
                        <FontAwesomeIcon icon={faTrash} /> 
                    </Button>     
                </Space>
            ),
        },
    ];

    const mergedColumns = modalColumns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record: Item) => ({
            record,
            inputType: col.dataIndex === 'age' ? 'number' : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });

    const onChange = (pagination:any) => {
        setCurrentPage(pagination.current || 1)
    };

    return (
        <div style={{marginLeft:'2%'}}>
            <Title>계정 관리</Title>
            <Button onClick={()=>onOpenAddModal()} style={{float:"right", marginBottom:"15px"}}>기능 관리</Button>
            <Table 
                columns={columns} 
                dataSource={data} 
                rowKey={(render)=> render.company_uid}
                // onChange={onChange}
            />
            <Modal title={"기능 사용 설정"} open={isOpen} onCancel={()=>setIsOpen(false)}
                     footer={[
                        <div key="btnWrapper" style={{display:"flex", justifyContent:"center"}}>
                        <Button style={{width:"100px"}} key="ok" type="primary" onClick={() => saveCompanyFeature()}>
                            저장
                        </Button>
                        <Button style={{width:"100px"}} key="cancel" onClick={() => setIsOpen(false)}>
                            취소
                        </Button>
                    </div>
                    ]}
            >
                <Transfer
                    dataSource={featureList}
                    titles={['기능 목록', '사용할 기능 목록']}
                    targetKeys={targetKeys}
                    selectedKeys={selectedKeys}
                    onChange={onChangeTransfer}
                    onSelectChange={onSelectChange}
                    listStyle={{
                        width: 400,
                        height: 400,
                    }}
                    showSelectAll={false}
                    // onScroll={onScroll}
                    render={(item) => item.title}
                />
                <div style={{marginTop:"20px"}}>※ 메뉴를 오른쪽으로 이동 시 사용할 목록에 추가 됩니다.</div>
                <div>※ 저장하지 않고 창을 종료할 경우 기능이 적용되지 않습니다.</div>
                <div>※ [저장] 선택 시 스페이스에 기능이 셋팅 됩니다.</div>
            </Modal>
            <Modal title={"기능 관리"} open={addModal} onCancel={()=>setAddModal(false)}
                footer={[
                    <div key="btnWrapper" style={{display:"flex", justifyContent:"center"}}>
                    <Button style={{width:"100px"}} key="ok" type="primary" onClick={() => saveFeatureSetting()}>
                        저장
                    </Button>
                </div>
                ]}
            >
                <div>
                    <div style={{display:"flex", justifyContent:"center", marginBottom:'20px'}}>
                        <div>기능 추가</div>
                        <div style={{margin:"0 10px"}}><Input onChange={onFeatureTitle} value={featureTitle}></Input></div>
                        <div><Button onClick={addFeature}>Add</Button></div>
                    </div>
                    <Form form={form} component={false}>
                        <Table
                            components={{
                            body: {
                                cell: EditableCell,
                            },
                            }}
                            bordered
                            dataSource={featureTable}
                            rowKey={(render)=> render.idx}
                            columns={mergedColumns}
                            rowClassName="editable-row"
                            onChange={onChange}
                            pagination={{
                            onChange: cancel,
                            }}
                        />
                    </Form>
                    <div>※ 저장하지 않고 창을 종료할 경우 기능이 셋팅되지 않습니다.</div>
                    <div>※ 기능 추가 또는 삭제 시 “기능 사용 설정” 팝업에 반영됩니다.</div>
                </div>
            </Modal>
        </div>

    )
};

export default ManageCompany
