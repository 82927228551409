import { useCallback } from 'react';
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux";
import { logoutAdmin } from '../../../_actions/AdminAction'; 
import * as ROUTES from '../../../constants/routes'

function Header() {
    const dispatch = useDispatch();
    const logoutAdminCallback = useCallback(async () => dispatch(await logoutAdmin()),
    [dispatch]
    );
    const handleLogout = () => {
        logoutAdminCallback()
    }

    return (
        <div style={{
            width : '100%',
            display: 'flex',
            backgroundColor: '#633b98',
            height: '50px',
            justifyContent: 'flex-end',
            color: 'white',
            alignItems:'center'
        }}>
            <Link to={ROUTES.ADMIN_LOGINPAGE} style={{paddingRight: '25px', color: 'white'}} onClick={handleLogout}>
                😃 로그아웃
            </Link>
        </div> 
    )
}

export default Header