import { Space, Table, Button } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import styled from "styled-components";
import AppKeyAddModal from './AppKeyAddModal';
// import AppKeyEditModal from './AppKeyEditModal';
import { getAppKeyList, deleteAppKey, getCompanyList } from '../../../_actions/AppkeyAction';
import { confirmMessage, infoMessage, alertMessage } from "../../utils/utils";
import { useSelector } from "react-redux";
import CODE from '../../../constants/code';


const Title = styled.h3`
    border-bottom: 2px solid red;
    width: 25%;
    padding-bottom: 2px;
    font-weight: 700;
`;

export interface DataType {
    key: React.Key;
    appIndex: number;
    twAppkey: string;
    twUrl: string;
    companyuid: string;
    name : string;
}

export default function AppKey(props:any) {
    const [openModal, setOpenModal] = useState(false);
    const [appKeyList, setAppKeyList] = useState<DataType[]>([]);
    const [appKey, setAppKey] = useState<DataType>();
    const [isReload, setIsReload] = useState(false);
    const [companyList, setCompanyList] = useState();
    
    const columns: ColumnsType<DataType> = [
        {
            title: 'No',
            dataIndex: 'appIndex',
            key: 'appIndex',
            align: 'center',
            width: 75,
            render: (text, row, index) => {
                return(<div>{index+1}</div>)
            }
        },
        {
            title: 'APP Name',
            dataIndex: 'twAppkey',
            key: 'twAppkey',
            align: 'center',
            width: 400
        },
        {
            title: 'share Link 주소',
            dataIndex: 'twUrl',
            key: 'twUrl',
            align: 'center',
            render: (text, record) => (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                    {text}
                </div>
            ),
        },
        {
            title: 'Action',
            key: 'companyuid',
            align: 'center',
            width: 160,
            render: (text, record) => (
                <Space size="small">
                    <Button onClick={() => { openEditModal(record); } }>수정</Button>
                    <Button onClick={() => { handleDeleteAppKey(text); } }>삭제</Button>
                </Space>
            )
        }
    ];

    useEffect(() => {
        getAppKeyList().then((res)=>{
            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                setAppKeyList(res.data.appkeyList);
                //이미 등록된 회사는 안보이도록 처리
                setCompanyList(res.data.companyList);
            } else {
                alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
            }
        });
    }, [isReload])

    const handleDeleteAppKey = (data:DataType) => {
        confirmMessage("AppKey를 삭제하시겠습니까?", "danger", ()=>{
            deleteAppKey(data).then((res)=>{
                if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                    infoMessage("AppKey를 삭제했습니다.");
                    changeReload();
                } else {
                    alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
                }               
            })
        })
    }

    const changeReload = () => {
        setIsReload(!isReload);
    }
    const openCreateModal = () => {
        setOpenModal(true);
    }
    const closeeModal = () => {
        setOpenModal(false);
        setAppKey(undefined);
    }
    const openEditModal = (data:any) => {
        setOpenModal(true);
        setAppKey(data);
    }

    return (
        <div style={{marginLeft:'2%'}}>
            <Title>APP Key 등록</Title>
            <div style={{display: "flex", justifyContent: "flex-end", marginBottom: "1rem"}}>
                <Button onClick={openCreateModal}>Create</Button>
            </div>
            <Table columns={columns} dataSource={appKeyList} rowKey={(render)=> render.companyuid} tableLayout={"auto"} />
            {openModal && <AppKeyAddModal 
                closeeModal={closeeModal} 
                changeReload={changeReload} 
                companyData={companyList}
                appKeyData={appKey || false} 
            />}
        </div>
    )
}