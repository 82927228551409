import styled from "styled-components";
import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Button, Input, Space, Table } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import type { TableRowSelection } from 'antd/lib/table/interface';
import type { FilterConfirmProps } from 'antd/lib/table/interface';
import type { ColumnsType, ColumnType, TableProps  } from 'antd/lib/table';
import { getGuestList } from '../../../_actions/AccountAction';

const Title = styled.h3`
    margin-bottom: 5%;
    border-bottom: 2px solid red;
    width: 25%;
    padding-bottom: 2px;
    font-weight: 700;
`;

const ManageGuestList: React.FC = () => {

    let currentUrl = window.location.href;
    let urlStr = currentUrl.split('/');
    let companyuid = urlStr[6];

    interface DataType {
        key: React.Key;
        guest_index : string;
        license_type : string;
        guest_email : string;
        type : string;
        state: string;
        chatting_hour : string;
        screen_hour : string;
        access_time : string;
    }
    type DataIndex = keyof DataType;

    const searchInput = useRef<InputRef>(null);

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={String(selectedKeys[0])}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            confirm({ closeDropdown: false });
                        }}
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
            }
        },

    });

    const columns: ColumnsType<DataType> = [
        {
            title: 'No',
            dataIndex: 'guest_index',
            key: 'guest_index',
            align: 'center' as 'center',
        },
        {
            title: 'Type',
            key: 'license_type',
            dataIndex: 'license_type',
            filters: [
                { text: <span>정식</span>, value: '정식' },
                { text: <span>테스트</span>, value: '테스트' },
            ],
            onFilter: (value: any, record) => record.license_type.startsWith(value),
            align: 'center' as 'center',
        },
        {
            title: 'E-mail',
            dataIndex: 'guest_email',
            key: 'guest_email',
            align: 'center' as 'center',
            ...getColumnSearchProps('guest_email'),
            // render: (text: any)  => <a href="#!">{text}</a>,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            align: 'center' as 'center',
        },
        // {
        //     title: 'State',
        //     key: 'state',
        //     dataIndex: 'state',
        //     filters: [
        //         { text: <span>접속중</span>, value: '접속중' },
        //         { text: <span>블록중</span>, value: '블록중' },
        //         { text: <span>로그아웃</span>, value: '로그아웃' },
        //     ],
        //     onFilter: (value: any, record) => record.state.startsWith(value),
        //     filterSearch: true,
        //     align: 'center' as 'center',
        // },
        {
            title: '화상채팅 시간',
            dataIndex: 'chatting_hour',
            key: 'chatting_hour',
            align: 'center' as 'center',
        },
        {
            title: '화면송출 시간',
            dataIndex: 'screen_hour',
            key: 'screen_hour',
            align: 'center' as 'center',
        },
        {
            title: '기간별 누적 접속시간',
            dataIndex: 'access_time',
            key: 'access_time',
            align: 'center' as 'center',
        },
    ];
    
    // const [checkState, setCheckState] = useState(false);
    const [data, setData] = useState();

    useEffect(() => {
        getGuestList(companyuid)
        .then(response => {
            setData(response.data);
        });
    }, [companyuid]);
    
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<DataType> = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
            {
            key: 'odd',
            text: 'Select Odd Row',
                onSelect: changableRowKeys => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changableRowKeys.filter((_, index) => {
                    if (index % 2 !== 0) {
                        return false;
                    }
                    return true;
                    });
                    setSelectedRowKeys(newSelectedRowKeys);
                },
            },
            {
            key: 'even',
            text: 'Select Even Row',
                onSelect: changableRowKeys => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changableRowKeys.filter((_, index) => {
                    if (index % 2 !== 0) {
                        return true;
                    }
                    return false;
                    });
                    setSelectedRowKeys(newSelectedRowKeys);
                },
            },
        ],
    };

    // function logout(){
    //     if(selectedRowKeys.length === 0){
    //         alert("접속 해제할 계정을 선택해주세요.");
    //     }else{
    //         let result = window.confirm(selectedRowKeys.length + "개의 계정을 접속 해제 하시겠습니까?")
    //         if(result){
    //             guestLogout(selectedRowKeys)
    //             .then((response) => {
    //                 if(response.data === true){
    //                     alert("선택 계정 접속 해제 성공");
    //                     setCheckState(!checkState);
    //                     setSelectedRowKeys([]);
    //                 }else{
    //                     alert("접속 해제 처리에 실패하였습니다.")
    //                 }
    //             }).catch((error)=>{
    //                 alertMessage(error.response.data);
    //             })  
    //         }
    //     }
    // }
    // function block(){
    //     if(selectedRowKeys.length === 0){
    //         alert("블록 처리할 계정을 선택해 주세요.");
    //     }else{
    //         let result = window.confirm(selectedRowKeys.length + "개의 계정을 블록 처리 하시겠습니까?")
    //         if(result){
    //             guestBlock(selectedRowKeys)
    //             .then((response) => {
    //                 if(response.data === true){
    //                     alert("선택 계정 블록 처리 성공");
    //                     setCheckState(!checkState);
    //                     setSelectedRowKeys([]);
    //                 }else{
    //                     alert("블록 처리에 실패하였습니다.")
    //                 }
    //             }).catch((error)=>{
    //                 alertMessage(error.response.data);
    //             })  
    //         }
    //     }
    // }

    // function clearBlock(){
    //     if(selectedRowKeys.length === 0){
    //         alert("블록 해제 처리할 계정을 선택해 주세요.");
    //     }else{
    //         let result = window.confirm(selectedRowKeys.length + "개의 계정을 블록 해제 처리 하시겠습니까?")
    //         if(result){
    //             guestClearBlock(selectedRowKeys)
    //             .then((response) => {
    //                 if(response.data === true){
    //                     alert("선택 계정 블록 해제 처리 성공");
    //                     setCheckState(!checkState);
    //                     setSelectedRowKeys([]);
    //                 }else{
    //                     alert("블록 해제 처리에 실패하였습니다.")
    //                 }
    //             }).catch((error)=>{
    //                 alertMessage(error.response.data);
    //             })  
    //         }
    //     }
    // }
    // function deleteGuest(){
    //     if(selectedRowKeys.length === 0){
    //         alert("삭제할 계정을 선택해 주세요.");
    //     }else{
    //         let result = window.confirm(selectedRowKeys.length + "개의 계정을 삭제 하시겠습니까?")
    //         if(result){
    //             guestDelete(selectedRowKeys)
    //             .then((response) => {
    //                 if(response.data === true){
    //                     alert("선택 계정 삭제 성공");
    //                     setCheckState(!checkState);
    //                     setSelectedRowKeys([]);
    //                 }else{
    //                     alert("삭제 실패하였습니다.")
    //                 }
    //             }).catch((error)=>{
    //                 alertMessage(error.response.data);
    //             })  
    //         }
    //     }
    // }

    const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
        // console.log('params', pagination, filters, sorter, extra);
    };

    return (
        <div>
            <Title>게스트 관리</Title>
            <br></br>
            <div style={{display: "flex", justifyContent: 'space-between'}}>
                <div style={{marginLeft: '2%'}}>
                    조회기간&nbsp;&nbsp;&nbsp;
                    <input type="date" name="" id="" />&nbsp;&nbsp;
                    <input type="date" name="" id="" />
                </div>
                {/* <div>
                    <button onClick={logout}>접속해제</button>&nbsp;
                    <button onClick={block}>계정블록</button>&nbsp;
                    <button onClick={clearBlock}>블록해제</button>&nbsp;
                    <button onClick={deleteGuest}>계정삭제</button>
                </div> */}
            </div>
            <br></br>
            <Table 
                rowSelection={rowSelection} 
                columns={columns} 
                dataSource={data} 
                rowKey={(render)=> render.guest_index}
                onChange={onChange}
            />
        </div>
    )
}

export default ManageGuestList
