export default function Main() {

    return (
        <div style={{
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            width: '100%', 
            height: '100%',
            flexDirection: "column"
        }}>
            <div style={{display: 'flex', alignItems: "center"}}>
                <h2 style={{color: "#3b5998"}}>MXSPACE</h2>
                <h4 style={{marginLeft: 10, color: "gray"}}>Meta eXperience Space</h4>
            </div>
            <div>
                <h2>관리자 페이지에 오신 것을 환영합니다.</h2>
            </div>
        </div>
    )
    
}