import {COMPANY_CHECK_TEMPORARY_REGISTER} from '../_actions/types';

export default function companyReducer (state = {}, action: { type: any; payload: any; }) {
    switch (action.type) {
        case COMPANY_CHECK_TEMPORARY_REGISTER:
            return { ...state, result: action.payload }
        default:
            return state;
    }
}
