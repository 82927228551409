import React, { useEffect, useState } from 'react';
import androidBtn from './images/01_btn_android.png'
import windowBtn from './images/01_btn_window.png'
import './Main.css';
import { Modal, Button, Input } from 'antd';
import {checkAppDownloadCode} from '../../_actions/AppDownloadAction'
import axios from 'axios';
import CODE from '../../constants/code';

const DownloadBtn = () => {
    
    const [signupCode, setsignupCode] = useState("");
    const [downLoadFileType, setdownloadFileType] = useState("");
    const [errorMsg, seterrorMsg] = useState("");
    const [infoMsg, setinfoMsg] = useState("");
    const [visible, setvisible] = useState(false);
    useEffect(()=>{
        seterrorMsg("")
    }, [signupCode])
    useEffect(()=>{
        if(!visible){
            setdownloadFileType("")
            setsignupCode("")
            seterrorMsg("")
        }
    }, [visible])
    const onChangesignupCode = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        let val:string = event.currentTarget.value.toString();
        setsignupCode(val)
    }
    const ChecksignupCode = () => {
        if(errorMsg !==''){
            seterrorMsg("")
        }
        checkAppDownloadCode(signupCode)
        .then((response:any) => {
            if(Number(response.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                downloadLocalFunc()
            } else {
                seterrorMsg("유효하지 않은 코드입니다.")
            }
        })
        // .catch((err:any)=>{
        //     seterrorMsg(err.response.data.resultMessage)
        //     console.log(err.response)
        // })
    }
    const downloadLocalFunc = () => {
        try{
            setinfoMsg("다운로드 진행 중입니다.")
            const fileName = "MXSpaceSetup"+`${process.env.REACT_APP_MODE==="prod"? "" : process.env.REACT_APP_MODE}`+"."+ downLoadFileType;
            const link = document.createElement('a');
            link.href = `${process.env.REACT_APP_SERVER_BASE_URL}/common/resource/installer?extension=${downLoadFileType}&code=${signupCode}`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            setvisible(false)
            setinfoMsg("")
            document.body.removeChild(link); 
        } catch (e) {
            console.log(e)
        }
    }
    const openModal = (type:string) => {
        setvisible(true)
        setdownloadFileType(type)
    }
    return (
        <>
        <div className='topDownlod EnFont'>DOWNLOAD</div>
        <div className='flexRow topBtnWrapper'>
            <div className='topBtn'><img alt='안드로이드 다운로드 버튼' className='topBtnIcon cursorPointer' src={androidBtn} onClick={()=>openModal("apk")}/></div>
            <div className='topBtn'><img alt='윈도우 다운로드 버튼' className='topBtnIcon cursorPointer' src={windowBtn} onClick={()=>openModal("exe")}/></div>
            <Modal
                title= '발급 받은 계정 코드를 입력하세요.'
                open={visible}
                closable={false}
                style={{top: '38%'}}
                footer={[
                    <Button key='1' onClick={()=>setvisible(false)}>취소</Button>,
                    <Button key='2' type="primary" onClick={ChecksignupCode}>확인</Button>
                ]}
                width={300}
            >
                <div>
                    <Input.Group compact>
                        <Input
                            status={errorMsg !=='' ? `error` : infoMsg !=='' ? 'warning' : ''}
                            style={{
                            width: '70%',
                            margin : '0 15%',
                            textAlign : 'center',
                            backgroundColor : '#F5F5F5'
                            }}
                            disabled={infoMsg !==''}
                            onChange={onChangesignupCode}
                            defaultValue=""
                            value={signupCode}
                        />
                    </Input.Group>
                    <div style={{color:"red", textAlign:'center'}}>{errorMsg}</div>
                    <div style={{color:"orange", textAlign:'center'}}>{infoMsg}</div>
                </div>
            </Modal>
        </div>
        </>
    );
};


export default DownloadBtn;