import { useEffect, useState } from 'react';
import { Modal, Input, Select, Button } from 'antd';
import styled from "styled-components";
import { DataType } from './AppLink';
import { createAppLink } from '../../../_actions/AppLinkAction';
import CODE from '../../../constants/code';
import { alertMessage, infoMessage } from '../../utils/utils';

const { Option } = Select;

const ModalBodyLayout = styled.div`
    display: grid; 
    grid-template-columns: 25% auto; 
    grid-template-rows: 50px 50px 50px;
    align-items: center;
`;

export default function AppLinkAddModal(props:any) {
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [appLink, setAppLink] = useState<DataType>({} as any);
    
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {

        const dataToSubmit = {
            "fileuid" : appLink.fileuid,
            "appName" : appLink.appName,
            "appLink" : appLink.appLink
        }
        createAppLink(dataToSubmit).then((res)=>{
            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                infoMessage("AppLink를 생성했습니다.");
                props.changeReload();
            } else {
                alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
            } 
            
        });

        setIsModalVisible(false);
        props.closeCreateModal();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        props.closeCreateModal();
    };

    const onAppNameHandler = (event: { currentTarget: { value: string; }; }) => {
        setAppLink({...appLink, appName: event.currentTarget.value});
    }

    const onShareLinkHandler = (event: { currentTarget: { value: string; }; }) => {
        setAppLink({...appLink, appLink: event.currentTarget.value});
    }

    const onFileuidHandler = (value:string ) => {
        let data = JSON.parse(value);
        setAppLink({...appLink, fileuid: data.uid});
    }

    useEffect(() => {
        showModal();
    }, [])

    return (
        <>
            <Modal title="APP Link 생성" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="ok" type="primary" onClick={handleOk} 
                        disabled={!appLink.appName ? true : false || !appLink.appLink ? true : false}
                    > 
                        OK
                    </Button>
                ]}>
                <ModalBodyLayout>
                    <div>File Name:</div>
                    <div>
                        <Select onChange={onFileuidHandler} style={{ width: '100%'}} defaultValue={"선택해주세요"}>
                            { props.fileListData && props.fileListData?.map((item:any) => {
                                return (<Option key={item.uid} value={JSON.stringify(item)} >{item.filename}</Option>)
                            })}
                        </Select>
                    </div>
                    <div>APP Name:</div>
                    <div><Input onChange={onAppNameHandler} /></div>
                    <div>Share Link:</div>
                    <div><Input onChange={onShareLinkHandler} /></div>
                </ModalBodyLayout>
            </Modal>
        </>
    );
};