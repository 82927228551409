import { UserData } from "../_actions/types";
import {USER_LOGIN, USER_REGISTER, AUTH_USER, USER_AUTHENTICATION, USER_LOGOUT} from '../_actions/types';

export const initialState : UserData = {
        loginStatus : false,
        userData : {
            uid: -1,
            companyuid: -1,
            licenseuid: -1,
            userid: "",
            nickname: "",
            gender: 1,
            grade: -1,
            profileimage: "",
            usertype: 0,
            createdate: "",
            updatedate: ""
        }
};

export default function userReducer (state = initialState, action: { type: any; payload: any; }) {
    switch (action.type) {
        case USER_LOGIN:
            return { ...state, ...action.payload } // spread operator
        case USER_LOGOUT:
            return { ...initialState }
        case USER_REGISTER:
            return { ...state, ...action.payload }
        case AUTH_USER:
            return { ...state, ...action.payload }
        case USER_AUTHENTICATION : 
            return { ...state, ...action.payload }
        default:{
            return state;
        }
    }
}