import './App.css';
import 'swiper/css';
import 'swiper/swiper-bundle.min.css';

import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import * as ROUTES from './constants/routes';
import FileManageWebview from './components/webview/file/FileManageWebview';
import ProfileUploadWebview from './components/webview/profile/ProfileUploadWebview';
import JoinRequestPage from './components/joinRequest/JoinRequest';
import Main from './components/main/Main';
import Inquiry from './components/main/inquiry/Inquiry';
import HomeUsers from './components/user/HomeUsers';
import LoginUsers from './components/user/login/LoginUsers';
import axios from 'axios';
import LoginAdmin from './components/admin/login/LoginAdmin';
import { MXSpaceSetupGuide } from './components/guide/MXSpaceSetupGuide';
import HomeAdmin from './components/admin/HomeAdmin';
import CODE from './constants/code';
import { alertMessage } from './components/utils/utils';
import { MXSpaceDownload } from './components/mxspaceDownload/MxspaceDownload';
import NewMainPage from 'components/newMain/NewMainPage';

function App() {
    if (process.env.REACT_APP_MODE === 'production') {
        console.log = function no_console() {};
        console.debug = function no_console() {};
    }

    let location = useLocation();
    // 요청 인터셉터 추가하기
    axios.interceptors.request.use(
        function (config) {
            // 요청이 전달되기 전에 작업 수행
            config.withCredentials = true;
            return config;
        },
        function (error) {
            // 요청 오류가 있는 작업 수행
            return Promise.reject(error);
        }
    );
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            if (!location.pathname.includes('/fileUpload')) {
                if (!error.response) {
                    alertMessage('서버와 연결이 끊어졌습니다.');
                } else if (error.response && error.response.status) {
                    switch (error.response.status) {
                        // case Number(CODE.RCV_FAIL):
                        //   break;
                        default:
                            alertMessage(
                                error.response.data.resultMessage || '잠시 후 다시 시도해주세요.'
                            );
                    }
                }
            }
            return Promise.reject(error);
        }
    );
    return (
        // <Router>
        <Routes>
            {/* 로그인 필요 X */}
            {/* 공통페이지 */}
            <Route path="/" element={<NewMainPage />} />
            <Route path={ROUTES.INQUIRY_PAGE} element={<Inquiry />} />
            <Route path={ROUTES.MXSPACE_GUIDE} element={<MXSpaceSetupGuide />} />
            <Route path={ROUTES.MXSPACE_GUIDE_all} element={<MXSpaceSetupGuide />} />
            <Route path={ROUTES.MXSPACE_DOWNLOAD} element={<MXSpaceDownload />} />
            <Route path={ROUTES.JOIN_REQUESTPAGE} element={<JoinRequestPage />} />{' '}
            {/* 사용자 로그인이 필요 없는 페이지라 공통으로 이동 */}
            {/* 웹뷰 */}
            <Route path={ROUTES.FILE_UPLOAD} element={<FileManageWebview />} />
            <Route
                path={ROUTES.FILE_UPLOAD + '/:keyQuery/:modelType'}
                element={<FileManageWebview />}
            />
            <Route
                path={ROUTES.FILE_UPLOAD + '/:keyQuery/:modelType/:lang'}
                element={<FileManageWebview />}
            />
            <Route path={ROUTES.LOBBY_FILE} element={<FileManageWebview isLobby={true} />} />
            <Route
                path={ROUTES.LOBBY_FILE + '/:keyQuery/:modelType/:lang'}
                element={<FileManageWebview isLobby={true} />}
            />
            <Route
                path={ROUTES.LOBBY_FILE + '/:keyQuery/:modelType'}
                element={<FileManageWebview isLobby={true} />}
            />
            <Route path={ROUTES.PROFILE_UPLOAD} element={<ProfileUploadWebview />} />
            {/* 로그인 필요 O */}
            {/* 사용자 */}
            <Route path={ROUTES.USER_LOGINPAGE} element={<LoginUsers history={[]} />} />
            <Route path={ROUTES.USER_ALL} element={<HomeUsers />} />
            {/* 관리자 */}
            <Route path={ROUTES.ADMIN_LOGINPAGE} element={<LoginAdmin history={[]} />} />
            <Route path={ROUTES.ADMIN_ALL} element={<HomeAdmin />} />
            <Route path="/*" element={<Main />} />
        </Routes>
        // </Router>
    );
}

export default App;
