import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { RootState } from '../../../_reducers';

import { Table, Button } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';

import GroupAddModal from './GroupAddModal';
import GroupEditModal from './GroupEditModal';

import { getGroupList } from '../../../_actions/FuncGroupAction';
import CODE from '../../../constants/code';

import { GroupButtonLayout } from './style';
import { alertMessage } from '../../utils/utils';

export interface GroupDataType {
    fid: number;
    funcgroupname: string;
    cntGroupmember: number;
    createdate: string;
}

export interface CompanyFeatureType {
    idx: number;
    feature: string;
}

export default function GroupList() {
    const [openModal, setOpenModal] = useState<{ [key: string]: boolean }>({
        createModal: false,
        editModal: false,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [groupList, setGroupList] = useState<GroupDataType[]>([]);
    const [companyFeature, setCompanyFeature] = useState<CompanyFeatureType[]>([]);
    const [selectGroupId, setSelectGroupId] = useState<number>(0);

    const { userData } = useSelector((state: RootState) => state.userReducer);

    const onChange: TableProps<GroupDataType>['onChange'] = (
        pagination,
        filters,
        sorter,
        extra
    ) => {
        setCurrentPage(pagination.current || 0);
    };

    const columns: ColumnsType<GroupDataType> = [
        {
            title: 'No',
            dataIndex: 'key',
            key: 'key',
            align: 'center' as 'center',
            render: (text, row, index) => {
                return <div>{(currentPage - 1) * 10 + index + 1}</div>;
            },
        },
        {
            title: '그룹명',
            key: 'funcgroupname',
            dataIndex: 'funcgroupname',
            align: 'center' as 'center',
        },
        {
            title: '그룹 등록 인원',
            key: 'cntGroupmember',
            dataIndex: 'cntGroupmember',
            align: 'center' as 'center',
            render: (_, record) => {
                return (
                    <GroupButtonLayout>
                        <p style={{ margin: 0 }}>{record.cntGroupmember} </p>
                        <Button
                            onClick={() => {
                                setSelectGroupId(record.fid);
                                handleGroupEditModal();
                            }}
                        >
                            수정
                        </Button>
                    </GroupButtonLayout>
                );
            },
        },
        {
            title: '그룹 생성 일자',
            key: 'createdate',
            dataIndex: 'createdate',
            align: 'center' as 'center',
        },
    ];

    const handleCreateModal = () => {
        setOpenModal({
            ...openModal,
            createModal: !openModal.createModal,
        });
    };

    const handleGroupEditModal = () => {
        if (openModal.editModal) {
            setSelectGroupId(0);
        }
        setOpenModal({
            ...openModal,
            editModal: !openModal.editModal,
        });
    };

    const handleGetGroupList = () => {
        getGroupList(userData.companyuid).then((res) => {
            if (Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)) {
                setGroupList(res.data.funcgroupList);
                setCompanyFeature(res.data.companyFeature);
            } else {
                alertMessage(res.data.resultMessage || '잠시 후 다시 시도해주세요.');
            }
        });
    };

    useEffect(() => {
        handleGetGroupList();
    }, []);

    return (
        <div>
            <h3>
                <u>그룹 관리</u>
            </h3>
            <br />
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
                <Button onClick={handleCreateModal}>그룹 생성</Button>
            </div>
            <br />

            <Table
                columns={columns}
                dataSource={groupList}
                onChange={onChange}
                rowKey={(render) => render.fid}
            />
            {openModal.createModal && (
                <GroupAddModal
                    companyFeature={companyFeature}
                    openModal={openModal.createModal}
                    closeModal={handleCreateModal}
                    refreshGroupList={handleGetGroupList}
                />
            )}
            {openModal.editModal && (
                <GroupEditModal
                    groupId={selectGroupId}
                    openModal={openModal.editModal}
                    closeModal={handleGroupEditModal}
                    refreshGroupList={handleGetGroupList}
                />
            )}
        </div>
    );
}
