import { Button, Input, Table, TableProps, Modal, Progress } from "antd";
import { ColumnsType, TableRowSelection } from "antd/lib/table/interface";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { alertMessage, confirmMessage, getReadableFileSize } from "../../utils/utils";
import moment from "moment";
import CODE from "../../../constants/code";
import { deleteModelDataListBYhyundai, getModelDataListBYhyundai, uploadModelDataBYhyundai } from "../../../_actions/HyundaiAction";
import { useSelector } from "react-redux";
import { RootState } from "../../../_reducers";
import './css/ModelFileManage.css'

const Title = styled.h3`
    border-bottom: 2px solid red;
    width: 25%;
    padding-bottom: 2px;
    font-weight: 700;
`;
interface DataType {
    uid : number;
    username : string;
    bid : number;
    filename : string;
    filesize : number;
    createtime: string;
}
function ModelFileManage() {
    const validFileType = [".zip"]
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [fileList, setFileList] = useState<DataType[]>();
    const [percent, setPercent] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const uploadFile = useRef<any>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const {userData} = useSelector((state : RootState) => state.userReducer);
    useEffect(()=>{
        getFileList()
    }, [])
    useEffect(()=>{
        if(inputRef.current !== null) inputRef.current.value = "";
    }, [isOpen])
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection: TableRowSelection<DataType> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
        setCurrentPage(pagination.current || 1)
    };
    const columns: ColumnsType<DataType> = [
        {
            title: 'No',
            dataIndex: 'bid',
            key: 'bid',
            align: 'center' as 'center',
            width: "5%",
            render: (text, row, index) => {
                return(<div>{(currentPage - 1) * 10 + index + 1}</div>)
            }
        },
        {
            title: '등록자',
            key: 'username',
            dataIndex: 'username',
            width: "25%",
            align: 'center' as 'center',
        },
        {
            title: '파일 이름',
            key: 'filename',
            dataIndex: 'filename',
            width: "35%",
            align: 'center' as 'center',
        },
        {
            title: '파일 용량',
            dataIndex: 'filesize',
            key: 'filesize',
            width: "15%",
            align: 'center' as 'center',
            render(value, record, index) {
				return (<div>{getReadableFileSize(value)}</div>)
			},
        },
        {
            title: '등록 일시',
            dataIndex: 'createtime',
            key: 'createtime',
            width: "20%",
            align: 'center' as 'center',
            render(value, record, index) {
                const dateFormat = 'YYYY-MM-DD HH:mm:ss';
				const today = moment(value).format(dateFormat);
				return (<div>{today}</div>)
			},
        }
    ];
    const onDeleteFunc = () => {
        if(selectedRowKeys.length === 0){
            alertMessage("삭제할 파일을 선택해주세요.​");
        }else{
            confirmMessage(
                "선택한 파일을 삭제하시겠습니까?​",
                "primary",
                ()=>{
                    //삭제함수
                    deleteModelDataListBYhyundai(selectedRowKeys)
                    .then((res: any) => {
                        if (Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)) {
                            getFileList()
                        } else {
                            alertMessage(res.data.resultMessage || "잠시 후 시도해주세요.");
                        }
                    })
                }
            )
        }
    }
    
    const onUploadFunc = () => {
        setIsOpen(true)
    }
    const config = {
        onUploadProgress: (progressEvent: { loaded: number; total: number; }) => {
            setPercent(Math.round((progressEvent.loaded * 100) / progressEvent.total) || 0)
        }
    }
    const uploadFunc = () => {
            if (uploadFile.current !== null) {
                const formData: any = new FormData();
                formData.append("zippedFile", uploadFile.current);
                uploadModelDataBYhyundai(formData, userData.uid, uploadFile.current.name, config)
                    .then((res: any) => {
                        if (Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)) {
                            getFileList()
                            setIsOpen(false)
                        } 
                        setPercent(0);
                    })
                    .catch((err) => { setPercent(0);} )
                
            } else {
                alertMessage("선택된 파일이 없습니다.")
            }

    }
    const getFileList = () => {
        // 파일 목록 불러오는 함수
        // setFileList(data)
        getModelDataListBYhyundai()
        .then((res)=>{
            if(res.data) setFileList(res.data)
        })
    }
    const onReset = () => {
        setSearchKeyword("");
        getFileList()
    }
    const onSearch = () => {
        let temp = fileList?.slice();
        if(searchKeyword !=="") temp = fileList?.filter(c=>c.filename.toLowerCase().match(searchKeyword.toLowerCase()))
        setFileList(temp);
    }
    const uploadHandler = (e:any) => {
        e.preventDefault();
        var files;
        var file;
        // "파일 첨부" 버튼을 눌러서 이미지를 선택했을때
        files = e.target.files
        if(files !== null && files[0] !== undefined){
            file = e.target.files[0];
            var filename = files !== null && file !== undefined ? file.name : '';
            var extension = filename.length > 3 ? filename.slice(-4) : '';
            if (validFileType.indexOf(extension.toLowerCase()) < 0) {
                uploadFile.current = null
                e.target.value = '';
                return alert("업로드 가능한 파일 형식이 아닙니다.")
            } else {
                uploadFile.current = file
            }
        } else {
            uploadFile.current = null
        }
    }
    return (
        <>
            <Title>모델 파일 관리</Title>
            <br/>
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: "1rem"}}>
                <div style={{display: "flex", justifyContent: "flex-start", marginBottom: "1rem"}}>
                    <Input type="text" value={searchKeyword} onChange={(e)=>setSearchKeyword(e.target.value)} style={{width:"300px",marginRight:"5px"}}/>
                    <Button onClick={()=>onSearch()} type="primary">검색</Button>
                </div>
                <div style={{display: "flex", justifyContent: "flex-end", marginBottom: "1rem"}}>
                    <Button onClick={()=>onUploadFunc()} style={{marginRight:"5px"}}>Upload</Button>
                    <Button onClick={()=>onDeleteFunc()}>Delete</Button>
                </div>
            </div>
            <div style={{ position:"relative"}}>
            <Table rowSelection={rowSelection} 
                columns={columns} 
                dataSource={fileList} 
                onChange={onChange} 
                rowKey={(render)=> render.bid}
                pagination={{position : ["bottomCenter"]}}
                />
            <div style={{position:"absolute", bottom:'15px',right:0}}>
                <Button onClick={()=>onReset()}>되돌아가기</Button>
            </div>
            </div>
            <Modal title={"파일 업로드"} open={isOpen} onCancel={()=>setIsOpen(false)}
                style={{top:'38%', whiteSpace:'pre-line', fontSize:'16px', fontWeight:500}}
                className="modelFileUploadModal"
                footer={[
                    <div key="btnWrapper" style={{display:"flex", justifyContent:"center"}}>
                    <Button style={{width:"100px"}} key="ok" type="primary" onClick={() => uploadFunc()} disabled={percent>0}>
                        등록
                    </Button>
                    <Button style={{width:"100px"}} key="cancel" onClick={() => setIsOpen(false)} disabled={percent>0}>
                        취소
                    </Button>
                </div>
                ]}
                >
                    <div>
                        <div className="fileUpload">
                            {percent <1 ?
                            <div style={{border:"1px solid lightgray", height:"50px", display:'flex', flexDirection:'column', justifyContent:"center", padding:"0 5px"}}>
                                <input className="fileUploadModalInput" id="fileUploadModalInput" type="file" accept={validFileType[0]}
                                style={{width:"100%"}} onChange={e => { uploadHandler(e); }} ref={inputRef}/>
                            </div>
                        :   <div style={{height:"50px", display:'flex', flexDirection:'column', justifyContent:"center", padding:"0 5px"}}>
                                <div style={{textAlign:"center"}}>{uploadFile.current !== null ? uploadFile.current.name : "파일 업로드 중"}</div>
                                <Progress strokeColor={{ '0%': '#662DB7', '100%': '#00A9FF' }} status='active' percent={percent} showInfo={false} />
                            </div>
                        }
                        </div>
                        <div style={{marginTop:"10px"}}>등록할 파일을 선택한 후 [등록] 버튼을 클릭해주세요.​</div>
                    </div>
                </Modal>
        </>
    )
}

export default ModelFileManage 