import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Button, Input, Space, Table } from 'antd';
import type { ColumnsType, ColumnType, TableProps } from 'antd/lib/table';
import type { FilterConfirmProps } from 'antd/lib/table/interface';
import React, { useRef, useState, useEffect } from 'react';
import type { TableRowSelection } from 'antd/lib/table/interface';
import { alertMessage, confirmMessage, infoMessage } from "../../utils/utils";
import { getCompanyUserList, countAccount, resetPassword, setUserOptionsFunc
} from '../../../_actions/AccountAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../_reducers';
import CODE from '../../../constants/code';

const UserList:any = (props:any) => {
    let { isPwResetAllowed=false } = props;
    const {userData} = useSelector((state : RootState) => state.userReducer);
    const { uid } = userData;
    type DataIndex = keyof DataType;
    
    interface DataType {
        key: React.Key;
        user_uid : number;
        user_index : string;
        license_type : string;
        user_email : string;
        state: string;
        user_level: string;
        chatting_hour : string;
        screen_hour : string;
        access_time : string;
    }

    const searchInput = useRef<InputRef>(null);

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={`${selectedKeys[0]}`}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            confirm({ closeDropdown: false });
                        }}
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });
    const [data, setData] = useState();
    const [maxUser, setMaxUser] = useState();
    const [accountInUse, setAccountInUse] = useState();
    const [remainingAccount, setRemainingAccount] = useState();
    const [checkState, setCheckState] = useState(false);
    // const [companyIndex, setCompanyIndex] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if(uid>-1){
            getCompanyUserList()
            .then((res)=>{
                if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                    setData(res.data.users);
                } else {
                    alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
                }
            })
            countAccount()
            .then((res)=>{
                if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                    setMaxUser(res.data.maxAccount);
                    setAccountInUse(res.data.usedAccount);
                    setRemainingAccount(res.data.remainedAccount);
                }
            });
        }
    }, [uid, checkState])

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<DataType> = {
        selectedRowKeys,
        onChange: onSelectChange,
        // selections: [
        //     Table.SELECTION_ALL,
        //     Table.SELECTION_INVERT,
        //     Table.SELECTION_NONE,
        //     {
        //         key: 'odd',
        //         text: 'Select Odd Row',
        //         onSelect: changableRowKeys => {
        //             let newSelectedRowKeys = [];
        //             newSelectedRowKeys = changableRowKeys.filter((_, index:any) => {
        //                 if (index % 2 !== 0) {
        //                     return false;
        //                 }
        //                 return true;
        //             });
        //             setSelectedRowKeys(newSelectedRowKeys);
        //         },
        //     },
        //     {
        //         key: 'even',
        //         text: 'Select Even Row',
        //         onSelect: changableRowKeys => {
        //             let newSelectedRowKeys = [];
        //             newSelectedRowKeys = changableRowKeys.filter((_, index:any) => {
        //                 if (index % 2 !== 0) {
        //                     return true;
        //                 }
        //                 return false;
        //             });
        //             setSelectedRowKeys(newSelectedRowKeys);
        //         },
        //     },
        // ],
    };

    function setUserOptions(options:String){
        let emptyMsg, confirmMsg, successMsg:any, errorMsg = "유효하지 않은 요청입니다.";

            switch (options) {
              case "assign": // 관리자 등록
                emptyMsg = "관리자 등록할 계정을 선택하세요.";
                confirmMsg = selectedRowKeys.length + "개의 계정을 관리자로\n등록하시겠습니까?";
                successMsg = (<div>선택 계정이 관리자로 등록 되었습니다.<br/>다음 접속 시 적용됩니다.</div>);
                errorMsg= "관리자 등록 처리에 실패하였습니다.";
                break;
            case "cancel": // 관리자 해제
                emptyMsg = "관리자 해제할 계정을 선택하세요.";
                confirmMsg = selectedRowKeys.length + "개의 계정을 관리자에서 해제 하시겠습니까?";
                successMsg = (<div>선택 계정이 관리자로 등록 되었습니다.<br/>다음 접속 시 적용됩니다.</div>);
                errorMsg= "관리자 등록 처리에 실패하였습니다.";
                break;
            case "block": // 사용자 블록
                emptyMsg = "블록 처리할 유저를 선택해 주세요.";
                confirmMsg = selectedRowKeys.length + "개의 계정을 블록 처리 하시겠습니까?";
                successMsg = (<div>선택 계정이 블록 처리되었습니다.<br/>다음 접속 시 적용됩니다.</div>);
                errorMsg= "블록 처리에 실패하였습니다.";
                break;
            case "active": // 사용자 블록 해제
                emptyMsg = "블록 해제 처리할 계정을 선택해 주세요.";
                confirmMsg = selectedRowKeys.length + "개의 계정을 블록 해제 처리 하시겠습니까?";
                successMsg = (<div>선택 계정이 블록 해제 처리되었습니다.<br/>다음 접속 시 적용됩니다.</div>);
                errorMsg= "블록 해제 처리에 실패하였습니다.";
                break;
            case "delete": // 사용자 삭제
                emptyMsg = "삭제할 계정을 선택해 주세요.";
                confirmMsg = selectedRowKeys.length + "개의 계정을 삭제 하시겠습니까?";
                successMsg = (<div>선택 계정이 삭제 처리되었습니다.</div>);
                errorMsg= "삭제 처리에 실패하였습니다.";
                break;
            default:
                break;
            }

            if(selectedRowKeys.length === 0){
                alertMessage(emptyMsg)
            } else {
                confirmMessage(
                    confirmMsg,
                    "primary",
                    ()=>{
                        setUserOptionsFunc(options, selectedRowKeys)
                        .then((response) => {                
                            if(Number(response.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                                infoMessage(successMsg);
                                setCheckState(!checkState);
                                setSelectedRowKeys([]);
                            }else{
                                alertMessage(errorMsg)
                            }
                        })
                    }
                )
            }

    }

    function resetPW(){
        if(selectedRowKeys.length === 0){
            alert("비밀번호 리셋할 계정을 선택하세요.");
        }else{
            let result = window.confirm(selectedRowKeys.length + "개 계정의 비밀번호를 1234로 초기화하시겠습니까?")
            if(result){
                resetPassword(0)
                .then((response) => {
                    if(response.data === 1){
                        alert("선택 계정의 비밀번호가 초기화되었습니다. PW : 1234");
                        setSelectedRowKeys([]);
                    }else{
                        alert("비밀번호 리셋 처리를 실패하였습니다.")
                    }
                }).catch((error)=>{
                    alertMessage(error.response.data);
                })  
            }
        }
    }


    const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
        setCurrentPage(pagination.current || 0)
        // console.log('params', pagination, filters, sorter, extra);
    };

    const columns: ColumnsType<DataType> = [
        {
            title: 'No',
            dataIndex: 'key',
            key: 'key',
            align: 'center' as 'center',
            render: (text, row, index) => {
                return(<div>{(currentPage - 1) * 10 + index + 1}</div>)
            }
        },
        {
            title: 'Type',
            key: 'license_type',
            dataIndex: 'license_type',
            align: 'center' as 'center',
        },
        {
            title: '계정 등급',
            key: 'user_level',
            dataIndex: 'user_level',
            align: 'center' as 'center',
        },
        {
            title: 'E-mail',
            dataIndex: 'user_email',
            key: 'user_email',
            align: 'center' as 'center',
            ...getColumnSearchProps('user_email'),
        },
        {
            title: 'State',
            key: 'state',
            dataIndex: 'state',
            filters: [
                { text: <span>접속중</span>, value: '접속중' },
                { text: <span>블록중</span>, value: '블록중' },
                { text: <span>로그아웃</span>, value: '로그아웃' },
            ],
            onFilter: (value: any, record) => record.state.startsWith(value),
            filterSearch: true,
            align: 'center' as 'center',
        },
        {
            title: '화상채팅 시간',
            dataIndex: 'chatting_hour',
            key: 'chatting_hour',
            align: 'center' as 'center',
        },
        {
            title: '화면송출 시간',
            dataIndex: 'screen_hour',
            key: 'screen_hour',
            align: 'center' as 'center',
        },
        {
            title: '기간별 누적 접속시간',
            dataIndex: 'access_time',
            key: 'access_time',
            align: 'center' as 'center',
        },
    ];

    return (
        <div>
            <h3><u>계정관리 &gt; 개별 계정 정보</u></h3>
            <br></br>
            <div>
                <div>발급 계정 수 : &nbsp;&nbsp;{maxUser} </div>
                <div>사용 계정 수 : &nbsp;&nbsp;{accountInUse} </div>
                <div>잔여 계정 수 : &nbsp;&nbsp;{remainingAccount} </div>
            </div>
            <br></br>
            <div style={{display: "flex", justifyContent: 'space-between'}}>
                <div>
                    {/* 조회기간&nbsp;&nbsp;
                    <input type="date" name="" id="" />&nbsp;&nbsp;&nbsp;
                    <input type="date" name="" id="" /> */}
                </div>
                <div>

                    <button onClick={()=>setUserOptions("assign")}>관리자 등록</button>&nbsp;&nbsp;
                    <button onClick={()=>setUserOptions("cancel")}>관리자 해제</button>&nbsp;&nbsp;
                    {isPwResetAllowed && <button onClick={resetPW} style={{marginRight:'6px'}}>비밀번호 리셋</button>}
                    {/* <button onClick={logoutList}>접속해제</button>&nbsp;&nbsp; */}
                    <button onClick={()=>setUserOptions("block")}>계정블록</button>&nbsp;&nbsp;
                    <button onClick={()=>setUserOptions("active")}>블록 해제</button>&nbsp;&nbsp;
                    <button onClick={()=>setUserOptions("delete")}>계정 삭제</button>&nbsp;&nbsp;
                </div>
            </div>
            <br></br>
            <Table rowSelection={rowSelection} 
                columns={columns} 
                dataSource={data} 
                onChange={onChange} 
                rowKey={(render)=> render.user_uid}/>
        </div>
    );
};

export default UserList;