import styled from "styled-components";
import React, { useEffect, useState } from 'react' 
import { confirmCompanyName, registCompany } from '../../../_actions/CompanyAction';
import type { RadioChangeEvent, CheckboxProps  } from 'antd';
import { Input, Button, Radio, Select, Checkbox } from 'antd';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { alertMessage, infoMessage } from "../../utils/utils";
import Selectbox from './Selectbox';
import './css/RegistCompany.css'

const Title = styled.h3`
    margin-bottom: 5%;
    border-bottom: 2px solid red;
    width: 25%;
    padding-bottom: 2px;
    font-weight: 700;
`;
const Item = styled.th`
    text-align: left;
    padding-left: 3%;
    font-size: 1rem;
    font-weight: 500;
    width:30%;
    border:none;
    
`;

const Td = styled.td`
    border:none;
`;

function NewCompany() {
    const { Option } = Select;
    let navigate = useNavigate();
    //회사명, 계약타입, 기간, 계정수량, 채팅시간, 송출시간, 담당자이메일, 사업자번호 
    const [Company_name, setCompany_name] = useState("")
    const [License_type, setLicense_type] = useState("default")
    const [Days, setDays] = useState("default")
    const [License_startdate, setLicense_startdate] = useState("")
    const [License_enddate, setLicense_enddate] = useState("")
    const [Max_users, setMax_users] = useState("default")
    const [Chatting_hour, setChatting_hour] = useState("")
    const [SttKey, setSttKey] = useState("")
    const [SttRegioncode, setSttRegioncode] = useState("")
    const [UseTranslate, setUseTranslate] = useState(false)
    const [Screen_hour, setScreen_hour] = useState("")
    const [Manager_email, setManager_email] = useState("")
    const [Manager_phone, setManager_phone] = useState("")
    const [Business_number, setBusiness_number] = useState("")
    const [loading, setLoading] = useState(false);
    const [MailLanguage, setMailLanguage] = useState("")
    // RTC 모드, URL 추가
    const [RTC_mode, setRTC_mode] = useState("0")
    const [RTC_url, setRTC_url] = useState("")

    //오류 메세지 상태 저장
    const [nameMessage, setNameMessage] = useState<string>('')
    const [emailMessage, setEmailMessage] = useState('')
    const [businessNumMessage, setBusinessNumMessage] = useState('')
    
    //유효성 검사
    const [Confirm_name, setConfirm_name] = useState<boolean>(false)
    // const [Confirm_email, setConfirm_email] = useState<boolean>(false)
    const [Confirm_businessNum, setConfirm_businessNum] = useState<boolean>(false)
    const [SuccessRegister, setSuccessRegister] = useState(false)

    let flag = false;

    useEffect(()=>{
        if(SuccessRegister === true && flag === true){
            navigate('../../manageAccount');
        }
    },[SuccessRegister, navigate, flag])

    const onChange: CheckboxProps['onChange'] = (e) => {
        setUseTranslate(e.target.checked)
        let sttDiv =  document.getElementById("sttData")
        if(e.target.checked){
            sttDiv!.style.display = "flex";
            
        }else{
            setSttKey("")
            setSttRegioncode("")
            sttDiv!.style.display = "none";
        }
    };
    //아이디 중복 검사
    function confirmName(){
        let body = {
            company_name : Company_name,
        }
        
        if(Company_name.length < 1){
            infoMessage("회사명을 입력해주세요.")
        }else{
            confirmCompanyName(body)
            .then((response:any) => {
                if(response.data.result){
                    setNameMessage("사용 가능한 회사명입니다.")
                    setConfirm_name(true)
                }else{
                    setNameMessage("사용할 수 없는 회사명입니다.")
                    alertMessage(response.data.resultMessage);
                    setConfirm_name(false)
                }
            })
            // .catch((error: { response: { data: any; }; })=>{
            //     alertMessage(error.response.data.resultMessage);
            // })  
        }
    }

    const onClickHandler = (event: { preventDefault: () => void; }) => {

        let body = {
            company_name: Company_name,
            license_type: License_type,
            license_startdate: License_startdate,
            license_enddate: License_enddate,
            max_users: Max_users,
            chatting_hour: Chatting_hour,
            screen_hour: Screen_hour,
            manager_phone : Manager_phone,
            manager_email: Manager_email,
            business_number: Business_number,
            rtc_mode : RTC_mode,
            rtc_url : RTC_url,
            mailLanguage : MailLanguage,
            sttKey : SttKey,
            sttRegioncode : SttRegioncode
        }

        if(!Confirm_name){
            event.preventDefault();
            infoMessage("회사명 중복 확인을 해주세요.")     
        }else if(License_type === "default"){
            event.preventDefault();
            infoMessage("계약 타입을 선택해 주세요.")
        }else if(Max_users === "default"){
            event.preventDefault();
            infoMessage("계정 수량을 선택해 주세요.")
        }
        else if(UseTranslate && (SttKey == "" || SttKey == null || SttRegioncode == "" || SttRegioncode == null)){
            event.preventDefault();
            infoMessage("STT Key 또는 지역 코드를 정확히 입력해 주세요.")
        }else if(MailLanguage === ""){
            event.preventDefault();
            console.log(SttKey)
            console.log(SttRegioncode)
            infoMessage("메일 언어를 선택해 주세요.")
        }
        // else if(!Confirm_email){
        //     event.preventDefault();
        //     infoMessage("이메일을 제대로 입력해주세요")
        // }
        else if(!Confirm_businessNum){
            event.preventDefault();
            infoMessage("사업자 번호를 제대로 입력해주세요.")
        }else if(Confirm_name === true && License_type !== "default" && License_startdate !== "" && License_startdate !== null &&
            License_enddate !== "" && License_enddate !== null && Max_users !== "default" && Chatting_hour !== "" && Chatting_hour !== null &&
            Screen_hour !== "" && Screen_hour !== null && Confirm_businessNum === true 
        ){       
            setLoading(true);
            
            registCompany(body)
                .then((response: any) => {
                    if(response.data.result === 1){
                        setLoading(false);
                        infoMessage("계정 등록 완료")
                        setSuccessRegister(true)
                    }else{
                        setLoading(false);
                        // infoMessage("계정 등록 실패")
                        alertMessage(response.data.resultMessage);
                    }
            })
            .catch((error)=>{
                setLoading(false);
                // alertMessage(error.response.data);
            });
            
        }else{
            event.preventDefault();
            infoMessage("모든 항목을 완료해주세요.")
        }
    }

    const onCompanyName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setCompany_name(event.currentTarget.value)
        if(Company_name.length < 1) setNameMessage("")
    }

    const onChangeRadioButton = (e: RadioChangeEvent) => {
        setLicense_type(e.target.value);
    };

    function formatDate(date:any) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    useEffect(() => {
        let date = new Date(License_startdate);
        if(Days !== "default"){
            let dayStr = Days.split("_");
            let dayNum = Number(dayStr[0]);
            if(dayStr[1] === "y"){
                date.setFullYear(date.getFullYear() + dayNum);
            }else if(dayStr[1] === "m"){
                date.setMonth(date.getMonth() + dayNum)
                
            }else if(dayStr[1] === "d"){
                date.setDate(date.getDate() + dayNum)
            }
            setLicense_enddate(formatDate(date))
        }
    }, [Days, License_enddate, License_startdate, License_type])
    
    const onCalendar = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setLicense_startdate(event.currentTarget.value)
        
    }

    const onChangeAccountCount = (value: string) => {
        setMax_users(value);
    };

    const onChattingHour = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setChatting_hour(event.currentTarget.value)
    }
    
    const onChangeSttKey = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setSttKey(event.currentTarget.value)
    }

    const onChangeSttRegionCode = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setSttRegioncode(event.currentTarget.value)
    }

    const onScreenHour = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setScreen_hour(event.currentTarget.value)
    }

    const onManagerEmail = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {

        // const emailRegex =
        //     /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
        const emailCurrent = event.currentTarget.value
        setManager_email(emailCurrent)
    
        // if (!emailRegex.test(Manager_email)) {
        //     setEmailMessage('이메일 형식을 확인해주세요.')
        //     setConfirm_email(false)
        // } else {
        //     setEmailMessage('올바른 이메일 형식이에요.')
        //     setConfirm_email(true)
        // }
    }

    const onChangeManagerPhone = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setManager_phone(event.currentTarget.value)
    }

    const onBusinessNumber = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        
        const businessNumRegex = /([0-9]{3})-?([0-9]{2})-?([0-9]{4})/
        setBusiness_number(event.currentTarget.value)

        if (!businessNumRegex.test(Business_number)) {
            setBusinessNumMessage('형식을 확인해주세요.')
            setConfirm_businessNum(false)
        } else {
            setBusinessNumMessage('올바른 형식이에요.')
            setConfirm_businessNum(true)
        }
    }

    function getDate(){
        var today = new Date();

        var year = today.getFullYear();
        var month = ('0' + (today.getMonth() + 1)).slice(-2);
        var day = ('0' + today.getDate()).slice(-2);

        var date = year + '-' + month  + '-' + day;
        return date;
    }

    const onChangeRTCRadioButton = (e: RadioChangeEvent) => {
        setRTC_mode(e.target.value)
    };

    const onChangeMailLanguage = (e: RadioChangeEvent) => {
        setMailLanguage(e.target.value)
    };
    return (
        <form style={{marginLeft: '2%'}}>
            <Title style={{marginBottom: '5%'}}>신규 사용자 회사 등록</Title>

            <div>
                <table style={{borderSpacing: '0 15px',borderCollapse: 'separate', border:'none'}}>
                    <tbody>
                        <tr>
                            <Item>1. 회사명</Item>
                            <Td>
                                <Input style={{width: "250px"}} id="company_name" onChange={onCompanyName} autoComplete="off" />
                                <Button onClick={confirmName} style={{marginLeft: "10px"}}>체크</Button><br/>
                                {Company_name.length > 0 && <span className={`message ${Confirm_name ? 'success' : 'error'}`}>{nameMessage}</span>}                         
                            </Td>
                        </tr>
                        <tr>
                            <Item>2. 계약 타입</Item>
                            <Td>
                                <Radio.Group onChange={onChangeRadioButton} value={License_type}>
                                    <Radio value={0}>테스트 버전</Radio>
                                    <Radio value={1}>정식 버전</Radio>
                                </Radio.Group>
                            </Td>
                        </tr>
                        <tr>
                            <Item>3. 기간 설정</Item>
                            <Td>
                                <input type="date" style={{marginRight: '10px', width:'115px'}} name="calendar" min={getDate()} onChange={onCalendar}/>
                                ~
                                <Input id="license_enddate" style={{width: "115px", margin: '0 10px'}} value={License_enddate} disabled />
                                <Selectbox LicenseType={License_type} Days={Days} setDays={setDays} LicenseStartdate={License_startdate}></Selectbox>
                            </Td>
                        </tr>
                        <tr> 
                            <Item>4. 계정 수량</Item>
                            <Td>
                                <Select defaultValue={"수량 선택"} style={{ width: '115px' }} onChange={onChangeAccountCount}>
                                    <Option value="5">5</Option>
                                    <Option value="10">10</Option>
                                    <Option value="15">15</Option>
                                    <Option value="50">50</Option>
                                    <Option value="100">100</Option>
                                    <Option value="250">250</Option>
                                    <Option value="500">500</Option>
                                    <Option value="1000">1,000</Option>
                                    <Option value="5000">5,000</Option>
                                </Select>
                            </Td>
                        </tr>
                        <tr>
                            <Item>5. RTC 모드</Item>
                            <Td>
                                <Radio.Group onChange={onChangeRTCRadioButton} value={RTC_mode}>
                                    <Radio value={0}>Agora</Radio>
                                    <Radio value={1}>MX RTC</Radio>
                                </Radio.Group>
                                
                            </Td>
                        </tr>
                        <tr>
                            <Item></Item>
                            <Td>
                                <Input type="text" placeholder='RTC url' style={{width: "250px"}} name="rtc_url" value={RTC_url} onChange={(e)=>{setRTC_url(e.target.value)}}/>
                            </Td>
                        </tr>
                        <tr>
                            <Item>6. 화상채팅 시간</Item>
                            <Td>
                                <NumberFormat style={{width: "250px"}} name="chatting_hour" onChange={onChattingHour} customInput={Input} thousandSeparator={true} />
                                <Checkbox style = {{marginLeft:'30px'}} onChange={onChange}>실시간 통역 사용</Checkbox>
                            </Td>
                        </tr>
                        <tr>
                            <Item> </Item>
                            <Td>
                                <div id="sttData" style={{display:'none', justifyContent:'space-evenly'}}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <div style={{marginRight:'20px', color:'red'}}>*STT Key</div><div><Input type="text" value={SttKey} onChange={onChangeSttKey}></Input></div>
                                    </div>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <div style={{margin:'0 20px 0 20px', color:'red'}}>*지역 코드</div><div><Input type="text" value={SttRegioncode} onChange={onChangeSttRegionCode}></Input></div>
                                    </div>
                                </div>
                            </Td>
                        </tr>
                        <tr>
                            <Item>7. 화면송출 시간</Item>
                            <Td>
                                <NumberFormat style={{width: "250px"}} name="screen_hour" onChange={onScreenHour} customInput={Input} thousandSeparator={true} />
                            </Td>
                        </tr>
                        <tr>
                            <Item>8. 담당자 이메일</Item>
                            <Td>
                                <Input type="email" style={{width: "250px"}} name="manager_email" onChange={onManagerEmail} autoComplete="off"/><span style={{color:'red', marginLeft:'2px'}}>*직접 입력하여 주십시오.(복사/붙여넣기 미허용)</span><br/>
                                {/* {Manager_email.length > 0 && <span className={`message ${Confirm_email ? 'success' : 'error'}`}>{emailMessage}</span>} */}
                            </Td>
                        </tr>
                        <tr>
                            <Item>9. 등록 안내 메일 언어</Item>
                            <Td>
                                <Radio.Group onChange={onChangeMailLanguage} value={MailLanguage}>
                                    <Radio value={"KR"}>KR</Radio>
                                    <Radio value={"EN"}>EN</Radio>
                                    {/* <Radio value={1}>JP</Radio> */}
                                </Radio.Group> 
                            </Td>
                        </tr>
                        <tr>
                            <Item>10. 담당자 연락처</Item>
                            <Td>
                            {/* <Input style={{width: "250px"}} placeholder={"숫자만 입력해 주세요."} onChange={onChangeManagerPhone}/>  */}
                            <NumberFormat style={{width: "250px"}} name="business_number" onChange={onChangeManagerPhone}
                                        customInput={Input} format="###-####-####" autoComplete="off" /><br/>
                            </Td>
                        </tr>
                        <tr>
                            <Item>11. 사업자번호</Item>
                            <Td>
                                <NumberFormat style={{width: "250px"}} name="business_number" onChange={onBusinessNumber} 
                                customInput={Input} format="###-##-#####" autoComplete="off" /><br/>
                                {Business_number.length > 0 && <span className={`message ${Confirm_businessNum ? 'success' : 'error'}`}>
                                                                    {businessNumMessage}
                                                                </span>}
                            </Td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{width: '55%',margin:'20px auto'}}><Button onClick={onClickHandler} disabled={loading}>Save</Button></div>
        </form>
    )
}

export default NewCompany
