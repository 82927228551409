import { 
    // ReactElement, // wj : 언어 변경 적용 시 사용 예정
    useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './Main.css';
import logo1 from './images/01_logo_mxspace_w.png'
import logo2 from './images/01_logo_mxspace_b.png'
import hamBtn from './images/btn_hamburger.png'
import closeBtn from './images/btn_close.png'
// import { useTranslation } from 'react-i18next'// wj : 언어 변경 적용 시 사용 예정

export default function MXSPACEweb_Nav(props:any) {
    // const { t, i18n } = useTranslation() // wj : 언어 변경 적용 시 사용 예정
    let { goodsTabs, showInquiryBtn=true } = props
    let navigate = useNavigate();
    const [menuToggle, setmenuToggle] = useState(false);
    useEffect(()=>{
        if(menuToggle) document.body.classList.add('lockScroll')
        else if(!menuToggle) document.body.classList.remove('lockScroll')
    }, [menuToggle])
    const ScrollnMenuClose = (onMoveToElementFunc: ()=>void) => {
        setmenuToggle(false)
        onMoveToElementFunc()
    }
    // const changelanguageToKo = () => i18n.changeLanguage('ko') // wj : 언어 변경 적용 시 사용 예정
    // const changelanguageToEn = () => i18n.changeLanguage('en') // wj : 언어 변경 적용 시 사용 예정
    return (
        <>
        <div className="topNav">
            <img alt='로고1' className="logo" src={logo1} onClick={()=>{navigate('/')}} />
            {showInquiryBtn &&
            <>
            <div className="widePc widePcf flexRow topMenuList EnFont">
                {showInquiryBtn && goodsTabs.map((tab: any, idx : number) => {
                    return (<div key={idx} className='cursorPointer' onClick={tab.onMoveToElement}>{tab.name}</div>)
                })}
            </div>
            <div className="ipadMini ipadMinif flexRow topMenuList EnFont">
                {showInquiryBtn && goodsTabs.map((tab: any, idx : number) => {
                    return (<div key={idx} className='cursorPointer' onClick={tab.onMoveToElement}>{tab.name}</div>)
                })}
            </div>
            </>}
            <div className='langu widePc EnFont cursorPointer'>
                {/* 기능 추가 시 오픈, 언어 변경 함수 추가 되어 있음 */}
                {/* <span onClick={changelanguageToKo}>KO</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span style={{color: "gray"}} onClick={changelanguageToEn}>EN</span> */}
                </div>
            <div className='langu ipadMini EnFont cursorPointer'>
                {/* 기능 추가 시 오픈 */}
                {/* <span>KO</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span style={{color: "gray"}}>EN</span> */}
                </div>
            {showInquiryBtn && showInquiryBtn && <img className='logo wideMobile cursorPointer' src={hamBtn} alt='모바일 햄버거 버튼' onClick={()=>setmenuToggle(!menuToggle)} />}
        </div>

        <div className={`toggleMenuWrapper ${menuToggle ? 'active' : 'inactive'} wideMobile`}>
            <div className="topNav">
                <img alt='로고1' className="logo" src={logo2}/>
                <div className="flexRow">
                    <div className='EnFont'>
                        {/* 기능 추가 시 오픈 */}
                        {/* <span>KO</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span style={{color: "gray"}}>EN</span> */}
                        </div>
                </div>
                <img alt='로고1' className="logo" src={closeBtn} onClick={()=>setmenuToggle(!menuToggle)}/>
                
            </div>
            <div className='toggleMenuList'>
                {showInquiryBtn && goodsTabs.map((tab: any, idx : number) => {
                        return (<div key={idx} className='toggleMenuItem EnFont' onClick={()=>ScrollnMenuClose(tab.onMoveToElement)}>{tab.name}</div>)
                    })}
            </div>
        </div>
    </>
    )
}