import React, { useEffect, useState, forwardRef, Ref, HTMLAttributes } from 'react';

import { useTranslation } from 'react-i18next';

import SwiperCore, { Navigation } from 'swiper';

import CheckMobile from 'components/utils/MobileCheck';

import {
    ModalCloseBtn,
    StyledFunction,
    StyledFunctionSwiper,
    StyledFunctionSwiperSlide,
    SwiperNextBtn,
    SwiperPlusIcon,
    SwiperPrevBtn,
} from './style';

import function1Banner from './images/functionSection/swiper/function1.png';
import function2Banner from './images/functionSection/swiper/function2.png';
import function3Banner from './images/functionSection/swiper/function3.png';
import function4Banner from './images/functionSection/swiper/function4.png';
import function5Banner from './images/functionSection/swiper/function5.png';
import function6Banner from './images/functionSection/swiper/function6.png';
import function7Banner from './images/functionSection/swiper/function7.png';
import function8Banner from './images/functionSection/swiper/function8.png';
import function9Banner from './images/functionSection/swiper/function9.png';
import function10Banner from './images/functionSection/swiper/function10.png';

import functionPopupImg1_1 from './images/functionSection/popup/function1_1.png';
import functionPopupImg1_2 from './images/functionSection/popup/function1_2.png';
import functionPopupImg2_1 from './images/functionSection/popup/function2_1.png';
import functionPopupImg3_1 from './images/functionSection/popup/function3_1.png';
import functionPopupImg3_2 from './images/functionSection/popup/function3_2.png';
import functionPopupImg4_1 from './images/functionSection/popup/function4_1.png';
import functionPopupImg4_2 from './images/functionSection/popup/function4_2.png';
import functionPopupImg5_1 from './images/functionSection/popup/function5_1.png';
import functionPopupImg5_2 from './images/functionSection/popup/function5_2.png';
import functionPopupImg6_1 from './images/functionSection/popup/function6_1.png';
import functionPopupImg6_2 from './images/functionSection/popup/function6_2.png';
import functionPopupImg7_1 from './images/functionSection/popup/function7_1.png';
import functionPopupImg7_2 from './images/functionSection/popup/function7_2.png';
import functionPopupImg7_2_mb from './images/functionSection/popup/function7_2_mb.png';
import functionPopupImg8_1 from './images/functionSection/popup/function8_1.png';
import functionPopupImg8_2 from './images/functionSection/popup/function8_2.png';
import functionPopupImg9_1 from './images/functionSection/popup/function9_1.png';
import functionPopupImg9_2 from './images/functionSection/popup/function9_2.png';
import functionPopupImg10_1 from './images/functionSection/popup/function10_1.png';

interface AboutSectionProps extends HTMLAttributes<HTMLDivElement> {}

const FunctionSection = forwardRef((props: AboutSectionProps, ref: Ref<HTMLDivElement>) => {
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [swiper, setSwiper] = useState<SwiperCore>();
    const [modalFunctionContent, setModalFunctionContent] = useState<{
        title?: string;
        txt?: string;
        contents?: {
            img?: string;
            pcTxt?: string;
            mbTxt?: string;
        }[];
    }>({});
    const [activeSlide, setActiveSlide] = useState<number>(0);
    const [swiperBtnOpacity, setSwiperBtnOpacity] = useState<{ prev: number; next: number }>({
        prev: 0.3,
        next: 1,
    });

    const isMobile = CheckMobile();

    const functionSwiper = [
        {
            img: function1Banner,
            txtColor: 'white',
            title: t('functionSwiperBannerTit1'),
            txt: t('functionSwiperBannerTxt1'),
        },
        {
            img: function2Banner,
            txtColor: 'black',
            title: t('functionSwiperBannerTit2'),
            txt: t('functionSwiperBannerTxt2'),
        },
        {
            img: function3Banner,
            txtColor: 'white',
            title: t('functionSwiperBannerTit3'),
            txt: t('functionSwiperBannerTxt3'),
        },
        {
            img: function4Banner,
            txtColor: 'white',
            title: t('functionSwiperBannerTit4'),
            txt: t('functionSwiperBannerTxt4'),
        },
        {
            img: function5Banner,
            txtColor: 'white',
            title: t('functionSwiperBannerTit5'),
            txt: t('functionSwiperBannerTxt5'),
        },
        {
            img: function6Banner,
            txtColor: 'white',
            title: t('functionSwiperBannerTit6'),
            txt: t('functionSwiperBannerTxt6'),
        },
        {
            img: function7Banner,
            txtColor: 'black',
            title: t('functionSwiperBannerTit7'),
            txt: t('functionSwiperBannerTxt7'),
        },
        {
            img: function8Banner,
            txtColor: 'white',
            title: t('functionSwiperBannerTit8'),
            txt: t('functionSwiperBannerTxt8'),
        },
        {
            img: function9Banner,
            txtColor: 'white',
            title: t('functionSwiperBannerTit9'),
            txt: t('functionSwiperBannerTxt9'),
        },
        {
            img: function10Banner,
            txtColor: 'white',
            title: t('functionSwiperBannerTit10'),
            txt: t('functionSwiperBannerTxt10'),
        },
    ];

    const functionPopupContents = [
        {
            title: t('functionSwiperBannerTit1'),
            txt: t('functionSwiperBannerTxt1'),
            contents: [
                {
                    img: functionPopupImg1_1,
                    pcTxt: t('functionPopupContentTxt1_1'),
                },
                {
                    img: functionPopupImg1_2,
                    pcTxt: t('functionPopupContentTxt1_2'),
                },
            ],
        },
        {
            title: t('functionSwiperBannerTit2'),
            txt: t('functionSwiperBannerTxt2'),
            contents: [
                {
                    img: functionPopupImg2_1,
                    pcTxt: t('functionPopupContentTxt2_1_pc'),
                    mbTxt: t('functionPopupContentTxt2_1_mb'),
                },
            ],
        },
        {
            title: t('functionSwiperBannerTit3'),
            txt: t('functionSwiperBannerTxt3'),
            contents: [
                {
                    img: functionPopupImg3_1,
                    pcTxt: t('functionPopupContentTxt3_1_pc'),
                    mbTxt: t('functionPopupContentTxt3_1_mb'),
                },
                {
                    img: functionPopupImg3_2,
                    pcTxt: t('functionPopupContentTxt3_2_pc'),
                    mbTxt: t('functionPopupContentTxt3_2_mb'),
                },
            ],
        },
        {
            title: t('functionSwiperBannerTit4'),
            txt: t('functionPopupContentTitle4'),
            contents: [
                {
                    img: functionPopupImg4_1,
                    pcTxt: t('functionPopupContentTxt4_1_pc'),
                    mbTxt: t('functionPopupContentTxt4_1_mb'),
                },
                {
                    img: functionPopupImg4_2,
                    pcTxt: t('functionPopupContentTxt4_2_pc'),
                    mbTxt: t('functionPopupContentTxt4_2_mb'),
                },
            ],
        },
        {
            title: t('functionSwiperBannerTit5'),
            txt: t('functionPopupContentTitle5'),
            contents: [
                {
                    img: functionPopupImg5_1,
                    pcTxt: t('functionPopupContentTxt5_1_pc'),
                    mbTxt: t('functionPopupContentTxt5_1_mb'),
                },
                {
                    img: functionPopupImg5_2,
                    pcTxt: t('functionPopupContentTxt5_2_pc'),
                    mbTxt: t('functionPopupContentTxt5_2_mb'),
                },
            ],
        },
        {
            title: t('functionSwiperBannerTit6'),
            txt: t('functionSwiperBannerTxt6'),
            contents: [
                {
                    img: functionPopupImg6_1,
                    pcTxt: t('functionPopupContentTxt6_1_pc'),
                    mbTxt: t('functionPopupContentTxt6_1_mb'),
                },
                {
                    img: functionPopupImg6_2,
                    pcTxt: t('functionPopupContentTxt6_2_pc'),
                    mbTxt: t('functionPopupContentTxt6_2_mb'),
                },
            ],
        },
        {
            title: t('functionSwiperBannerTit7'),
            txt: t('functionSwiperBannerTxt7'),
            contents: [
                {
                    img: functionPopupImg7_1,
                    pcTxt: t('functionPopupContentTxt7_1_pc'),
                    mbTxt: t('functionPopupContentTxt7_1_mb'),
                },
                {
                    img: functionPopupImg7_2,
                    pcTxt: t('functionPopupContentTxt7_2'),
                },
            ],
        },
        {
            title: t('functionSwiperBannerTit8'),
            txt: t('functionSwiperBannerTxt8'),
            contents: [
                {
                    img: functionPopupImg8_1,
                    pcTxt: t('functionPopupContentTxt8_1_pc'),
                    mbTxt: t('functionPopupContentTxt8_1_mb'),
                },
                {
                    img: functionPopupImg8_2,
                    pcTxt: t('functionPopupContentTxt8_2_pc'),
                    mbTxt: t('functionPopupContentTxt8_2_mb'),
                },
            ],
        },
        {
            title: t('functionSwiperBannerTit9'),
            txt: t('functionSwiperBannerTxt9'),
            contents: [
                {
                    img: functionPopupImg9_1,
                    pcTxt: t('functionPopupContentTxt9_1_pc'),
                    mbTxt: t('functionPopupContentTxt9_1_mb'),
                },
                {
                    img: functionPopupImg9_2,
                },
            ],
        },
        {
            title: t('functionSwiperBannerTit10'),
            txt: t('functionSwiperBannerTxt10'),
            contents: [
                {
                    img: functionPopupImg10_1,
                    pcTxt: t('functionPopupContentTxt10_1_pc'),
                    mbTxt: t('functionPopupContentTxt10_1_mb'),
                },
            ],
        },
    ];

    const handleOnSlideChange = (swiperInstance: SwiperCore) => {
        const { realIndex } = swiperInstance;
        const lastIndex = functionSwiper.length;

        if (realIndex === 0) {
            setSwiperBtnOpacity({
                prev: 0.3,
                next: 1,
            });
        } else if (isMobile ? realIndex + 1 >= lastIndex : realIndex + 3 >= lastIndex) {
            setSwiperBtnOpacity({
                prev: 1,
                next: 0.3,
            });
        } else {
            setSwiperBtnOpacity({
                prev: 1,
                next: 1,
            });
        }
    };

    const handleFunctionModal = (index: number) => {
        setModalFunctionContent(functionPopupContents[index]);
        setTimeout(() => {
            setModalVisible(true);
        }, 100);

        setActiveSlide(index);
    };

    const handleFunctionModalClose = () => {
        setTimeout(() => {
            setModalFunctionContent({});
        }, 500);

        setModalVisible(false);

        if (swiper) {
            swiper.slideTo(activeSlide + 1, 500);
        }
    };

    const handleOutsideClick = (e: any) => {
        if (e.target.classList.contains('modalOverlayContainer')) {
            handleFunctionModalClose();
        }
    };

    useEffect(() => {
        if (modalVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [modalVisible]);

    return (
        <StyledFunction $modalVisible={modalVisible} ref={ref}>
            <div className="functionWrapper">
                <p className="functionTitle">FUNCTION</p>
                <StyledFunctionSwiper
                    modules={[Navigation]}
                    navigation={{
                        prevEl: '.swiper-navi-prev',
                        nextEl: '.swiper-navi-next',
                    }}
                    slidesPerView={isMobile ? 1.18 : 3}
                    onSwiper={setSwiper}
                    onSlideChange={handleOnSlideChange}
                >
                    {functionSwiper.map((item, i) => {
                        return (
                            <StyledFunctionSwiperSlide
                                $txtColor={item.txtColor}
                                key={`function-item-${i}`}
                                onClick={() => {
                                    handleFunctionModal(i);
                                }}
                            >
                                <div>
                                    <img src={item.img} alt="" className="functionSlideImg" />
                                    <div className="swiperTxtWrapper">
                                        <p
                                            className={`functionSlideTitle ${item.txtColor}`}
                                            dangerouslySetInnerHTML={{
                                                __html: item.title,
                                            }}
                                        />
                                        <p
                                            className={`functionSlideTxt ${item.txtColor}`}
                                            dangerouslySetInnerHTML={{
                                                __html: item.txt,
                                            }}
                                        />
                                        <SwiperPlusIcon />
                                    </div>
                                </div>
                            </StyledFunctionSwiperSlide>
                        );
                    })}
                </StyledFunctionSwiper>
                <div className="navigationWrapper">
                    <div
                        className="swiper-navi swiper-navi-prev"
                        style={{ opacity: swiperBtnOpacity.prev }}
                    >
                        <SwiperPrevBtn />
                    </div>
                    <div
                        className="swiper-navi swiper-navi-next"
                        style={{ opacity: swiperBtnOpacity.next }}
                    >
                        <SwiperNextBtn />
                    </div>
                </div>
            </div>
            <div className="functionModal">
                <div className="modalOverlayContainer" onClick={handleOutsideClick}>
                    <div className="modalOverlay">
                        {modalFunctionContent && (
                            <div className="modalContentContainer">
                                <p
                                    className="modalTitle"
                                    dangerouslySetInnerHTML={{
                                        __html: modalFunctionContent['title']
                                            ? modalFunctionContent['title']
                                            : '',
                                    }}
                                />
                                <p
                                    className="modalTxt"
                                    dangerouslySetInnerHTML={{
                                        __html: modalFunctionContent['txt']
                                            ? modalFunctionContent['txt']
                                            : '',
                                    }}
                                />

                                {modalFunctionContent['contents'] &&
                                    modalFunctionContent['contents'].map((mc, idx) => {
                                        return (
                                            <div
                                                className="modalContent"
                                                key={`modal-content-${idx}`}
                                            >
                                                {mc.pcTxt && (
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: isMobile
                                                                ? mc.mbTxt || mc.pcTxt
                                                                : mc.pcTxt,
                                                        }}
                                                    />
                                                )}

                                                {activeSlide === 6 && idx === 1 && isMobile ? (
                                                    <img src={functionPopupImg7_2_mb} alt="" />
                                                ) : (
                                                    mc.img && <img src={mc.img} alt="" />
                                                )}
                                            </div>
                                        );
                                    })}
                            </div>
                        )}
                        <div className="modalCloseBtn" onClick={handleFunctionModalClose}>
                            <ModalCloseBtn />
                        </div>
                    </div>
                </div>
            </div>
        </StyledFunction>
    );
});

export default FunctionSection;
