import { PagemoveDispatchType, PagemoveType} from "../_actions/SideMenuAction";
import { PAGEMOVE_SUCCESS ,PAGEMOVE_FAIL } from '../_actions/types'

const initialState = {isChanged: false}

const SideMenuReducer = (state = initialState, action: PagemoveDispatchType) => {

    switch (action.type) {
        case PAGEMOVE_FAIL: 
            return {
                ...state,
                isChanged : action.payload
            }
        case PAGEMOVE_SUCCESS:             
            return {
                ...state,
                isChanged : action.payload
            }
        default:
            return state;
    }

}

export default SideMenuReducer;