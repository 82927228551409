import { useEffect, useState } from 'react';
import { Modal, Input, Select, Button } from 'antd';
import styled from "styled-components";
import { createAppKey } from '../../../_actions/AppkeyAction';
import { alertMessage } from "../../utils/utils";
import { DataType } from './AppKey';
import CODE from '../../../constants/code';

const { Option } = Select;

const ModalBodyLayout = styled.div`
    display: grid; 
    grid-template-columns: 25% auto; 
    grid-template-rows: 50px 50px 50px;
    align-items: center;
`;

export default function AppKeyAddModal(props: any) {
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [appKey, setAppKey] = useState<DataType>(props.appKeyData ? props.appKeyData : {} as any);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {

        const dataToSubmit = {
            // "name" : appKey.name,
            "companyuid": appKey.companyuid,
            "twAppkey": appKey.twAppkey,
            "twUrl": appKey.twUrl
        }

        createAppKey(dataToSubmit).then((res) => {
            if (Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)) {
                Modal.info({
                    content: res.data.resultMessage,
                })
                props.changeReload();
            } else {
                alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
            }
        })

        setIsModalVisible(false);
        props.closeeModal();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        props.closeeModal();
    };

    const ontwAppkeyHandler = (event: { currentTarget: { value: string; }; }) => {
        setAppKey({ ...appKey, twAppkey: event.currentTarget.value });
    }

    const ontwUrlHandler = (event: { currentTarget: { value: string; }; }) => {
        setAppKey({ ...appKey, twUrl: event.currentTarget.value });
    }

    const onCompanyListHandler = (value: string) => {
        let data = JSON.parse(value);
        setAppKey({ ...appKey, name: data.name, companyuid: data.companyuid });
    }

    useEffect(() => {
        showModal();
    }, [])

    return (
        <>
            <Modal title={`APP Key ${props.appKeyData ? '수정' : '생성'}`} open={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="ok" type="primary" onClick={handleOk}
                        disabled={!appKey.twAppkey ? true : false || !appKey.twUrl ? true : false}
                    >
                        OK
                    </Button>
                ]}>
                <ModalBodyLayout>
                    <div>company name :</div>
                    <div>
                        {props.appKeyData ?
                            <Input value={appKey?.name} disabled />
                            :
                            <Select onChange={onCompanyListHandler} style={{ width: '100%' }} defaultValue={"선택해주세요"}>
                                {props.companyData && props.companyData?.map((item: any) => {
                                    return (<Option key={item.companyuid} value={JSON.stringify(item)} >{item.name}</Option>)
                                })}
                            </Select>
                        }
                    </div>
                    <div>APP Name:</div>
                    <div><Input value={appKey.twAppkey} onChange={ontwAppkeyHandler} /></div>
                    <div>Share Link:</div>
                    <div><Input value={appKey.twUrl} onChange={ontwUrlHandler} /></div>
                </ModalBodyLayout>
            </Modal>
        </>
    );
};