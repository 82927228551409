import { NoticeDetailType } from 'types/notice';
import { NoticeDetail } from './NoticeDetail';
import { StyledHeaderTitle } from './styles';
import { useEffect, useState } from 'react';

export const EditNotice = () => {
    const [, , , , id] = window.location.pathname.split('/');

    const [noticeDetail, setNoticeDetail] = useState<NoticeDetailType>({
        ko: {
            title: '',
            contents: '',
        },
        en: {
            title: '',
            contents: '',
        },
        jp: {
            title: '',
            contents: '',
        },
        exposeStartDt: '',
        exposeEndDt: '',
    });

    const handleUpdate = (e: NoticeDetailType) => {};

    const getNoticeDetail = () => {
        // 상세 데이터 조회 API
        setNoticeDetail({
            ko: {
                title: '제목입니다',
                contents: '테스트 내용ㅇ',
            },
            en: {
                title: '',
                contents: '',
            },
            jp: {
                title: '',
                contents: '',
            },
            exposeStartDt: '2024-04-17 00:43',
            exposeEndDt: '2024-04-17 00:43',
        });
    };

    const handleDelete = () => {
        //삭제 API
    };

    useEffect(() => {
        if (id) {
            getNoticeDetail();
        }
    }, [id]);

    return (
        <div style={{ marginLeft: '2%' }}>
            <StyledHeaderTitle>공지 상세</StyledHeaderTitle>
            <NoticeDetail
                type="update"
                noticeData={noticeDetail}
                handleData={(e) => handleUpdate(e)}
                handleDelete={handleDelete}
            />
        </div>
    );
};
