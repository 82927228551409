import React, { useEffect, useState } from 'react';
import visitBtn from './images/01_btn_visit@2x 1.png';
import logo from '../main/images/ui_logo_white@3x.png';
import mxview from './images/logo_solution_MXview.png';
import androidBtn from './images/01_btn_android_black.png';
import windowBtn from './images/01_btn_window_black.png';
import korManual from './images/01_btn_korx2.png';
import engManual from './images/01_btn_engx2.png';
import markerImage from './images/mxview_vumarker.png';

export function MXSpaceDownload() {
    const moveToHomePage = () => {
        window.open('https://ymx.co.kr');
    };

    const downloadMxspaceAOS = () => {
        try {
            const link = document.createElement('a');
            link.href = `${process.env.REACT_APP_SERVER_BASE_URL}/common/resource/installer?extension=apk&code=O84GHGH4`;
            link.setAttribute('download', 'MXSpaceSetup.apk');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (e) {
            console.log(e);
        }
    };

    const downloadMxspaceExe = () => {
        try {
            const link = document.createElement('a');
            link.href = `${process.env.REACT_APP_SERVER_BASE_URL}/common/resource/installer?extension=exe&code=O84GHGH4`;
            link.setAttribute('download', 'MXSpaceSetup.exe');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (e) {
            console.log(e);
        }
    };
    const downloadMxviewAOS = () => {
        try {
            const link = document.createElement('a');
            link.href = `${process.env.REACT_APP_SERVER_BASE_URL}/common/resource/installer/mxview?extension=apk`;
            link.setAttribute('download', 'MXView.apk');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (e) {
            console.log(e);
        }
    };

    const manualKR = () => {
        try {
            const link = document.createElement('a');
            link.href = `${process.env.REACT_APP_SERVER_BASE_URL}/manual/MXspaceUserManual_kr`;
            // link.setAttribute('download', 'MXSpaceSetup.exe');
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (e) {
            console.log(e);
        }
    };

    const manualEN = () => {
        try {
            const link = document.createElement('a');
            link.href = `${process.env.REACT_APP_SERVER_BASE_URL}/manual/MXspaceUserManual_en`;
            link.target = '_blank';
            // link.setAttribute('download', 'MXSpaceSetup.exe');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (e) {
            console.log(e);
        }
    };

    const viewManualEN = () => {
        try {
            const link = document.createElement('a');
            link.href = `${process.env.REACT_APP_SERVER_BASE_URL}/manual/MXviewUserManual_en`;
            link.target = '_blank';
            // link.setAttribute('download', 'MXSpaceSetup.exe');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (e) {
            console.log(e);
        }
    };

    const viewManualKR = () => {
        try {
            const link = document.createElement('a');
            link.href = `${process.env.REACT_APP_SERVER_BASE_URL}/manual/MXviewUserManual_kr`;
            link.target = '_blank';
            // link.setAttribute('download', 'MXSpaceSetup.exe');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '50px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        backgroundColor: 'black',
                        borderRadius: '14px',
                        width: '90%',
                        flexWrap: 'wrap',
                        padding: '10px',
                        maxWidth: 1800,
                    }}
                >
                    <img style={{ width: 209, height: 67 }} alt="" src={logo} />

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <p style={{ color: 'white', fontSize: 'xx-large', textAlign: 'center' }}>
                            XR-based Enterprise-grade digital twin solution provider​
                        </p>
                        <img
                            onClick={moveToHomePage}
                            style={{ width: 160, marginLeft: 'auto', cursor: 'pointer' }}
                            alt=""
                            src={visitBtn}
                        />
                    </div>
                </div>
            </div>
            <hr />
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        width: '90%',
                        maxWidth: 1800,
                        margin: '25px auto',
                        flexWrap: 'wrap',
                    }}
                >
                    <div>
                        <img alt="" src="/images/logo_mxspace2.png" />
                    </div>
                    <p style={{ fontSize: 'x-large', textAlign: 'center', margin: 0 }}>
                        Enterprise Remote Collaboration XR-based workspace platform​
                    </p>
                </div>
                <div
                    style={{
                        margin: '30px auto',
                        width: '90%',
                        border: '1px solid gray',
                        borderRadius: '14px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            flexWrap: 'wrap',
                            padding: '50px 0',
                            maxWidth: 1800,
                            gap: 50,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                gap: 50,
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                            }}
                        >
                            <div>
                                <p
                                    style={{
                                        textAlign: 'center',
                                        fontSize: 'x-large',
                                    }}
                                >
                                    Mobile
                                </p>
                                <img
                                    style={{ height: 100, cursor: 'pointer' }}
                                    alt=""
                                    src={androidBtn}
                                    onClick={() => downloadMxspaceAOS()}
                                />
                            </div>
                            <div>
                                <p
                                    style={{
                                        textAlign: 'center',
                                        fontSize: 'x-large',
                                    }}
                                >
                                    PC
                                </p>
                                <img
                                    style={{ height: 100, cursor: 'pointer' }}
                                    alt=""
                                    src={windowBtn}
                                    onClick={() => downloadMxspaceExe()}
                                />
                            </div>
                        </div>
                        <div>
                            <p
                                style={{
                                    textAlign: 'center',
                                    fontSize: 'x-large',
                                }}
                            >
                                Manual
                            </p>
                            <div
                                style={{
                                    display: 'flex',
                                    gap: 50,
                                }}
                            >
                                <img
                                    style={{
                                        cursor: 'pointer',
                                        flexWrap: 'wrap',
                                        height: 100,
                                    }}
                                    alt=""
                                    src={korManual}
                                    onClick={manualKR}
                                />
                                <img
                                    style={{
                                        cursor: 'pointer',
                                        flexWrap: 'wrap',
                                        height: 100,
                                    }}
                                    alt=""
                                    src={engManual}
                                    onClick={manualEN}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        width: '90%',
                        maxWidth: 1800,
                        margin: '25px auto',
                        flexWrap: 'wrap',
                    }}
                >
                    <div>
                        <img style={{ width: 190 }} alt="" src={mxview} />
                    </div>
                    <p style={{ fontSize: 'x-large', textAlign: 'center', margin: 0 }}>
                        XR-based Digital Construction Management Solution​​
                    </p>
                </div>
                <div
                    style={{
                        margin: '30px auto',
                        width: '90%',
                        border: '1px solid gray',
                        borderRadius: '14px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            flexWrap: 'wrap',
                            padding: '50px 0',
                            maxWidth: 1800,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                gap: 50,
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                            }}
                        >
                            <div>
                                <p
                                    style={{
                                        textAlign: 'center',
                                        fontSize: 'x-large',
                                    }}
                                >
                                    Mobile
                                </p>
                                <img
                                    style={{ height: 100, cursor: 'pointer' }}
                                    alt=""
                                    src={androidBtn}
                                    onClick={() => downloadMxviewAOS()}
                                />
                            </div>
                            <div>
                                <p
                                    style={{
                                        textAlign: 'center',
                                        fontSize: 'x-large',
                                    }}
                                >
                                    PC
                                </p>
                                <div
                                    style={{
                                        border: '2.5px solid black',
                                        width: '250px',
                                        height: '100px',
                                        borderRadius: '8px',
                                        textAlign: 'center',
                                        fontSize: '1.2rem',
                                    }}
                                >
                                    <div style={{ marginTop: '17px' }}>Service scheduled for​</div>
                                    <div>October 2024​</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p
                                style={{
                                    textAlign: 'center',
                                    fontSize: 'x-large',
                                }}
                            >
                                AR Marker
                            </p>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100px',
                                }}
                            >
                                <a
                                    href={markerImage}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecorationLine: 'underline',
                                        fontSize: 30,
                                    }}
                                >
                                    mxview_armarker
                                </a>
                            </div>
                        </div>
                        <div>
                            <p
                                style={{
                                    textAlign: 'center',
                                    fontSize: 'x-large',
                                }}
                            >
                                Manual
                            </p>
                            <div
                                style={{
                                    display: 'flex',
                                    gap: 50,
                                }}
                            >
                                <img
                                    style={{
                                        cursor: 'pointer',
                                        flexWrap: 'wrap',
                                        height: 100,
                                    }}
                                    alt=""
                                    src={korManual}
                                    onClick={viewManualKR}
                                />
                                <img
                                    style={{
                                        cursor: 'pointer',
                                        flexWrap: 'wrap',
                                        height: 100,
                                    }}
                                    alt=""
                                    src={engManual}
                                    onClick={viewManualEN}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
