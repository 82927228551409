// libraries
import styled from 'styled-components';

export const SpinContainer = styled.div<{ capacity: number }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, ${props => props.capacity});
  z-index: 9999;
  .pageLoader_wrapper {
    text-align: center;
    &_text {
      margin: 15px 0px;
      font-size: 18px;
      color: #fff;
    }
  }
`;
