import { Space, Table, Button } from 'antd';
import type { ColumnsType, TableProps } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import styled from "styled-components";
import MapListAddModal from './MapListAddModal';
import MapSettingEditModal from './MapSettingEditModal';
// import BundleUploadModal from './BundleUploadModal'
import { getCompanyMaplist } from '../../../_actions/MapAction';
import CODE from '../../../constants/code';

const Title = styled.h3`
    border-bottom: 2px solid red;
    width: 75%;
    padding-bottom: 2px;
    font-weight: 700;
`;
const Title2 = styled.h3`
    border-bottom: 2px solid red;
    width: 75%;
    padding-bottom: 2px;
    font-weight: 700;
    display : flex;
    justify-content : space-between;
`;
// 사업자 인덱스로 정렬한 배열을 주시는데
// 구성요소 DataType
export interface CompanyMapType {
    // key: React.Key;
    companyuid: string;
    name: string;
    maplist: MapType[];
}
export interface MapType {
    // key: React.Key;
    assign: number; // 할당 여부
    order: number; // 사용 X
    mapid: number;
    mapname: string;
    thumbnail: string;
    thumbnail_p : string;
    status: number; // 디폴트 여부 --> 업체에 최초 맵 데이터 추가 시 디폴트면 할당으로 추가해서 주기로함
}
export default function MapSetting(props:any) {
    const [isBundleModal, setIsBundleModal] = useState(false);
    const [isCreateModal, setIsCreateModal] = useState(false);
    const [isEditModal, setIsEditModal] = useState(false);
    const [isReload, setIsReload] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [companyMapData, setCompanyMapData] = useState<CompanyMapType[]>();
    const [selectedCompanyMapList, setSelectedCompanyMapList] = useState([]);
    const columns: ColumnsType<CompanyMapType> = [
        {
            title: 'No',
            dataIndex: 'key',
            key: 'key',
            align: 'center',
            width: 75,
            render: (text, row, index) => {
                return(<div>{(currentPage - 1) * 10 + index + 1}</div>)
            }
        },
        {
            title: '회사명',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            width: 200
        },
        {
            title: '룸 리스트',
            dataIndex: 'maplist',
            key: 'maplist',
            align: 'center',
            render: (text, record) =>
            record.maplist.length>0 && record.maplist.filter(c=>c.status=== 1 || c.assign===1).map((item:any, idx:number) => {
                    return(<span key={item.mapname} style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                        {item.mapname}{idx===record.maplist.filter(c=>c.status=== 1 || c.assign===1).length-1 ? '' : ', ' }
                    </span>)
                }),
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 160,
            render: (record) => (
                <Space size="small">
                    {record.maplist.length>0 ?
                    <Button onClick={()=>{openEditModal({maplist : record.maplist, restMapList:record.restMapList, companyuid : record.companyuid})}}>Edit</Button>
                    :<Button onClick={()=>{openCreateModal()}}>Create</Button>}
                </Space>
            )
        },
    ];
    const onChange: TableProps<CompanyMapType>['onChange'] = (pagination, filters, sorter, extra) => {
        setCurrentPage(pagination.current || 1)
    };
    useEffect(() => {
        getCompanyMaplist().then((res)=>{
            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                setCompanyMapData(res.data.companyMapList);
            }
        })
    }, [isReload])

    const changeReload = () => {
        setIsReload(!isReload);
    }
    const openCreateModal = () => {
        setIsCreateModal(true);
    }
    const closeCreateModal = () => {
        setIsCreateModal(false);

    }
    const openEditModal = (data:any) => {
        setIsEditModal(true);
        setSelectedCompanyMapList(data)
    }
    const closeEditModal = (changedData : any) => {
        setIsEditModal(false);
        setSelectedCompanyMapList([])
    }
    const closeBundleModal = () => {
        setIsBundleModal(false);
    }
    return (
        <div style={{margin :'0 2%'}}>
            <br/><br/>
            <Title2>
            <span>Map Create</span>
            <div>
                {/* <Button onClick={openBundleModal}>번들 업로드</Button> */}
                <Button onClick={openCreateModal}>룸 세팅</Button>
            </div>
            </Title2>
            <br/><br/>
            <Title><span>계정별 Map Setting 등록</span></Title>
            <br/>
            <Table 
                columns={columns} dataSource={companyMapData}
             rowKey={(render)=> render.companyuid} tableLayout={"auto"}
             onChange={onChange} />
            {isCreateModal && 
            <MapListAddModal 
                closeCreateModal={closeCreateModal} 
                changeReload={changeReload}
                // changeMapItem={changeMapItem}
            />}
            {isEditModal && <MapSettingEditModal 
                closeEditModal={closeEditModal} 
                changeReload={changeReload} 
                selectedCompanyMapList={selectedCompanyMapList}
            />}
             {/*
            {isBundleModal && <BundleUploadModal
            closeBundleModal={closeBundleModal} 
            />} */}
        </div>
    )
}