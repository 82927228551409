import axios from 'axios';

export async function getModelDataListBYhyundai() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/hcar/bundle/list`);
}
export async function uploadModelDataBYhyundai(dataToSubmit: any, useruid :any, name :any, config :any)  {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/hcar/bundle/new?useruid=${useruid}&name=${name}`, dataToSubmit, config);
}
export async function deleteModelDataListBYhyundai(bid:any[]) {
    return await axios.delete(`${process.env.REACT_APP_SERVER_BASE_URL}/api/hcar/bundle?bid=${bid}`);
}


