import React, { useEffect, useState } from 'react'
import { infoMessage } from "../../utils/utils";

const Selectbox = (LicenseType:any) => {
    // let {LicenseType, Days, setDays, LicenseStartdate} = props;
    const [selectIndex, setSelectIndex] = useState(0)

    const onClickDays = (() => {
        if(LicenseType.LicenseStartdate === ""){
            infoMessage("달력에서 시작일을 먼저 선택해 주세요.")
            LicenseType.setDays("default")
        }
    })

    const onDaysChange = (e:any) => {
        const {value} = e.target 
        LicenseType.setDays(value)
    }
    
    useEffect(()=>{
        if(LicenseType.LicenseType === 1){//정식일때 인덱스1 옵션 출력
            setSelectIndex(1);
        }else if(LicenseType.LicenseType === 0 || LicenseType.LicenseType === "default"){
            setSelectIndex(0)
        }
    }, [LicenseType])
    
    const periodOptions = [
        [
            {value: "default", label:'기간 선택'},
            {value: "7_d", label:'7일'},
            {value: "1_m", label:'1달'},
            {value: "2_m", label:'2달'},
            {value: "3_m", label:'3달'}
        ],
        [
            {value: "default", label:'기간 선택'},
            {value: "1_y", label:'1년'},
            {value: "2_y", label:'2년'},
            {value: "3_y", label:'3년'}
        ]
    ]

    return (
        <select 
            value={LicenseType.Days}
            onChange={onDaysChange}
            onClick={onClickDays}
        >
            {
                periodOptions[selectIndex].map((period, idx) => (
                    <option key={idx} value={period.value}>{period.label}</option>
                ))
            }
        </select>
    )
}

export default Selectbox
