import React, { useEffect, useState } from 'react';
import './Inquiry.css';
import MXSPACEwebFooter from '../Footer';
import MXSPACEwebNav from '../Nav';
import { isEmail } from '../../../constants/func';
import { Button, Checkbox, Input, Modal } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import inquiryImg from './images/07_contact_banner.png';
import NewFooter from 'components/newMain/NewFooter';
import NewNav from 'components/newMain/Navbar/Nav';
import { sendMail } from '_actions/MailAction';
import CODE from '../../../constants/code';
import { PageLoader } from 'components/PageLoader';

const { TextArea } = Input;

const Inquiry = () => {
  const [loading, setLoading] = useState(false);
  const [mailModalvisible, setMailModalvisible] = useState(false);
  const [mailModalText, setMailModalText] = useState('');
  const [visible, setvisible] = useState(false);
  const [type, settype] = useState('');
  const [inquirydata, setInquirydata] = useState({
    category: 'price',
    company: '',
    department: '',
    username: '',
    position: '',
    email: '',
    contactNumber: '',
    title: '',
    contents: '',
    agree: false,
  });
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  const onCheckFunc = (e: CheckboxChangeEvent) => {
    setInquirydata({ ...inquirydata, category: e.target.value });
  };
  const onChangeInput = (event: {
    currentTarget: { value: React.SetStateAction<string> };
    target: { name: React.SetStateAction<string> };
  }) => {
    let targetValue: string = event.currentTarget.value.toString();
    let targetName: string = event.target.name.toString();
    setInquirydata({ ...inquirydata, [targetName]: targetValue });
  };
  const setAgree = (event: any) => {
    if (!inquirydata.agree) {
      setvisible(true);
      settype('privacyRule');
    } else {
      setInquirydata({ ...inquirydata, agree: !!event.currentTarget.value });
    }
  };
  var categoryList = [
    { label: '가격 문의', value: 'price' },
    { label: '업무 협의', value: 'business' },
    { label: '제휴/마케팅', value: 'partnership' },
    { label: '기타', value: 'others' },
  ];
  const sendInquiryFunc = () => {
    //유효성 체크 필요 리펙토링 필요
    if (
      inquirydata.company === '' ||
      // inquirydata.department === '' ||
      inquirydata.username === '' ||
      // inquirydata.position === '' ||
      inquirydata.email === '' ||
      // || isEmail(inquirydata.email)
      inquirydata.contactNumber === '' ||
      inquirydata.title === '' ||
      inquirydata.contents === '' ||
      inquirydata.agree === false
    ) {
      setvisible(true);
      settype('register');
    } else {
      Mailto(inquirydata);
    }
  };
  const Mailto = (mailInfo: any) => {
    const {
      company,
      department,
      username,
      position,
      email,
      contactNumber,
      title,
      contents,
      agree,
      category,
    } = mailInfo;

    if (!agree) {
      return false;
    }

    const sendMailData = {
      category:
        category === 'price'
          ? '가격 문의'
          : category === 'business'
          ? '업무 협의'
          : category === 'partnership'
          ? '제휴/마케팅'
          : '기타',
      subject: title,
      companyName: company,
      email,
      department,
      name: username,
      phone: contactNumber,
      position,
      content: contents,
    };

    setLoading(true);

    sendMail(sendMailData).then((response) => {
      setLoading(false);
      if (Number(response.data.resultCode) === Number(CODE.RCV_SUCCESS)) {
        setMailModalvisible(true);
        setMailModalText('문의가 등록 되었습니다.');
      } else {
        setMailModalvisible(true);
        setMailModalText('전송이 실패되었습니다. 네트워크 상황을 확인 후 다시 시도해주세요.');
      }
    });
  };

  const openPrivacyRule = () => {
    setvisible(true);
    settype('privacyRule');
  };

  const setPrivacyRule = () => {
    setvisible(false);
    setInquirydata({ ...inquirydata, agree: true });
  };

  const goodsTabs = ['ABOUT US', 'FUNCTION', 'CONTACT US'];

  window.onbeforeunload = function () {
    return alert('');
  };

  return (
    <>
      {loading && <PageLoader maskCapacity={0.4} />}
      <SendMailModal
        visible={mailModalvisible}
        mailModalText={mailModalText}
        modalClose={() => {
          setMailModalvisible(false);
          setMailModalText('');
        }}
      />
      <NewNav anotherPage={true} goodsTabs={goodsTabs} styleType={'scroll'} />
      <div className="InquiryWrapper" style={{ minHeight: `calc(90vh)` }}>
        <ModalComponent
          visible={visible}
          type={type}
          setPrivacyRule={setPrivacyRule}
          modalClose={() => setvisible(false)}
        />

        <div>
          <div className="InquiryHeader">
            <div className="InquiryHeaderText">문의 하기</div>
          </div>
          <div className="InquiryContainer">
            <div className="InquiryInputWrapper">
              <div className="InquiryInputHeader InquiryInputWrapperL">
                문의 유형<span className="starMark">*</span>
              </div>
              <div className="InquiryInputContainer InquiryCheckboxList">
                {categoryList.map((item) => {
                  return (
                    <div className="col-sm-12 px-3 py-2 InquiryCheckboxItem" key={item.label}>
                      <Checkbox
                        key={item.label}
                        onChange={onCheckFunc}
                        checked={item.value === inquirydata.category}
                        value={item.value}
                      >
                        {item.label}
                      </Checkbox>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="InquiryInputWrapper InquiryInputWrapperL InquiryInputWrapperB">
              <div className="InquiryInputHeader">
                고객 정보<span className="starMark">*</span>
              </div>
              <div className="InquiryInputContainer InquiryInputList">
                <Input name="company" placeholder={'회사명'} onChange={onChangeInput} />
                <Input name="department" placeholder={'부서명'} onChange={onChangeInput} />
                <Input name="username" placeholder={'고객명'} onChange={onChangeInput} />
                <Input name="position" placeholder={'직책'} onChange={onChangeInput} />
                <Input
                  name="email"
                  placeholder={'이메일'}
                  status={inquirydata.email !== '' && !isEmail(inquirydata.email) ? 'error' : ''}
                  onChange={onChangeInput}
                />
                <Input name="contactNumber" placeholder={'전화번호'} onChange={onChangeInput} />
              </div>
            </div>
            <div className="InquiryInputWrapper">
              <div className="InquiryInputHeader">
                문의 제목<span className="starMark">*</span>
              </div>
              <div className="InquiryInputContainer">
                <Input name="title" placeholder={'제목'} onChange={onChangeInput} />
              </div>
            </div>
            <div className="InquiryInputWrapper InquiryInputWrapperL">
              <div className="InquiryInputHeader">
                문의 내용<span className="starMark">*</span>
              </div>
              <div className="InquiryInputContainer">
                <TextArea
                  name="contents"
                  placeholder={'상세내용을 입력해주세요'}
                  onChange={onChangeInput}
                />
              </div>
            </div>
            <div className="InquiryInputWrapper InquiryPrivacyWrapper">
              <div className="InquiryInputHeader InquiryInputHeaderEmpty">&nbsp;</div>
              <div className="InquiryInputContainer" style={{ position: 'relative' }}>
                <span className="checkBoxNotouch" onClick={setAgree}></span>
                <Checkbox name="agree" checked={inquirydata.agree}></Checkbox>
                <span className="checkboxLabel">
                  <span className="privacyRule" onClick={openPrivacyRule}>
                    개인정보보호정책
                  </span>
                  에 동의합니다.
                </span>
              </div>
            </div>
          </div>
          <div className="InquiryInputWrapper InquiryBtnWrapper">
            <Button type="primary" onClick={sendInquiryFunc}>
              문의하기
            </Button>
          </div>
        </div>
      </div>
      <NewFooter showInquiryBtn={false} />
      {/* <MXSPACEwebFooter showInquiryBtn = {false}/> */}
    </>
  );
};

export default Inquiry;

const ModalComponent = (props: any) => {
  let { visible, type, setPrivacyRule, modalClose } = props;
  return (
    <div className="privacyModalWrapper">
      {type === 'privacyRule' ? (
        <Modal
          open={visible}
          // closable={false}
          onCancel={modalClose}
          width={900}
          bodyStyle={{ padding: '2vw' }}
          footer={[
            <Button type="primary" key="1" onClick={setPrivacyRule}>
              동의
            </Button>,
          ]}
        >
          <div className="privacyWrapper">
            <div className="mainheader">개인 정보 보호 정책</div>
            <br />
            <div className="header">&#183; 개인정보 수집이용에 대한 동의</div>
            <div className="body">
              작성자의 소중한 개인정보는 다음과 같은 정책에 따라 수집 및 이용됩니다. 저희 주식회사
              옐로테일에서는 해당 목적에 연관되는 개인정보만을 수집하며, 수집된 정보를 투명하고
              안전하게 보호 관리할 것을 약속합니다. 이에 개인정보 수집및 이용에 대한 동의를
              구합니다.
            </div>
            <br />
            <div className="header">&#183; 개인정보의 수집·이용 목적</div>
            <div className="body">
              문의글 작성자에 대한 견적, 기간, 개발방법 등의 문의에 대한 정보가 보다 정확한 답변을
              위해 수집됩니다.
            </div>
            <br />
            <div className="header">&#183; 수집항목</div>
            <div className="body">
              필수항목: 성명, 이메일, 연락처, 문의 내용, 회사명 등 작성 내용
            </div>
            <br />
            <div className="header">&#183; 보유이용기간</div>
            <div className="body">
              원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이
              파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
            </div>
            <br />
            <div className="body">
              보존 이유 : 작성자의 동의를 통한 정보 유지
              <br />
              보존 기간 : 작성자 정보 삭제 요청시까지
            </div>
          </div>
        </Modal>
      ) : type === 'register' ? (
        <Modal
          open={visible}
          closable={false}
          style={{ top: '38%' }}
          footer={[
            <Button type="primary" key="1" onClick={modalClose}>
              확인
            </Button>,
          ]}
        >
          <div style={{ textAlign: 'center' }}>
            기본정보를 모두 기입하고,
            <br />
            개인정보보호정책에 동의해주세요.
          </div>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

const SendMailModal = (props: any) => {
  let { visible, modalClose, mailModalText } = props;

  return (
    <>
      {visible && (
        <>
          <div className="sendMailModalMask"></div>
          <div className="sendMailModalWrap">
            <div className="sendMailModal">
              <div className="sendMailModalContent">
                <p>{mailModalText}</p>
                <div className="sendMailModalBtn" onClick={modalClose}>
                  확인
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
