import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Button, Input, Space, Table } from 'antd';
import type { ColumnsType, ColumnType, TableProps } from 'antd/lib/table';
import type { FilterConfirmProps } from 'antd/lib/table/interface';
import React, { useRef, useState, useEffect } from 'react';
import type { TableRowSelection } from 'antd/lib/table/interface';
import { alertMessage, infoMessage } from "../../utils/utils";
import { getUserList, block, clearBlock, deleteUser,saCountAccount } from '../../../_actions/AccountAction';

const ManageAccountUser: React.FC = () => {
    type DataIndex = keyof DataType;

    let currentUrl = window.location.href;
    let urlStr = currentUrl.split('/');
    let companyuid = urlStr[6];

    
    interface DataType {
        key: React.Key;
        user_index : string;
        license_type : string;
        user_email : string;
        state: string;
        chatting_hour : string;
        screen_hour : string;
        access_time : string;
        company_name: string;
    }

    const searchInput = useRef<InputRef>(null);

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={String(selectedKeys[0])}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            confirm({ closeDropdown: false });
                        }}
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });
    const [data, setData] = useState();
    const [companyName, setCompanyName] = useState();
    const [maxUser, setMaxUser] = useState();
    const [accountInUse, setAccountInUse] = useState();
    const [remainingAccount, setRemainingAccount] = useState();
    const [checkState, setCheckState] = useState(false);
    // const [state, setState] = useState();

    useEffect(() => {
        getUserList(companyuid)
        .then(response => {
            setData(response.data);   
            setCompanyName(response.data[0].company_name);        
        }).catch((error)=>{
            alertMessage(error.response.data);
        });
        saCountAccount(companyuid).then((res)=>{
            setMaxUser(res.data[0]);
            setAccountInUse(res.data[1]);
            setRemainingAccount(res.data[2]);
        });
    }, [companyuid, checkState]);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<DataType> = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
            {
                key: 'odd',
                text: 'Select Odd Row',
                onSelect: changableRowKeys => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changableRowKeys.filter((_, index) => {
                        if (index % 2 !== 0) {
                            return false;
                        }
                        return true;
                    });
                    setSelectedRowKeys(newSelectedRowKeys);
                },
            },
            {
                key: 'even',
                text: 'Select Even Row',
                onSelect: changableRowKeys => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changableRowKeys.filter((_, index) => {
                        if (index % 2 !== 0) {
                            return true;
                        }
                        return false;
                    });
                    setSelectedRowKeys(newSelectedRowKeys);
                },
            },
        ],
    };
    // function logoutList(){
    //     if(selectedRowKeys.length === 0){
    //         alert("접속 해제할 계정을 선택해주세요.");
    //     }else{
    //         let result = window.confirm(selectedRowKeys.length + "개의 계정을 접속 해제 하시겠습니까?")
    //         if(result){
    //             logout(selectedRowKeys)
    //             .then((response) => {
    //                 if(response.data === 1){
    //                     alert("선택 계정 접속 해제 성공");
    //                     setCheckState(!checkState);
    //                     setSelectedRowKeys([]);
    //                 }else{
    //                     alert("접속 해제 처리에 실패하였습니다.")
    //                 }
    //             }).catch((error)=>{
    //                 alertMessage(error.response.data);
    //             })  
    //         }
    //     }
    // }
    function blockList(){
        if(selectedRowKeys.length === 0){
            alert("블록 처리할 계정을 선택해 주세요.");
        }else{
            let result = window.confirm(selectedRowKeys.length + "개의 계정을 블록 처리 하시겠습니까?")
            if(result){
                block(selectedRowKeys, companyuid)
                .then((response) => {
                    if(response.data.result === 1){
                        // alert("선택 계정이 블록 처리되었습니다. 다음 접속 시 적용됩니다.");
                        infoMessage(response.data.resultMessage)
                        setCheckState(!checkState);
                        setSelectedRowKeys([]);
                    }else{
                        // alert("블록 처리에 실패하였습니다.")
                        alertMessage(response.data.resultMessage);
                    }
                })
                // .catch((error)=>{
                //     alertMessage(error.response.data);
                // })  
            }
        }
    }

    function clearBlockUser(){
        if(selectedRowKeys.length === 0){
            alert("블록 해제 처리할 계정을 선택해 주세요.");
        }else{
            let result = window.confirm(selectedRowKeys.length + "개의 계정을 블록 해제 처리 하시겠습니까?")
            if(result){
                clearBlock(selectedRowKeys, companyuid)
                .then((response) => {
                    if(response.data.result === 1){
                        // alert("선택 계정이 블록 해제되었습니다.");
                        infoMessage(response.data.resultMessage)
                        setCheckState(!checkState);
                        setSelectedRowKeys([]);
                    }else{
                        alertMessage(response.data.resultMessage);
                    }
                })
                // .catch((error)=>{
                //     alertMessage(error.response.data);
                // })  
            }
        }
    }
    function deleteUserList(){
        if(selectedRowKeys.length === 0){
            alert("삭제할 계정을 선택해 주세요.");
        }else{
            let result = window.confirm(selectedRowKeys.length + "개의 계정을 삭제 하시겠습니까?")
            if(result){
                deleteUser(selectedRowKeys, companyuid)
                .then((response) => {
                    if(response.data.result === 1){
                        // alert("선택 계정 삭제 성공");
                        infoMessage(response.data.resultMessage)
                        setCheckState(!checkState);
                        setSelectedRowKeys([]);
                    }else{
                        alertMessage(response.data.resultMessage);
                    }
                })
                // .catch((error)=>{
                //     alertMessage(error.response.data);
                // })  
            }
        }
    }

    const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
        // console.log('params', pagination, filters, sorter, extra);
    };

    const columns: ColumnsType<DataType> = [
        {
            title: 'No',
            dataIndex: 'user_index',
            key: 'user_index',
            align: 'center' as 'center',
        },
        {
            title: 'Type',
            key: 'license_type',
            dataIndex: 'license_type',
            align: 'center' as 'center',
        },
        // {
        //     title: '계정 등급',
        //     key: 'user_level',
        //     dataIndex: 'user_level',
        //     align: 'center' as 'center',
        // },
        {
            title: 'E-mail',
            dataIndex: 'user_email',
            key: 'user_email',
            align: 'center' as 'center',
            ...getColumnSearchProps('user_email'),
        },
        {
            title: 'State',
            key: 'state',
            dataIndex: 'state',
            filters: [
                { text: <span>접속중</span>, value: '접속중' },
                { text: <span>블록중</span>, value: '블록중' },
                { text: <span>로그아웃</span>, value: '로그아웃' },
            ],
            onFilter: (value: any, record) => record.state.startsWith(value),
            filterSearch: true,
            align: 'center' as 'center',
        },
        {
            title: '화상채팅 시간',
            dataIndex: 'chatting_hour',
            key: 'chatting_hour',
            align: 'center' as 'center',
        },
        {
            title: '화면송출 시간',
            dataIndex: 'screen_hour',
            key: 'screen_hour',
            align: 'center' as 'center',
        },
        {
            title: '기간별 누적 접속시간',
            dataIndex: 'access_time',
            key: 'access_time',
            align: 'center' as 'center',
        },
    ];

    return (
        <div>
            <h3 style={{marginLeft: "2%", borderBottom:'2px solid red', width:'30%'}}>계정관리 &gt; 개별 계정 정보</h3>
            <div style={{display: "flex", justifyContent: 'space-between', width:'25%', marginLeft:'2%'}}>
                <div style={{ marginBottom: '2%' }}>{companyName}</div>
                <div>
                    <div>발급 계정 수 : &nbsp;&nbsp;{maxUser} </div>
                    <div>사용 계정 수 : &nbsp;&nbsp;{accountInUse} </div>
                    <div>잔여 계정 수 : &nbsp;&nbsp;{remainingAccount} </div>
                </div>
            </div>
            <br></br>
            <div style={{display: "flex", justifyContent: 'space-between'}}>
                <div>
                    조회기간
                    <input type="date" name="" id="" />&nbsp;&nbsp;
                    <input type="date" name="" id="" />
                </div>
                <div>
                    {/* <button onClick={logoutList}>접속 해제</button>&nbsp; */}
                    <button onClick={blockList}>계정 블록</button>&nbsp;
                    <button onClick={clearBlockUser}>블록 해제</button>&nbsp;
                    <button onClick={deleteUserList}>계정 삭제</button>
                </div>
            </div>
            <br></br>
            <Table rowSelection={rowSelection} 
                    columns={columns} 
                    dataSource={data} 
                    onChange={onChange} 
                    rowKey={(render)=> render.user_index}/>
        </div>
    );
};

export default ManageAccountUser;