import { NoticeDetail } from './NoticeDetail';

import { NoticeDetailType } from 'types/notice';

import { StyledHeaderTitle } from './styles';

export const CreateNotice = () => {
    const handleCreate = (e: NoticeDetailType) => {
        console.log('create api 연동 시작', e);
    };

    return (
        <div style={{ marginLeft: '2%' }}>
            <StyledHeaderTitle>공지 등록</StyledHeaderTitle>
            <NoticeDetail type="create" handleData={(e) => handleCreate(e)} />
        </div>
    );
};
