import { AdminData, ADMIN_LOGIN, ADMIN_LOGOUT } from "../_actions/types";

export const initialAdminDataState : AdminData = {
    loginStatus : false,
    adminData : {
        uid: -1,
        adminid: "",
        grade : -1
    }
};

export default function adminReducer (state = initialAdminDataState, action: { type: any; payload: any; }) {
    switch (action.type) {
        case ADMIN_LOGIN:
            return { ...state, ...action.payload } // spread operator
        case ADMIN_LOGOUT:
            return { ...initialAdminDataState }
        default:{
            return state;
        }
    }
}