import { useState } from 'react'
import "./ProfileUploadWebview.css";
import { uploadProfileImage, deleteProfileImage } from '../../../_actions/ProfileAction';

export interface userObject{
    user_uuid: string
}
const initUser = {"user_uuid":""}
//const initUser = {"user_uuid":"164"}
export default function ProfileUploadWebview() {
    const [userData, setUserData] = useState<userObject>(initUser);

    window.vuplex && addMessageListener();
    function addMessageListener() {
        window.vuplex.addEventListener('message', function(event: { data: any; }) {
            let json:userObject = JSON.parse(event.data);
            setUserData({user_uuid : `${json.user_uuid}`});
        });
    }

    function openFileInput(){
        const profileInput:any = document.getElementById('profileInput');
        profileInput.click();
    }

    function closeWindow(e:any) {
        var customWindow:any = window.open('', '_parent', '');
        customWindow.close();
    }

    function handleProfileUpload(e: any){
        e.preventDefault();
        const file = e.target.files[0];
        const formData:any = new FormData(); 
        formData.append("file", file);
        uploadProfileImage(formData, userData.user_uuid).then(res => {
            if(window.vuplex) window.vuplex.postMessage(res.data);
            closeWindow(e);
        }).catch((error) => {
            if(window.vuplex) window.vuplex.postMessage(error.response.data);
            closeWindow(e);
        });
    }

    function handleProfileReset(e:any){
        deleteProfileImage(userData.user_uuid).then(res => {
            console.log(res)
            console.log(res.data.resultCode)
            if(window.vuplex) window.vuplex.postMessage(res.data.resultCode);
            closeWindow(e);
        }).catch((error) => {
            if(window.vuplex) window.vuplex.postMessage(error.response.data);
            closeWindow(e);
        });
    }

    return ( // 창크기 186x88
        <div className="profileBtnBody"> 
            <input type="file" name="profileInput" id="profileInput" hidden
                onChange={e => {handleProfileUpload(e);}} accept="image/png, image/jpeg"/>
            <div className="profileBtn" onClick={openFileInput}>
                사진변경
            </div>
            <div className="profileBtn" onClick={handleProfileReset}>
                기본 이미지로 변경
            </div>
        </div>
    )
}