import { useEffect, useState } from 'react';
import { Modal, Input, Button, Upload, Select} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import styled from "styled-components";
import { alertMessage, infoMessage } from "../../utils/utils";
import { checkMapname, getMapIndexValidList, uploadMapImage, addMap } from '../../../_actions/MapAction'
import CODE from '../../../constants/code';
import { MAPTHUMBNAIL_UPLOADPATH_ANDROID, MAPTHUMBNAIL_UPLOADPATH_WINDOWS } from '../../../constants/common';
import { FILETYPE } from '../../../constants/fileType';
const { Option } = Select;

const ModalBodyLayout = styled.div`
    display: grid; 
    grid-template-columns: 25% auto; 
    grid-template-rows: 50px 200px 120px 70px;
    align-items: center;
`;

const FlexCenterLayout = styled.div`
    display: flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items: flex-start;
    form{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
`;  
export interface fileObject {
    length: number;
    map(arg0: (item: any) => JSX.Element): import("react").ReactNode;
    fileUuid: "", fileOwner: "", fileName: "", fileExtern: "", contentsType: "", fileHash: "", fileSize: ""
}
export default function MapListAddModal(props:any) {
    
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [map_Name, setMap_Name] = useState('');
    const [nameCheck, setNameCheck] = useState(2); // 체크 필요 2, 중복아님 1, 중복임 0
    const [thumbnailURL_aos, setThumbnailURL_aos] = useState('');
    const [thumbnailURL_win, setThumbnailURL_win] = useState('');
    const [mapFileIndex, setmapFileIndex] = useState("");
    const [mapidList, setMapidList] =  useState<string[]>([]);
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = () => {
        if(nameCheck === 2) return alertMessage('MAP 이름 중복 확인이 필요합니다.')
        if(nameCheck === 0) return alertMessage('MAP 이름 중복입니다.')
        if(thumbnailURL_aos === "" || thumbnailURL_win==="") return alertMessage('썸네일 등록이 필요합니다.')
        if(mapFileIndex ==='') return alertMessage('MAP index 등록이 필요합니다.')
        const dataToSubmit = {
            "mapid": mapFileIndex,
            "mapname" : map_Name,
            "thumbnail" : thumbnailURL_win,
            "thumbnail_p" : thumbnailURL_aos
        }
        addMap(dataToSubmit).then((res)=>{
            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                infoMessage('등록 성공')
                props.changeReload()
                handleCancel()
            } else {
                alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
            }
        })
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        props.closeCreateModal();
       
    };

    const onMapNameHandler = (event: { currentTarget: { value: string; }; }) => {
        setNameCheck(2)
        setMap_Name(event.currentTarget.value)
    }
    const getMapIndexArr = () => {
        getMapIndexValidList()
        .then((res)=>{
            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                setMapidList(res.data.mapIndexlist)
            } else {
                alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
            }
        }).catch((error)=>{
            console.error(error)
        });
    }
    useEffect(() => {
        getMapIndexArr()
        showModal();
    }, [])
    const checkMapName = () => {
        const dataToSubmit = {
            mapname : map_Name
        }
        if(map_Name ==='') return alertMessage('MAP 이름을 입력하세요.')
        checkMapname(dataToSubmit)
        .then((res)=>{
            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                setNameCheck(res.data.result ? 1 : 0)
            }
        })
    }

    const [fileListAos, setFileListAos] = useState(null);
    const [fileListWin, setFileListWin] = useState(null);
    const handleFileUpload = (e: any, type : string) => {
        e.preventDefault();
        var file = e.target.files[0];
        var imageType = /image.*/;
        if (file && !file.type.match(imageType)) return;
        if(type ==='aos') setFileListAos(file)   
        if(type ==='win') setFileListWin(file)        
    }
    const uploadAos = (e:any, file:any) => {
        e.preventDefault();
        let formData:any = new FormData(); 
        formData.append("thumbnail", file);
        uploadMapImage(FILETYPE["map_thumbnail_Group"][0], MAPTHUMBNAIL_UPLOADPATH_ANDROID, formData)
        .then((res) => {
            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                setThumbnailURL_aos(`${res.data.returnPath}`)
            } else {
                alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
            }
        })
    }
    const uploadWin = (e:any, file:any) => {
        e.preventDefault();
        let formData:any = new FormData(); 
        formData.append("thumbnail", file);
        uploadMapImage(FILETYPE["map_thumbnail_Group"][0], MAPTHUMBNAIL_UPLOADPATH_WINDOWS, formData)
        .then((res) => {
            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                setThumbnailURL_win(`${res.data.returnPath}`)
            } else {
                alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
            }
        })
    }
    function openFileInput2(type:string) {
        if(type === 'aos'){
            const fileInput:any = document.getElementById("aosBtn");
            fileInput!.click();
        } else if (type ==='win'){
            const fileInput:any = document.getElementById("winBtn");
            fileInput!.click();
        }
    }
   const MapItemToFileInfo = (type:string) => {
    let env = `${process.env.REACT_APP_SERVER_BASE_URL}`
    let fileList :UploadFile<any>[] = type==='none' ? 
    [{
       uid: `-1`,
       name: `${map_Name}`,
       status: 'error'        }]
    : [{
       uid: `-1`,
       name: `${map_Name}`,
       status: type==='none' ? 'error' : 'done',
       url : `${type==='aos' ? env+'/'+thumbnailURL_aos : type==='win' ? env+'/'+thumbnailURL_win : ''}`
   }]
       return fileList
}
    return (
        <>
            <Modal title="기본 룸 Setting" open={isModalVisible} onOk={handleOk}
             onCancel={handleCancel}
                footer={[
                    <Button key="ok" onClick={handleOk}>
                        OK
                    </Button>
                ]}>
                <ModalBodyLayout>
                    <div>MAP 이름:</div>
                    <div>
                        <Input onChange={onMapNameHandler} style={{width: "73%"}}/>
                        <Button style={{marginLeft: 10}} onClick={checkMapName}>중복확인</Button>
                        {nameCheck===1 ?
                        <div style={{color: "#2196f3", fontWeight: 200}}>사용 가능한 이름입니다.</div>
                    : nameCheck===0 ?
                    <div style={{color: "#ff0000", fontWeight: 200}}>이미 사용중인 MAP 이름입니다.</div> : <></>}
                    </div>
                    <div>MAP 썸네일 :<br/>(AOS)</div>
                    <FlexCenterLayout>
                        <Upload    
                            listType="picture-card"
                            fileList={thumbnailURL_aos ==='' || thumbnailURL_aos === undefined ?  MapItemToFileInfo('none') : MapItemToFileInfo('aos')}
                            showUploadList={{showRemoveIcon : false, showPreviewIcon : false}}
                            >
                        </Upload>
                            <form id="formElem" encType="multipart/form-data" onSubmit={(e)=>uploadAos(e, fileListAos)}>
                                <input type="file" name="mapUploadAos" id="mapUploadAos"
                                    onChange={e => {handleFileUpload(e, 'aos');}}
                                    />
                                <input type='submit' id='aosBtn' hidden/>
                                <div style={{width:'15%'}}>
                                    <Button icon={<UploadOutlined />} onClick={()=>openFileInput2('aos')}></Button>
                                </div>
                            </form>
                        
                    </FlexCenterLayout>
                    <div>MAP 썸네일 :<br/>(Windows)</div>
                    <FlexCenterLayout>
                    <Upload    
                            listType="picture-card"
                            fileList={thumbnailURL_win ==='' || thumbnailURL_win === undefined ? MapItemToFileInfo('none') : MapItemToFileInfo('win')}
                            showUploadList={{showRemoveIcon : false, showPreviewIcon : false}}
                            >
                        </Upload>
                            <form id="formElem" encType="multipart/form-data" onSubmit={(e)=>uploadWin(e, fileListWin)}>
                                <input type="file" name="mapUploadWin" id="mapUploadWin"
                                    onChange={e => {handleFileUpload(e, 'win');}} />
                                <input type='submit' id='winBtn' hidden/>
                                <div style={{width:'15%'}}>
                                    <Button icon={<UploadOutlined />}onClick={()=>openFileInput2('win')}></Button>
                                </div>
                            </form>
                       
                    </FlexCenterLayout>
                    <div>MAP 인덱스:</div>
                    <FlexCenterLayout>
                        <div style={{width: "75%"}}>
                            <Select style={{ width: '100%'}} placeholder="maps Index(win+aos)" onChange={(value)=>setmapFileIndex(value)}>
                            {mapidList.length>0 && mapidList.map((item:any)=>{
                            return (
                            <Option key={item} value={item}>
                                {item}
                            </Option>
                            )
                            })}
                            </Select>
                        </div>
                    </FlexCenterLayout>
                </ModalBodyLayout>
            </Modal>
        </>
    );
};