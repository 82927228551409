import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Table, Input, Modal } from 'antd'
import { UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import { alertMessage, confirmMessage } from '../../utils/utils';
import { getLobbyList, editLobby, deleteLobby } from '../../../_actions/LobbyAction'
import { DataType } from './LobbySetting'
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import CODE from '../../../constants/code';

const SubTitle = styled.div`
    width: 100%;
    padding-bottom: 2px;
    font-weight: 700;
    font-size: 1rem;
    display:flex;
    justify-content: space-between;
    div{
        display:flex;
        justify-content: flex-start;
        align-items: center;
    }
`;
const HoverIcon = styled.button`
    display:flex;
    align-items: center;
    border: 1px solid rgba(100,100,100,.25);
    border-radius: 5px;
    background: rgba(100,100,100,.1);
    &:hover {
        color: ${props=>props.disabled? 'gray': '#1890ff;' };
    }
    color: ${props=>props.disabled? 'gray': 'rgba(0,0,0,.85);' };
`;

const { Column } = Table;
const LobbyData = (props:any) => {
    const [lobbyList, setlobbyList] = useState<DataType[]>([])
    // const [deletedLobbyList, setDeletedLobbyList] = useState<DataType[]>([])
    const [menuToggle2, setMenuToggle2] = useState(true);
    const [changedLobbyNameIndex, setChangedLobbyNameIndex] = useState(-1)
    const [isDone, setIsDone] = useState(false);

    useEffect(()=>{
        getLobbyList()
        .then((res)=>{
            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                setlobbyList(res.data.lobbylist)
                props.setLobbyList(res.data.lobbylist)
            } else {
                alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
            }
        })
        .catch((err)=>{
            console.error(err)
        })
    }, [props.doneAddLobby, isDone])

    const deleteLobbyData = (data:any) => {
        confirmMessage("선택한 Lobby을\n삭제하시겠습니까?", "primary", ()=>{
            deleteLobby(data)
            .then((res)=>{
                if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                    Modal.info({
                        content: res.data.resultMessage,
                    })
                    setIsDone(!isDone);
                } else {
                    alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
                }

                
            })
        });
    }

    const editLobbyName = (row:any) => {
        editLobby(row)
        .then((res)=>{
            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                Modal.info({
                    content: res.data.resultMessage,
                })
                setIsDone(!isDone);
            } else {
                alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
            }
        })
        setChangedLobbyNameIndex(-1);
    }

    const onChangeFunction = (
        e: React.ChangeEvent<HTMLInputElement>,
        index:number,
        row:any
    ) => {
        let textValue = e.target.value;
        let temp:DataType[] = lobbyList.slice();
        temp[index] = {idx : row.idx, lobbyid: row.lobbyid, lobbyname: textValue};
        setChangedLobbyNameIndex(row.lobbyid);
        setlobbyList(temp)
    }

    return (
        <div>
            <SubTitle>
                <div>
                    <div>Lobby 리스트</div>&nbsp;&nbsp;
                    {menuToggle2 ? <UpCircleOutlined onClick={()=>setMenuToggle2(false)} />
                    : <DownCircleOutlined onClick={()=>setMenuToggle2(true)} />}
                </div>
            </SubTitle>
            <br/>
            <Table dataSource={lobbyList} rowKey={(render)=> render.lobbyid}
            className={`${menuToggle2 ?'lobbyMenuShow':"lobbyMenuHide"}`}>
                <Column
                    title= 'No.'
                    key= 'lobbyid'
                    dataIndex= 'lobbyid'
                    align= 'center'
                    render= {(text:any, row:any, index:any) => {
                        return(<div>{index+1}</div>)
                    }}
                    width="5%"
                />
                <Column
                    title= 'Lobby 이름'
                    key= 'lobbyname'
                    dataIndex= 'lobbyname'
                    align= 'center'
                    render= {(text:any, row:any, index:any) => {
                        return(<div style={{display:"flex"}} >
                            <Input value={text} style={{marginRight: "10px"}} 
                                onChange={(e:any) => onChangeFunction(e, index, row)}
                            />
                            <HoverIcon
                                onClick={() => editLobbyName(row)} 
                                disabled={changedLobbyNameIndex != row.lobbyid}
                            >
                                <SaveIcon />
                            </HoverIcon>
                        </div>
                        )
                    }}
                    width="50%"
                />
                <Column
                    title= 'Lobby 번들 파일'
                    key= 'lobbyid'
                    dataIndex= 'lobbyid'
                    align= 'center'
                    render= {(text:any, row:any, index:any) => {return(<>{text}</>)}}
                />
                <Column
                    title='삭제'
                    dataIndex='siteVideo'
                    key='siteVideo'
                    align='center'
                    render={(text: any, row: any, index: any) => {
                        return (
                            <div style={{display:"flex", justifyContent: "center"}}>
                                <HoverIcon onClick={() => deleteLobbyData(row)}>
                                    <DeleteIcon />
                                </HoverIcon>
                            </div>
                        )
                    }}
                    width="10%"
                />
            </Table>
        </div>
    );
};

export default LobbyData;