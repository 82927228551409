import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import * as ROUTES from '../../constants/routes';

import NewNav from './Navbar/Nav';
import BannerSection from './BannerSection';
import AboutSection from './AboutSection';
import FunctionSection from './FunctionSection';
import PlatformSection from './PlatformSection';
import NewFooter from './NewFooter';

const NewMainPage = () => {
    const location = useLocation();
    const { state } = location;
    const navigate = useNavigate();

    const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
        if (ref.current) {
            const yOffset = window.innerWidth < 768 ? (-15.556 * window.innerWidth) / 100 : 0;
            const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    const navigateTo = (route: string) => {
        navigate(route);
    };

    const useMoveScroll = (name: string) => {
        const element = useRef<HTMLDivElement>(null);

        const onMoveToElement = () => {
            if (name === 'CONTACT US') {
                navigateTo(ROUTES.INQUIRY_PAGE);
            } else {
                scrollToRef(element);
            }
        };

        return { name, element, onMoveToElement };
    };

    const goodsTabs = [
        useMoveScroll('ABOUT US'),
        useMoveScroll('FUNCTION'),
        useMoveScroll('CONTACT US'),
    ];

    useEffect(() => {
        if (state && state.scrollToRef) {
            const targetTab = goodsTabs.find((tab) => tab.name === state.scrollToRef);
            if (targetTab) scrollToRef(targetTab.element);
        }
    }, [state]);

    return (
        <div id="mainWrapper">
            <NewNav goodsTabs={goodsTabs} />
            <BannerSection />
            <AboutSection ref={goodsTabs[0].element} />
            <FunctionSection ref={goodsTabs[1].element} />
            <PlatformSection />
            <NewFooter showInquiryBtn={true} />
        </div>
    );
};

export default NewMainPage;
