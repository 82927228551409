// base
import React, { useMemo, useState } from 'react';

// libraries
import { Table, Select, Row, Col, Space } from 'antd';
import { TableProps, ColumnsType } from 'antd/lib/table';
import { PaginationProps } from 'antd/lib/pagination';

import { DEFAULT_PAGE_SIZE } from 'hooks';

// style
import { TableWrapper } from './style';

// defines
const defaultPageSizeRange = [10, 20, 50, 100];

export interface PaginationTableProps<T> extends TableProps<T> {
    noAsync?: boolean;
    noIndex?: boolean;
    columns: ColumnsType<T>;
    customRight?: React.ReactNode;
    showPageSize?: boolean;
    onChangePageSize?: (page: number, pageSize: number) => void;
    hideHeader?: boolean;
}

interface Pagination extends PaginationProps {
    total: number;
    current: number;
    pageSize: number;
    onChange?: (page: number, pageSize: number) => void;
}

export const PaginationTable = <T extends {}>(props: PaginationTableProps<T>) => {
    const {
        noAsync = false,
        noIndex = false,
        columns,
        dataSource,
        customRight,
        showPageSize = true,
        onChangePageSize,
        hideHeader,
        ...tableOptions
    } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPagesize] = useState(defaultPageSizeRange[0]);

    const pagination = useMemo<Pagination>(() => {
        return {
            total: 0,
            current: currentPage,
            pageSize: currentPageSize,
            onChange: (page, pageSize) => {
                setCurrentPage(page);
                if (pageSize) {
                    setCurrentPagesize(pageSize);
                }
            },
            onShowSizeChange: (curr, size) => {
                setCurrentPage(curr);
                setCurrentPagesize(size);
            },
            ...tableOptions.pagination,
        };
    }, [tableOptions.pagination, currentPage, currentPageSize]);

    const handleChangePageSize = (pageSize: number) => {
        if (pagination.onChange) {
            pagination.onChange(1, pageSize);
        }
    };

    return (
        <TableWrapper>
            {!hideHeader && (
                <Row className="table-header" justify="space-between">
                    <Col>
                        {pagination.total !== null && (
                            <Space style={{ marginRight: 10 }}>
                                <div className="totalCount-txt">
                                    <span>
                                        {`총 ${pagination.total || dataSource?.length || 0} 건`}
                                    </span>
                                </div>
                            </Space>
                        )}
                        {showPageSize && (
                            <Select
                                style={{ width: '8rem' }}
                                defaultValue={DEFAULT_PAGE_SIZE}
                                value={pagination.pageSize ? pagination.pageSize : undefined}
                                onChange={handleChangePageSize}
                            >
                                {defaultPageSizeRange.map((size, i) => (
                                    <Select.Option key={`pageSize-${i}`} value={size}>
                                        {`${size}개씩 보기`}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Col>
                    {customRight && <Col>{customRight}</Col>}
                </Row>
            )}

            <Table
                {...tableOptions}
                pagination={pagination}
                columns={
                    noIndex
                        ? columns
                        : [
                              {
                                  title: 'No',
                                  dataIndex: 'index',
                                  key: 'index',
                                  align: 'center' as 'center',
                                  render: (_, record, index) => {
                                      if (!noAsync) {
                                          return (
                                              pagination.total -
                                              index -
                                              (pagination.current * pagination.pageSize -
                                                  pagination.pageSize)
                                          );
                                      } else {
                                          return pagination.total - index;
                                      }
                                  },
                              },
                              ...columns,
                          ]
                }
                dataSource={dataSource}
            />
        </TableWrapper>
    );
};
