// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            기업형_메타버스_플랫폼_서비스: 'MXSPACE',
        },
    },
    ko: {
        translation: {
            //소개 페이지
            // 상단 메인
            기업형_메타버스_플랫폼_서비스: '기업형 메타버스 플랫폼 서비스',
            // move to mxspace
            // pc
            기술_산업에_특화된_맞춤형_메타버스_서비스: '기술 산업에 특화된 맞춤형 메타버스 서비스',
            '원거리_언텍트_업무_협의와_각종_문서,_영상_공유가_가능하며':
                '원거리 언텍트 업무 협의와 각종 문서, 영상 공유가 가능하며',
            '상상했던_모든_것이_Online_MXSPACE_공간속에서_Offline의_실제_기기_제어까지_현실로_가능해집니다.':
                '상상했던 모든 것이 Online MXSPACE 공간속에서 Offline의 실제 기기 제어까지 현실로 가능해집니다.',
            // mobile, tablet
            '원거리_언텍트_업무_협의와_각종_문서,_영상_공유가':
                '원거리 언텍트 업무 협의와 각종 문서, 영상 공유가',
            가능하며_Online_MXSPACE_공간속에서: '가능하며 Online MXSPACE 공간속에서',
            'Offline의_실제_기기_제어까지_현실로_가능해집니다.':
                'Offline의 실제 기기 제어까지 현실로 가능해집니다.',
            '상상했던_모든_것이_현실로_이어집니다.': '상상했던 모든 것이 현실로 이어집니다.',
            // mxspace Functions
            // pc
            사용자_지정_환경에_맞는_다양한_제품을_공간_커스텀을_통해:
                '사용자 지정 환경에 맞는 다양한 제품을 공간 커스텀을 통해',
            '기업에_특화된_협업_시스템으로_보다_높은_업무_효율을_제공합니다.':
                '기업에 특화된 협업 시스템으로 보다 높은 업무 효율을 제공합니다.',
            // mobile, tablet
            사용자_지정_환경에_맞는_다양한_제품을: '사용자 지정 환경에 맞는 다양한 제품을',
            공간_커스텀을_통해_기업에_특화된_협업: '공간 커스텀을 통해 기업에 특화된 협업',
            '시스템으로_보다_높은_업무_효율을_제공합니다.':
                '시스템으로 보다 높은 업무 효율을 제공합니다.',
            // communication
            // 공통
            MXSPACE안에서는_다수의_사람과: 'MXSPACE안에서는 다수의 사람과',
            '다양한_형태로_커뮤니케이션이_가능합니다.': '다양한 형태로 커뮤니케이션이 가능합니다.',
            // a variety of platforms
            // 공통
            '모바일_및_PC,_다양한_플랫폼에서_지원가능합니다.':
                '모바일 및 PC, 다양한 플랫폼에서 지원가능합니다.',

            //리뉴얼 메인
            renew_banner_pc_title: '기업형 리모트 협업 XR 기반 워크스페이스 플랫폼',
            renew_banner_mb_title: '기업형 리모트 협업 XR 기반<br />워크스페이스 플랫폼',
            renew_about_pc_text:
                '자체 개발한 리모트 협업 XR 기반 워크스페이스 플랫폼을 기반으로<br />다양한 솔루션, 콘텐츠 등을 연동해 고객이 원하는 모든 서비스를 제공합니다.',
            renew_about_mb_text:
                '자체 개발한 리모트 협업 XR 기반 워크스페이스 플랫폼을<br />기반으로 다양한 솔루션, 콘텐츠 등을 연동해 고객이<br />원하는 모든 서비스를 제공합니다.',
            functionSwiperBannerTit1: '화이트보드 기능',
            functionSwiperBannerTxt1: '파일공유 메뉴를 통한<br />PDF 스크린 공유',

            functionSwiperBannerTit2: 'Webview 모듈 기능 지원',
            functionSwiperBannerTxt2: '인트라넷 및 WEB 기반<br />솔루션 연동',

            functionSwiperBannerTit3: '3D 데이터 연동',
            functionSwiperBannerTxt3: 'AR 3D 증강 데이터 공유',

            functionSwiperBannerTit4: '원격 화상회의',
            functionSwiperBannerTxt4: '개인, 그룹별 화상, 문자, 음성<br />회의 지원',
            functionPopupContentTitle4: '개인,<br />그룹별 화상,<br />문자,<br />음성 회의 지원',

            functionSwiperBannerTit5: '3D 모델 데이터 구조 확인 및 리뷰',
            functionSwiperBannerTxt5: 'Windchill Cad PLM 3D모델<br />리뷰',
            functionPopupContentTitle5: 'Windchill Cad PLM 3D<br />모델 리뷰',

            functionSwiperBannerTit6: '다국어 번역',
            functionSwiperBannerTxt6: '실시간 통역 서비스',

            functionSwiperBannerTit7: '공간 이동',
            functionSwiperBannerTxt7: '이동 포탈',

            functionSwiperBannerTit8: '화면 공유',
            functionSwiperBannerTxt8: '내 화면 공유',

            functionSwiperBannerTit9: '품질 이슈 관리',
            functionSwiperBannerTxt9: '품질 이슈 관리 연동 기능',

            functionSwiperBannerTit10: '파일 편집',
            functionSwiperBannerTxt10: '스크린, 3D모델 편집 기능',

            functionPopupContentTxt1_1:
                '<span>파일공유 메뉴</span>를 통한 PDF 스크린을 공유합니다.<br />스크린 공유 시 스크린 제어 및 화이트 보드 기능이 가능합니다.',
            functionPopupContentTxt1_2:
                '우측 상단 펜슬 모양을 선택 하면<br />스크린에 <span>화이트 보드 기능</span>을 사용할 수 있습니다.',

            functionPopupContentTxt2_1_pc:
                '웹 뷰 모듈 기능은 Chrome v10 기반으로 <span>기업 내 모든 인트라넷 web</span> 을 지원합니다. <br />구글, 네이버 등 원하는 사이트로 정보를 활용할 수 있으며,<br />웹을 통한 4D 모델 협업이 가능합니다.',
            functionPopupContentTxt2_1_mb:
                '웹 뷰 모듈 기능은 Chrome v10 기반으로<br/><span>기업 내 모든 인트라넷 web</span> 을 지원합니다. <br />구글, 네이버 등 원하는 사이트로 정보를 활용할<br />수 있으며, 웹을 통한 4D 모델 협업이 가능합니다.',

            functionPopupContentTxt3_1_pc:
                '프로젝트 매니저, 현장 엔지니어, 안전관리자, 운영 관리자 등을 대상으로<br />Online의 리모트 협업 XR 기반 워크스페이스 플랫폼에서 3D 기기를 조작하여<br />Offline의 실제 기기를 제어 및 각 공장 별 다양한 기기들을 셋팅 후 <span>리모트 협업 XR 기반<br />워크스페이스 플랫폼</span>을 배치하여 사용가능 합니다.<br />* 3D폴리웍스 솔루션을 사용하고 있는 기업에서는 MXspace를 통해<br />3D폴리웍스 3D모델 데이터를 자유롭게 로드하여 리모트 협업이 가능합니다.',
            functionPopupContentTxt3_1_mb:
                '프로젝트 매니저, 현장 엔지니어, 안전관리자, 운<br />영 관리자 등을 대상으로Online의 리모트 협업<br />XR 기반 워크스페이스 플랫폼에서 3D 기기를 조<br />작하여 Offline의 실제 기기를 제어 및 각 공장 별<br />다양한 기기들을 셋팅 후 <span>리모트 협업 XR 기반 워<br />크스페이스 플랫폼</span>을 배치하여 사용가능 합니다.<br />* 3D폴리웍스 솔루션을 사용하고 있는 기업에서<br />는 MXspace를 통해 3D폴리웍스 3D모델 데이터<br />를 자유롭게<br />로드하여 리모트 협업이 가능합니다.',
            functionPopupContentTxt3_2_pc:
                '모델의 원하는 위치에 Ray Casting Point 기능 또는 메모를 입력하여<br />참여자들과의 소통을 지원합니다.',
            functionPopupContentTxt3_2_mb:
                '모델의 원하는 위치에 Ray Casting Point 기능<br />또는 메모를 입력하여 참여자들과의 소통을 지원<br />합니다.',

            functionPopupContentTxt4_1_pc:
                '원격 회의, 협업이 필요한 기업 <span>모든 사람을 대상</span>으로 사무실과<br />현장간 실시간 업무 협업을 지원합니다.<br />자체 Web rtc 모듈을 적용, 클라우드와 구축형(온프라미스)모두 지원 및<br />동시 최대 100명 사용자와 화상채팅이 가능합니다.',
            functionPopupContentTxt4_1_mb:
                '원격 회의, 협업이 필요한 기업 <span>모든 사람을 대상</span><br />으로 사무실과 현장간 실시간 업무 협업을 지원합<br />니다.<br />자체 Web rtc 모듈을 적용, 클라우드와 구축형<br />(온프라미스)모두 지원 및 동시 최대 100명 사용<br />자와 화상채팅이 가능합니다.',
            functionPopupContentTxt4_2_pc:
                '2D 스크린으로 변경 하여 3D 공간 내 원격 회의 진행을 지원하며, 교수자와 업무 진행자가<br />모든 접속자의 화상과 음성을 컨트롤 하는 기능을 지원합니다.',
            functionPopupContentTxt4_2_mb:
                '2D 스크린으로 변경 하여 3D 공간 내 원격 회의 진행을 지원하며, 교수자와 업무 진행자가 모든<br />접속자의 화상과 음성을 컨트롤 하는 기능을 지원합니다.',

            functionPopupContentTxt5_1_pc:
                '3D폴리웍스 솔루션을 사용하고 있는 기업에서는 MXspace를 통해서 3D폴리웍스 3D 모델<br/><span>데이터를 자유롭게</span> 로드 하여 리모트 협업이 가능합니다.',
            functionPopupContentTxt5_1_mb:
                '3D폴리웍스 솔루션을 사용하고 있는 기업에서는<br/>MXspace를 통해서 3D폴리웍스 3D 모델 <span>데이터<br/>를 자유롭게</span><br/>로드 하여 리모트 협업이 가능합니다.',
            functionPopupContentTxt5_2_pc:
                'Windchill Cad PLM 3D모델의 <span>하이어라키 데이터 뷰어</span>를 통해 <br/>3D데이터 구조 확인 및 리뷰가 가능합니다.',
            functionPopupContentTxt5_2_mb:
                'Windchill Cad PLM 3D모델의 <span>하이어라키 데<br/>이터 뷰어</span>를 통해 3D데이터 구조 확인 및 리뷰가<br/>가능합니다.',

            functionPopupContentTxt6_1_pc:
                '채팅의 다국어번역 버튼을 선택 하면 다양한 언어를 <span>자동으로 인식</span>하여 <br />사용자가 설정한 언어로 실시간 번역됩니다.',
            functionPopupContentTxt6_1_mb:
                '채팅의 다국어번역 버튼을 선택 하면 다양한 언어를 <span>자동으로 인식</span>하여 사용자가 설정한 언어로 실시간 번역됩니다.',
            functionPopupContentTxt6_2_pc:
                '3D 공간 내 번역기능을 사용하며 <br /><span>자유로운</span> “스크린 공유, 화상채팅, 3D모델 리뷰“ 등 <span>업무 협업</span>이 가능합니다.',
            functionPopupContentTxt6_2_mb:
                '3D 공간 내 번역기능을 사용하며 <span>자유로운</span> “스크<br />린 공유, 화상채팅, 3D모델 리뷰“ 등 <span>업무 협업</span>이<br />가능합니다.',

            functionPopupContentTxt7_1_pc:
                '공간과 공간을 이어주는 기능으로, 서로 다른 공간을 자유롭게 오가고 싶을 때 사용합니다. <br/>개수에 제약 없이 <span>원하는 만큼 포탈 생성이 가능</span>하며, <br/>편집 버튼을 통해 포탈을 수정할 수 있습니다.',
            functionPopupContentTxt7_1_mb:
                '공간과 공간을 이어주는 기능으로, 서로 다른 공<br/>간을 자유롭게 오가고 싶을 때 사용합니다.<br/>개수에 제약 없이 <span>원하는 만큼 포탈 생성이 가능</span>하<br/>며, 편집 버튼을 통해 포탈을 수정할 수 있습니다.',
            functionPopupContentTxt7_2:
                '파일공유 메뉴를 통해 포탈의 이미지를 <span>등록 및 수정</span> 합니다.',

            functionPopupContentTxt8_1_pc:
                '3D 공간 내에 내 화면의 콘텐츠를 공유합니다. 공유할 화면 선택창에서는 <br />스크린과 사용중인 앱 icon이 구분되어 공유하고자 하는 스크린을 <span>빠르게 찾을 수 있습니다.</span>',
            functionPopupContentTxt8_1_mb:
                '3D 공간 내에 내 화면의 콘텐츠를 공유합니다. 공<br />유할 화면 선택창에서는 스크린과 사용중인 앱<br />icon이 구분되어 공유하고자 하는 스크린을 <span>빠르<br />게 찾을 수 있습니다.</span>',
            functionPopupContentTxt8_2_pc:
                '공유된 내 화면의 스크린은 3D,2D 로 <span>자유로운 변경이 가능</span>하며, 화이트보드 기능 지원 및<br />2D 사용 시 참여자들 화면에 동기화 되어 업무 협업이 가능합니다.',
            functionPopupContentTxt8_2_mb:
                '공유된 내 화면의 스크린은 3D,2D 로 <span>자유로운<br />변경이 가능</span>하며, 화이트보드 기능 지원 및 2D 사<br />용 시 참여자들 화면에 동기화 되어 업무 협업이<br />가능합니다.',

            functionPopupContentTxt9_1_pc:
                '가상 공간내 3D모델 품질 이슈 (개선대책서) <span>추적 관리 기능을 제공</span> 합니다.<br />기업 내 사용 중인 경우에 한하여 3D 폴리웍스 모델 데이터 활용 및 관리 기능 제공하며,<br />3D 스캔 데이터 업로드 obj, csv 지원 및 폴리웍스 3D 모델 자동 경량화 기능을 제공합니다.',
            functionPopupContentTxt9_1_mb:
                '가상 공간내 3D모델 품질 이슈 (개선대책서) <span>추적<br />관리 기능을 제공</span> 합니다.<br />기업 내 사용 중인 경우에 한하여 3D 폴리웍스 모<br />델 데이터 활용 및 관리 기능 제공하며, 3D 스캔<br />데이터 업로드 obj, csv 지원 및 폴리웍스 3D 모<br />델 자동 경량화 기능을 제공합니다.',

            functionPopupContentTxt10_1_pc:
                '3D 공간 내에 로드한 스크린, 3D 모델을 편집 합니다.<br />모든 파일은 편집 상태에서 마우스로 드래그 하여 <span>원하는 곳에 배치</span> 가능합니다.<br />스크롤러를 사용하여 X축, Y축, Z축 이동 및 회전과 크기를 조절하며, 스크린 가로, 세로 비율 조정, 새로고침이 가능합니다.',
            functionPopupContentTxt10_1_mb:
                '3D 공간 내에 로드한 스크린, 3D 모델을 편집 합<br />니다.<br />모든 파일은 편집 상태에서 마우스로 드래그 하여<br /><span>원하는 곳에 배치</span> 가능합니다. 스크롤러를 사용하<br />여 X축, Y축, Z축 이동 및 회전과 크기를 조절하<br />며, 스크린 가로, 세로 비율 조정, 새로고침이 가<br />능합니다.',

            // footer
            찾아오시는_길: '찾아오시는 길',
            개인정보처리방침: '개인정보처리방침',
            회사_주소: '(04781) 서울 성동구 성수일로4길 25 서울숲코오롱디지털타워 1차 19층 1902호 ',
            문의하기: '문의하기',
        },
    },
};
i18n.use(initReactI18next).init({
    resources,
    lng: 'ko',
    fallbackLng: 'ko',
});
export default i18n;
