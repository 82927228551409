import React, { useState, useCallback } from 'react';
import { loginAdmin } from '../../../_actions/AdminAction'; 
import { useNavigate } from "react-router-dom";
import { Button, Input } from 'antd';
import { useDispatch } from "react-redux";
import { alertMessage } from "../../utils/utils";
import * as ROUTES from '../../../constants/routes'

function LoginAdmin(props: { history: string[]; }) {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [adminid, setAdminid] = useState("")
    const [password, setpassword] = useState("")
    const loginAdminCallback = useCallback(async (body : {adminid : string, password: string}) => dispatch(await loginAdmin(body)),
    [dispatch]
    );

    const loginHeaderStyle = {
        display: "flex", 
        height: "45px", 
        paddingLeft: "20px",
        justifyContent:"flex-start",
        alignContent: "center",
        alignItems: "center",
        borderBottom: "1px solid #DBDBDB",
        color: "#8F8F8F"
    }

    const loginBodyStyle = {
        display: 'grid', 
        gridTemplateRows: '50px, [1fr], 50px',
        justifyContent: 'center', 
        alignContent: "center",
        width: '100%', 
        height: 'calc(100vh - 100px)', 
    }

    const loginInputStyle = {
        background: "#F5F5F5",
        borderRadius: "10px",
        marginTop: "10px",
        height: "45px",
        fontSize : '14px'
    }

    const loginButtonStyle:any = {
        background: "#673ab7",
        color: "white",
        borderRadius: "10px",
        height: "45px",
        "&:hover": {
            borderColor: "#673ab7",
        },
    }

    const loginImageStyle = {
        width: "50%",
        marginBottom: "10px",
        alignSelf: "end",
        justifySelf: "center"
    }
    const onadminidHandler = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setAdminid(event.currentTarget.value)
    }

    const onpasswordHandler = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setpassword(event.currentTarget.value)
    }


    const onSubmitHandler = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        
        let body = {
            adminid: adminid,
            password: password
        }
        
        loginAdminCallback(body).then(((response)=>{
            if(!!response.payload.loginStatus){
                navigate(ROUTES.ADMIN_DASHBOARD_F)
            }
        }))
        .catch((error) => {
            if(!error.response){
                alertMessage("서버와 연결이 끊어졌습니다.");    
            }
        });
        
    }

    return (
        <>
            <div style={loginHeaderStyle}>
                <img src="/images/logo_mxspace2.png" style={{height: "22px"}} alt="" />
                <span style={{marginLeft: "10px", fontWeight: "400"}}>Meta eXperience Space</span>
            </div>
            <div style={loginBodyStyle}>
                <div style={{textAlign: "center"}}>
                    <img src="/images/logo_mxspace.png" style={loginImageStyle} alt="" />

                    <form style={{ display: 'flex', flexDirection: 'column', padding: '0 7% 0' }}
                        onSubmit={onSubmitHandler}
                    >
                        <Input  type="text" value={adminid} onChange={onadminidHandler} 
                            placeholder="아이디" style={loginInputStyle} />
                        <Input  type="password" value={password} onChange={onpasswordHandler} 
                            placeholder="비밀번호" style={loginInputStyle} />
                        <br />
                        <Button htmlType="submit" style={loginButtonStyle}>
                            관리자 로그인
                        </Button>
                    </form>

                </div>
            </div>
            <div style={{textAlign: 'center'}}>
                    <img src="/images/ui_logo@3x.png" style={{height: "20px", alignSelf: "start"}} alt=""/>
            </div>

        </>
    )
}

export default LoginAdmin 