import { useEffect, useState } from "react"
import styled from "styled-components";
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { alertMessage, infoMessage } from "../../utils/utils";
import { confirmCompanyName, getCompanyData, editCompany } from '../../../_actions/CompanyAction';
import { Input, Button, Radio, Checkbox } from 'antd';
import type { RadioChangeEvent, CheckboxProps } from 'antd';
import * as ROUTES from '../../../constants/routes'
import { log } from "console";

const Title = styled.h3`
    margin-bottom: 5%;
    border-bottom: 2px solid red;
    width: 25%;
    padding-bottom: 2px;
    font-weight: 700;
`;
const Item = styled.th`
    text-align: left;
    padding-left: 3%;
    font-size: 1rem;
    width:30%;
    border:none;
    font-weight: 500;
`;
 const Td = styled.td`
    border:none;
`;

function EditCompany() {
    let navigate = useNavigate();

    //현재 url에서 company_index 잘라내서 변수에 저장
    let currentUrl = window.location.href;
    let urlStr = currentUrl.split('/');
    let companyuid = urlStr[6];

    
    const [Company_name, setCompany_name] = useState<string>("")
    const [License_type, setLicense_type] = useState("0")
    const [Oldname, setOldname] = useState("")
    const [License_startdate, setLicense_startdate] = useState("")
    const [License_enddate, setLicense_enddate] = useState("")
    const [Max_users, setMax_users] = useState("default")
    const [Chatting_hour, setChatting_hour] = useState("")
    const [Screen_hour, setScreen_hour] = useState("")
    const [Manager_email, setManager_email] = useState("")
    const [Business_number, setBusiness_number] = useState("")
    const [Manager_phone, setManager_phone] = useState("")
    const [Olddate, setOlddate] = useState("")

    // RTC 모드, URL 추가
    const [RTC_mode, setRTC_mode] = useState("0")
    const [RTC_url, setRTC_url] = useState("")
    
    //stt
    // const [Stt_originkey, setStt_originkey] = useState("")
    // const [Stt_originregioncode, setStt_originregioncode] = useState("")
    const [Stt_regioncode, setStt_regioncode] = useState("")
    const [Stt_key, setStt_key] = useState("")
    const [Stt_flag, setStt_flag] = useState(false)
    const [UseTranslate, setUseTranslate] = useState(false)

    //오류 메세지 상태 저장 
    const [nameMessage, setNameMessage] = useState<string>('')
    const [emailMessage, setEmailMessage] = useState('')
    //유효성 검사
    const [Confirm_name, setConfirm_name] = useState<boolean>(true)
    const [Confirm_email, setConfirm_email] = useState<boolean>(true)
    const [visible, setVisible] = useState<boolean>(false)
    const [SuccessEdit, setSuccessEdit] = useState(false)

    useEffect(() => {
        getCompanyData(companyuid)
        .then(response => {
            setCompany_name(response.data.company_name);
            setLicense_type(response.data.license_type);
            setLicense_startdate(response.data.license_startdate);
            setLicense_enddate(response.data.license_enddate);
            setMax_users(response.data.max_users);
            setChatting_hour(response.data.chatting_hour);
            setScreen_hour(response.data.screen_hour);
            setManager_email(response.data.manager_email);
            setBusiness_number(response.data.business_number);
            setManager_phone(response.data.manager_phone);
            setOlddate(response.data.license_enddate);
            setOldname(response.data.company_name);
            setRTC_mode(response.data.rtc_mode);
            setRTC_url(response.data.rtc_url);
            if(response.data.stt_key != null)  setStt_key(response.data.stt_key);
            if(response.data.stt_regioncode != null) setStt_regioncode(response.data.stt_regioncode);
            // setStt_originkey(response.data.stt_key)
            // setStt_originregioncode(response.data.stt_regioncode)
           
            let sttDiv =  document.getElementById("sttData")
            if(Stt_key != null && Stt_key != "" && Stt_regioncode != null && Stt_regioncode != ""){
                setUseTranslate(true)
                sttDiv!.style.display = "flex";
            }
            setStt_flag(true)

        }).catch((error)=>{
            alertMessage(error.response.data);
        });        
    }, [companyuid, Stt_flag])

    useEffect(()=>{
        if(SuccessEdit === true){
            navigate(ROUTES.ADMIN_MANAGECOMPANY_F); 
        }
    },[SuccessEdit, navigate])
    
    const onChange: CheckboxProps['onChange'] = (e) => {
        setUseTranslate(e.target.checked)
        let sttDiv =  document.getElementById("sttData")
        if(e.target.checked){
            sttDiv!.style.display = "flex";
            
        }else{
            sttDiv!.style.display = "none";
            setStt_key("")
            setStt_regioncode("")
        }
    };

    const onChangeSttKey = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setStt_key(event.currentTarget.value)
    }

    const onChangeSttRegionCode = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setStt_regioncode(event.currentTarget.value)
    }

    //아이디 중복 검사
    function confirmName(){
        if(Company_name === Oldname){
            setConfirm_name(true);
            setNameMessage("사용 가능한 회사명입니다.")
        }else{
            if(Company_name.length < 1){
                alert("회사명을 입력해주세요.")
            }else{
                confirmCompanyName({company_name : Company_name})
                .then((response) => {
                    if(response.data.result){
                        setNameMessage("사용 가능한 회사명입니다.")
                        setConfirm_name(true)
                    }else{
                        setNameMessage("사용할 수 없는 회사명입니다.")
                        alertMessage(response.data.resultMessage);
                        setConfirm_name(false)
                    }
                })
                // .catch((error)=>{
                //     alertMessage(error.response.data);
                // })
            }
        }
    }

    const onClickHandler = (event: { preventDefault: () => void;}) => {
        
        let body = {
            company_name: Company_name,
            license_type: License_type,
            license_startdate: License_startdate,
            license_enddate: License_enddate,
            max_users: Max_users,
            chatting_hour: Chatting_hour,
            screen_hour: Screen_hour,
            manager_phone : Manager_phone,
            manager_email: Manager_email,
            business_number: Business_number,
            rtc_mode : RTC_mode,
            rtc_url : RTC_url,
            stt_key : Stt_key,
            stt_regioncode : Stt_regioncode
        }
      
        if(UseTranslate && (Stt_key == "" || Stt_key == null || Stt_regioncode == "" || Stt_regioncode == null)){
            event.preventDefault();
            infoMessage("STT Key 또는 지역 코드를 정확히 입력해 주세요.")
        }
        else if(Confirm_name === true && License_startdate !== "" && License_startdate !== null &&
            License_enddate !== "" && License_enddate !== null && Max_users !== "default" && Chatting_hour !== "" && 
            Chatting_hour !== null && Screen_hour !== "" && Screen_hour !== null && Confirm_email === true && Manager_phone !== ""
        ){
            editCompany(companyuid, body)
                .then((response) => {
                    if(response.data.result === 1){
                        infoMessage(response.data.resultMessage)
                        // alert("수정 성공")
                        setSuccessEdit(true)
                    }else{
                        event.preventDefault();
                        alertMessage(response.data.resultMessage);
                        // alert("수정 실패")

                    }
                })
                // .catch((error)=>{
                //     alertMessage(error.response.data);
                // })
        }else{
            event.preventDefault();
            alert("모든 항목을 완료해주세요.")
        }
    }

    const onCompanyName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setCompany_name(event.currentTarget.value)
        setConfirm_name(false)
        if(Company_name.length < 1) setNameMessage("")
    }
    
    //라이센스 타입
    const onChangeRadioButton = (e: RadioChangeEvent) => {
        setLicense_type(e.target.value);
    };

    const onChangeRTCRadioButton = (e: RadioChangeEvent) => {
        setRTC_mode(e.target.value)
    };

    const onCalendar = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setLicense_enddate(event.currentTarget.value)
    }

    const onMaxUsers = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setMax_users(event.currentTarget.value)
    }

    const onChattingHour = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setChatting_hour(event.currentTarget.value)
    }

    const onScreenHour = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setScreen_hour(event.currentTarget.value)
    }

    const onManagerEmail = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {

        // const emailRegex =
        //     /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
        const emailCurrent = event.currentTarget.value
        setManager_email(emailCurrent)
    
        // if (!emailRegex.test(Manager_email)) {
        //     setEmailMessage('이메일 형식을 확인해주세요.')
        //     setConfirm_email(false)
        // } else {
        //     setEmailMessage('올바른 이메일 형식이에요.')
        //     setConfirm_email(true)
        // }
    }
    const onChangeManagerPhone = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setManager_phone(event.currentTarget.value)
    }
    function formatDate(date:any) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
    
    function getDate(){
        var today = new Date();

        var year = today.getFullYear();
        var month = ('0' + (today.getMonth() + 1)).slice(-2);
        var day = ('0' + today.getDate()).slice(-2);

        var date = year + '-' + month  + '-' + day;
        return date; 
    }
    const clickAddYear = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        if(visible){
            if(window.confirm("변경사항을 초기화하고 1년 연장으로 전환하시겠습니까?")){
                setLicense_enddate(Olddate);
                setVisible(false)
                alert("'1년 연장'버튼을 클릭하여 기간을 연장해 주세요.")
            }
        }else{
            let date = new Date(License_enddate);
            date.setFullYear(date.getFullYear() + 1);
            setLicense_enddate(formatDate(date))
            alert("1년 연장이 적용되었습니다.")
        }
    }

    const onReset = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setLicense_enddate(Olddate)
        setVisible(false)
    }

    const clickAddSelf = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        if(visible === false && Olddate !== License_enddate){
            if(window.confirm("변경사항을 초기화하고 수동 연장으로 전환하시겠습니까?")){
                setLicense_enddate(Olddate);
                setVisible(true)
                // alert("종료 일자를 선택해주세요. 오늘 이후 날짜부터 선택 가능합니다.")
            }
        }
        setVisible(!visible);
        if(visible === false) alert("종료 일자를 선택해주세요. 오늘 이후 날짜부터 선택 가능합니다.")
    }

    return (
        <form style={{marginLeft: '2%'}} >
            <Title style={{marginBottom: '5%'}}>계정관리 {'>'} EDIT</Title>

            <div>
                <table style={{borderSpacing: '0 15px',borderCollapse: 'separate', border:'none'}}>
                    <tbody>
                        <tr>
                            <Item>1. 회사명</Item>
                            <Td>
                                <Input style={{width: "250px"}} name="company_name" onChange={onCompanyName} 
                                    autoComplete="off" value={Company_name}/>&nbsp;
                                <Button onClick={confirmName}>중복체크</Button><br/>
                                {Company_name.length > 0 && <span className={`message ${Confirm_name ? 'success' : 'error'}`}>{nameMessage}</span>}                         
                            </Td>
                        </tr>
                        <tr>
                            <Item>2. 계약 타입</Item> 
                            <Td>
                                <Radio.Group onChange={onChangeRadioButton} value={License_type}>
                                    <Radio value={'0'}>테스트 버전</Radio>
                                    <Radio value={'1'}>정식 버전</Radio>
                                </Radio.Group>
                            </Td>
                        </tr>
                        <tr>
                            <Item>3. 기간 설정</Item>
                            <Td>
                                <Input style={{width: "120px"}} name="license_startdate" value={License_startdate} disabled />&nbsp;-&nbsp;
                                <Input style={{width: "120px"}} name="license_enddate" value={License_enddate} disabled /><br/><br/>
                                <button type="button" onClick={clickAddYear}>1년 연장</button>&nbsp;&nbsp;
                                <button type="button" onClick={clickAddSelf}>수동 연장</button>&nbsp;&nbsp;
                                {visible && <input type="date" min={getDate()} onChange={onCalendar} name="" />}&nbsp;&nbsp;
                                <button type="button" onClick={onReset}>초기화</button>
                            </Td>
                        </tr> 
                        <tr> 
                            <Item>4. 계정 수량</Item>
                            <Td>
                                <select name="max_users" value={Max_users} onChange={onMaxUsers} >
                                    <option value="default">수량 선택</option>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="250">250</option>
                                    <option value="500">500</option>
                                    <option value="1000">1,000</option>
                                    <option value="5000">5,000</option>
                                </select>
                            </Td>
                        </tr>
                        <tr>
                            <Item>5. RTC 모드</Item>
                            <Td>
                                <Radio.Group onChange={onChangeRTCRadioButton} value={RTC_mode}>
                                    <Radio value={'0'}>Agora</Radio>
                                    <Radio value={'1'}>MX RTC</Radio>
                                </Radio.Group>
                                
                            </Td>
                        </tr>
                        <tr>
                            <Item></Item>
                            <Td>
                                <Input type="text" placeholder='RTC url' style={{width: "250px"}} name="rtc_url" value={RTC_url} onChange={(e)=>{setRTC_url(e.target.value)}}/>
                            </Td>
                        </tr>
                        <tr>
                            <Item>6. 화상채팅 시간</Item>
                            <Td>
                                <NumberFormat style={{width: "250px"}} name="chatting_hour" onChange={onChattingHour} value={Chatting_hour} customInput={Input} thousandSeparator={true} />
                                <Checkbox checked={UseTranslate} onChange={onChange} style = {{marginLeft:'30px'}}>실시간 통역 사용</Checkbox>
                            </Td>
                        </tr>
                        <tr>
                            <Item> </Item>
                            <Td>
                                <div id="sttData" style={{display:'none', justifyContent:'space-evenly'}}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <div style={{marginRight:'20px', color:'red'}}>*STT Key</div><div><Input type="text" value={Stt_key} onChange={onChangeSttKey}></Input></div>
                                    </div>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <div style={{margin:'0 20px 0 20px', color:'red'}}>*지역 코드</div><div><Input type="text" value={Stt_regioncode} onChange={onChangeSttRegionCode}></Input></div>
                                    </div>
                                </div>
                            </Td>
                        </tr>
                        <tr>
                            <Item>7. 화면송출 시간</Item>
                            <Td>
                                <NumberFormat style={{width: "250px"}} name="screen_hour" onChange={onScreenHour} 
                                                value={Screen_hour} customInput={Input} 
                                                thousandSeparator={true} />
                            </Td>
                        </tr>
                        <tr>
                            <Item>8. 담당자 이메일</Item>
                            <Td>
                                <Input type="email" name="manager_email" style={{width: "250px"}}
                                            onChange={onManagerEmail} autoComplete="off" 
                                            value={Manager_email}/><br/>
                                {Manager_email.length > 0 && <span className={`message ${Confirm_email ? 'success' : 'error'}`}>{emailMessage}</span>}
                            </Td>
                        </tr>
                        <tr>
                            <Item>9. 담당자 연락처</Item>
                            <Td>
                            {/* <Input style={{width: "250px"}} placeholder={"숫자만 입력해 주세요."} onChange={onChangeManagerPhone}/>  */}
                            <NumberFormat style={{width: "250px"}} name="business_number" onChange={onChangeManagerPhone}
                                        customInput={Input} format="###-####-####" autoComplete="off" value={Manager_phone}/><br/>
                            </Td>
                        </tr>
                        <tr>
                            <Item>10. 사업자번호</Item>
                            <Td>
                                <NumberFormat style={{width: "250px"}} name="business_number" value={Business_number} 
                                            customInput={Input} format="###-##-#####" autoComplete="off" 
                                            disabled/><br/>
                            </Td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{width: '55%',margin:'20px auto'}}><Button onClick={onClickHandler}>Save</Button></div>
        </form>
    )
}

export default EditCompany
