import axios from 'axios';

export async function getAppLinkList() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/manager/applink`);
}
export async function getModelFileList() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/manager/modelFile`);
}
export async function deleteAppLink(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/manager/applink/delete`, dataToSubmit);
}

export async function createAppLink(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/manager/applink/create`, dataToSubmit);
}

export async function editAppLink(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/manager/applink/update`, dataToSubmit);
}
