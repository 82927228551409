import axios from 'axios';

// maplist
export async function getMapList() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/map/getMaplist`);
}

export async function editMap(mapToSubmit : any) {
    return await axios.put(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/map/editMap`, mapToSubmit);
}

export async function checkMapname(mapToSubmit : any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/map/checkMapname`, mapToSubmit);
}

export async function uploadMapImage(group:Number, type:String, fileToSubmit : any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/map/uploadMapImage?group=${group}&type=${type}`, fileToSubmit);
}

export async function getMapIndexlist() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/map/getMapIndexlist`);
}

//mapsetting

export async function getCompanyMaplist() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/map/getCompanyMaplist`);
}
export async function editCompanyMap(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/map/editCompanyMap`, dataToSubmit);
}
export async function addMap(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/map/addMap`, dataToSubmit);
}
export async function getMapIndexValidList() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/map/getMapIndexValidList`);
}