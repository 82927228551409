import React, {useEffect, useState} from 'react'
import { getMapList } from '../../../_actions/MapAction';
import styled from "styled-components";
import { Space, Table, Button } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { editMap } from '../../../_actions/MapAction';
import { MapType } from '../mapSetting/MapSetting';
import MapListEditModal from './MapListEditModal';
import { Checkbox } from 'antd';
import CODE from '../../../constants/code';

const Title = styled.h3`
    border-bottom: 2px solid red;
    width: 75%;
    padding-bottom: 2px;
    font-weight: 700;
`;
export default function MapList() {
    const [mapList, setMapList] = useState<MapType[]>([]);
    const [isEditModal, setIsEditModal] = useState(false);
    const [selectedMap, setSelectedMap] = useState([]);
    const [checkState, setCheckState] = useState(true);

    const columns: ColumnsType<MapType> = [
        {
            title: '순번',
            dataIndex: 'key',
            key: 'key',
            align: 'center',
            width: 75,
            render: (text, row, index) => {
                return(<div>{index+1}</div>)
            }
        },
        {
            title: 'basic',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 200,
            render:(text, record) => (
                <BasicCheck record={record} checkState={checkState} setCheckState={setCheckState}/>
            )
        },
        {
            title: 'MAP NAME',
            dataIndex: 'mapname',
            key: 'mapname',
            align: 'center',
            render: (text, record) => (
                <div>{record.mapname}</div>
                )
            
        },
        
        {
            title: 'Window',
            dataIndex: 'thumbnail',
            key: 'thumbnail',
            align: 'center',
            render: (text, record) => (
                <img height='50px' width='auto' src={`${process.env.REACT_APP_SERVER_BASE_URL}/${record.thumbnail}`} alt=''/>
                )
            
        },
        {
            title: 'Andriod',
            dataIndex: 'thumbnail_p',
            key: 'thumbnail_p',
            align: 'center',
            render: (text, record) => (
                <img height='50px' width='auto' src={`${process.env.REACT_APP_SERVER_BASE_URL}/${record.thumbnail_p}`} alt=''/>
                )
            
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 160,
            render: (record) => (
                <Space size="small">
                    {record.mapid > -1 ?
                    <Button onClick={()=>{openEditModal(record)}}>Edit</Button>
                    :<div></div>}
                </Space>
            )
        },
    ];
    const getMapListFunc = () => {
        getMapList().then((response)=>{
            if(Number(response.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                let json:MapType[] = response.data.maplist;
                setMapList(json);
            }
        })
    }
    useEffect(() => {
        getMapListFunc()
    }, [isEditModal, checkState])
    const openEditModal = (data:any) => {
        setIsEditModal(true);
        setSelectedMap(data)
    }
    const closeEditModal = () => {
        setIsEditModal(false);
        setSelectedMap([])
        
    }
    return (
        <>
            <div style={{margin :'0 2%'}}>
            <br/><br/>
                <Title><span>Map List</span></Title>
            <br/><br/>
                <Table columns={columns} dataSource={mapList} rowKey={(render)=> render.mapid} tableLayout={"auto"} />
            </div>
            {isEditModal && <MapListEditModal 
                closeEditModal={closeEditModal}
                selectedMap={selectedMap}
            />}
        </>
    )
}

const BasicCheck = (props:any) => {
    let {record, setCheckState, checkState} = props
    const [isCheckable, setIsCheckable] = useState(true);
    const [checkvalue, setCheckvalue] = useState(record.status);

    const onCheckFunc =  (e:CheckboxChangeEvent) => {
        setCheckvalue(!checkvalue)
        setCheckState(!checkState)
        
        editMap({...record, status : !checkvalue ? 1 : 0, assignChanged:true})
        .then((res:any)=>{
            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                setIsCheckable(true)
            } else {
                return false
            }
        }).catch((err:any)=>{
            console.error(err)
            setCheckvalue(!checkvalue)
        })
    }
    return (
        <Checkbox
        key={record.mapid}
        onChange={onCheckFunc}
        checked={!!checkvalue}
        value={record}
        disabled={!isCheckable}
        />
    );
};
