import { Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../../../_reducers"; 
import { getRegistWaitList } from '../../../_actions/CompanyAction';


const Title = styled.h3`
    border-bottom: 2px solid red;
    margin-right: 75%;
    padding-bottom: 2px;
    font-weight: 700;
    margin-bottom: 50px;
`;

export interface DataType {
    key: React.Key;
    companyIndex: number;
    companyName: string;
    licenseType: string;
    licenseDate: string;
    maxUsers: number;
    managerEmail: string;
    managerPhone: string;
    businessNumber: string;
}

export default function RegistWaitList(props:any) {

    const [companyDataList, setCompanyDataList] = useState<DataType[]>([]);
    const {adminData} = useSelector((state : RootState) => state.adminReducer);

    const { uid } = adminData;

    useEffect(() => {
        getRegistWaitList().then((response)=>{
            let json:DataType[] = response.data;
            setCompanyDataList(json);
        });
    }, [uid])
    
    const columns: ColumnsType<DataType> = [
        {
            title: 'No',
            dataIndex: 'companyIndex',
            key: 'companyIndex',
            align: 'center',
            render: (text, row, index) => {
                return(<div>{index+1}</div>)
            }
        },
        {
            title: '회사명',
            dataIndex: 'companyName',
            key: 'companyName',
            align: 'center',
        },
        {
            title: '계약 타입',
            dataIndex: 'licenseType',
            key: 'licenseType',
            align: 'center',
        },
        {
            title: '기간 설정',
            dataIndex: 'licenseDate',
            key: 'licenseDate',
            align: 'center',
        },
        {
            title: '계정 수량',
            dataIndex: 'maxUsers',
            key: 'maxUsers',
            align: 'center',
        },
        {
            title: '담당자 이메일',
            dataIndex: 'managerEmail',
            key: 'managerEmail',
            align: 'center',
        },
        {
            title: '담당자 연락처',
            dataIndex: 'managerPhone',
            key: 'managerPhone',
            align: 'center',
        },
        {
            title: '사업자 번호',
            dataIndex: 'businessNumber',
            key: 'businessNumber',
            align: 'center',
        }
    ];

    return (
        <div style={{marginLeft:'2%'}}>
            <Title>회사 등록 관리</Title>
            <Table columns={columns} dataSource={companyDataList} rowKey={(render)=> render.businessNumber} />
        </div>
    )
}