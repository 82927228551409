import styled from 'styled-components';

export const GroupButtonLayout = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 120px;
    align-items: center;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
`;

export const TableLayout = styled.div`
    display: flex;
    justify-content: space-between;

    .userTable,
    .funcTable {
        tbody > tr:nth-child(1) > td {
            padding: 0px !important;
        }
    }
`;
