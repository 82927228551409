
import { useRef } from 'react';
import './Main.css';
import { Carousel } from 'antd';
import MXSPACEwebNav from './Nav'
import MXSPACEwebFooter from './Footer'
import DownloadBtn from './DownloadBtn'
import logo2 from './images/01_logo_mxspace2.png'
import mainImage from './images/01_main_image.png'
import fullVideo from './videos/0718_comp.mp4'
import title from './images/01_title.png'
import title_m from './images/01_title_mobile.png'
import title2 from './images/02_title2.png'
import title2_m from './images/02_title_mobile.png'
import comuniImg1 from './images/04_img1.png'
import comuniImg2 from './images/04_img2.png'
import comuniImg3 from './images/04_img3.png'

import icon1 from './images/03_icon_3d.png'
import icon2 from './images/03_icon_memo.png'
import icon3 from './images/03_icon_video.png'
import icon4 from './images/03_icon_vr.png'

import slide1_1 from './images/03_image_bim1.jpg'
import slide1_2 from './images/03_image_bim2.jpg'
import slide2_1 from './images/03_image_custom1.jpg'
import slide2_2 from './images/03_image_custom2.jpg'
import slide3_1 from './images/03_image_offline1.jpg'
import slide3_2 from './images/03_image_offline2.jpg'
import plaform_Desk from './images/05_platforms.png'
import plaform_tablet from './images/05_platforms_pad.png'
import plaform_mobile from './images/05_platforms_mobile.png'
import { BackTop } from 'antd';
import { useTranslation } from 'react-i18next'

function useMoveScroll(name2 : string) {
    const name = name2
    const element = useRef<HTMLDivElement>(null);
    const onMoveToElement = () => {
      element.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    return { name, element, onMoveToElement};
  }
export default function MXSPACEweb() {
    const { t } = useTranslation()
    const goodsTabs = [
        useMoveScroll('About'),
        useMoveScroll('Function'),
        useMoveScroll('Communication'),
        useMoveScroll('Platform'),
      ];

    return (
        <div className="pageWrapper flexCol">
            <BackTop />
            <MXSPACEwebNav goodsTabs={goodsTabs}/>
            <div className="fullImage topImage flexCol">
                <img alt='메인이미지 높이용 숨김' className="mainImage hiddenImage" src={mainImage}/>
                <div className=' flexCol topTitle'>
                    <img alt='로고2' className="logo2" src={logo2}/>
                    <div className=' flexCol topTitleMain'>{t('기업형_메타버스_플랫폼_서비스')}</div>
                    <DownloadBtn/>
                </div>
            </div>
            <div className="IntroText flexCol " ref={goodsTabs[0].element}>
                    <img alt='타이틀 이미지' className="IntroTextImage widePc" src={title}/>
                    <img alt='타이틀 이미지' className="IntroTextImage ipadMini" src={title}/>
                    <img alt='타이틀 이미지' className="IntroTextImage wideMobile" src={title_m}/>
                <div className="flexCol centerText widePc">
                    <div>{t('기술_산업에_특화된_맞춤형_메타버스_서비스')}</div>
                    <div>{t('원거리_언텍트_업무_협의와_각종_문서,_영상_공유가_가능하며')}</div>
                    <div>{t('상상했던_모든_것이_Online_MXSPACE_공간속에서_Offline의_실제_기기_제어까지_현실로_가능해집니다.')}</div>
                </div>
                <div className="flexCol centerText ipadMini">
                    <div>{t('기술_산업에_특화된_맞춤형_메타버스_서비스')}</div>
                    <div>{t('원거리_언텍트_업무_협의와_각종_문서,_영상_공유가')}</div>
                    <div>{t('가능하며_Online_MXSPACE_공간속에서')}</div>
                    <div>{t('Offline의_실제_기기_제어까지_현실로_가능해집니다.')}</div>
                    <div>{t('상상했던_모든_것이_현실로_이어집니다.')}</div>
                </div>
                <div className="flexCol centerText wideMobile">
                    <div>{t('기술_산업에_특화된_맞춤형_메타버스_서비스')}</div>
                    <div>{t('원거리_언텍트_업무_협의와_각종_문서,_영상_공유가')}</div>
                    <div>{t('가능하며_Online_MXSPACE_공간속에서')}</div>
                    <div>{t('Offline의_실제_기기_제어까지_현실로_가능해집니다.')}</div>
                    <div>{t('상상했던_모든_것이_현실로_이어집니다.')}</div>
                </div>
            </div>
            {/* <div className="fullVideo flexCol"> */}
                <video src={fullVideo} controls controlsList="nodownload" autoPlay={true} loop={true} muted={true} playsInline/>
            {/* </div> */}
            
            <div className="bodyContents flexCol" ref={goodsTabs[1].element}> 
            <div className="IntroText flexCol ">
                <img alt='타이틀 이미지2' className="IntroTextImage widePc" src={title2}/>
                <img alt='타이틀 이미지2' className="IntroTextImage ipadMini" src={title2}/>
                <img alt='타이틀 이미지2' className="IntroTextImage2 wideMobile" src={title2_m}/>
                
                <div className="flexCol centerText centerText2 widePc">
                    <div>{t('사용자_지정_환경에_맞는_다양한_제품을_공간_커스텀을_통해')}</div>
                    <div>{t('기업에_특화된_협업_시스템으로_보다_높은_업무_효율을_제공합니다.')}</div>
                </div>
                <div className="flexCol centerText centerText2 ipadMini">
                    <div>{t('사용자_지정_환경에_맞는_다양한_제품을')}</div>
                    <div>{t('공간_커스텀을_통해_기업에_특화된_협업')}</div>
                    <div>{t('시스템으로_보다_높은_업무_효율을_제공합니다.')}</div>
                </div>
                <div className="flexCol centerText centerText2 wideMobile">
                    <div>{t('사용자_지정_환경에_맞는_다양한_제품을')}</div>
                    <div>{t('공간_커스텀을_통해_기업에_특화된_협업')}</div>
                    <div>{t('시스템으로_보다_높은_업무_효율을_제공합니다.')}</div>
                </div>
            </div>
                <div className="bodyContentContainer">
                        <div className="bodyContentItem">
                            <img alt='아이콘4' className="bcImg" src={icon4}/>
                            <div className='bcMainText'>MXSPACE 메타버스에서<br/>
                                AR증강현실로 확장합니다</div>
                            <div className='bcBlueText'>AR 증강 현실과 Metaverse의 만남</div>
                            <div className='bcText'>작업자 간의 거리 제약이 없이 AR 증강 현실<br/>
                                화면 중계 시스템을 통해 업무 협업이<br/> 
                                가능하도록 MXSPACE가 해결해 드립니다.</div>
                        </div>
                        <div className="bodyContentItem bodyContentItem2">
                            <img alt='아이콘2'className="bcImg" src={icon2}/>
                            <div className='bcMainText'>메타버스의 내용이 현실의<br/>AR 증강으로 연동됩니다</div>
                            <div className='bcBlueText'>3D메모와 위치 포인팅을 통한 커뮤니케이션</div>
                            <div className='bcText'>3D Object에 메모를 남겨 작업자들 간의 history를 공유하고 위치 포인팅을 이용하여 자신이 보고 있는 위치를 공유해보세요.
                            MXSPACE는 증강 현실과 3D Object의 메모, 위치 포인팅이 연동되어 보여집니다.</div>
                        </div>
                        <div className="bodyContentItem" style={{justifyContent:'flex-end'}}>
                            <img alt='아이콘3'className="bcImg" src={icon3}/>
                            <div className='bcMainText'>PDF 형식의 각종 문서와<br/>MP4 영상 자료를 공유합니다</div>
                            <div className='bcBlueText'>파일 공유를 원하는 위치에 간편하게</div>
                            <div className='bcText'>업무나 교육에 필요한 영상, 문서, 사진 등을 MXSPACE 월드에 배치하여 참여자들에게 공유해보세요. 교육 환경과 업무 협의 공간을 간편하게 구축할 수 있습니다.</div>
                        </div>
                        <div className="bodyContentItem bodyContentItem2 bodyContentItem3 widePc">
                            <img alt='아이콘1'className="bcImg" src={icon1}/>
                            <div className='bcMainText'>메타버스 월드내에 3D 데이터를<br/>자유롭게 배치하여 활용가능합니다</div>
                            <div className='bcBlueText'>3D데이터를 직접 함께보는 느낌을 누려보세요</div>
                            <div className='bcText'>MXSPACE에서는 사용자가 다량의 CAD
설계 데이터를 월드에 공유하여 보여주는 것이 가능합니다. 3D 모델링 데이터 공유로 더욱
원활한 의사소통을 경험해 보세요.</div>
                        </div>
                        <div className="bodyContentItem bodyContentItem2 bodyContentItem3 ipadMinif">
                            <img alt='아이콘1'className="bcImg" src={icon1}/>
                            <div className='bcMainText'>메타버스 월드내에 3D 데이터를<br/>자유롭게 배치하여 활용가능합니다</div>
                            <div className='bcBlueText'>3D데이터를 직접 함께보는 느낌을 누려보세요</div>
                            <div className='bcText'>MXSPACE에서는 사용자가 다량의 CAD<br/>설계 데이터를 월드에 공유하여 보여주는 것이<br/>가능합니다. 3D 모델링 데이터 공유로 더욱<br/>원활한 의사소통을 경험해 보세요.</div>
                        </div>
                        <div className="bodyContentItem bodyContentItem2 wideMobilef">
                            <img alt='아이콘1'className="bcImg" src={icon1}/>
                            <div className='bcMainText'>메타버스 월드내에<br/>3D 데이터를 자유롭게<br/>배치하여 활용가능합니다</div>
                            <div className='bcBlueText'>3D데이터를 직접 함께보는 느낌을 누려보세요</div>
                            <div className='bcText'>MXSPACE에서는 사용자가 다량의 CAD
설계 데이터를 월드에 공유하여 보여주는 것이 가능합니다. 3D 모델링 데이터 공유로 더욱
원활한 의사소통을 경험해 보세요.</div>
                        </div>
                        
                </div>
            </div>


            <div className="bodySlideList">
                <div className="bodySlideRow">
                    <div className="bodySlideItem textItem2 bodySlideBg">
                        <div className='slideMainText widePc'>건설과 제조업의 대형 3D BIM data를 메타버스에서 활용합니다.</div>
                        <div className='slideMainText ipadMini'>건설과 제조업의 대형 3D BIM<br/>data를 메타버스에서 활용합니다.</div>
                        <div className='slideMainText wideMobile'>건설과 제조업의 대형<br/>3D BIM data를<br/>메타버스에서 활용합니다.</div>
                        <div className='slideBlueText widePc'>3D BIM data와 연동하여 디지털 트윈 공간을 만드세요</div>
                        <div className='slideBlueText ipadMini'>3D BIM data와 연동하여 디지털 트윈 공간을 만드세요</div>
                        <div className='slideBlueText wideMobile'>3D BIM data와 연동하여<br/>디지털 트윈 공간을 만드세요</div>
                        <div className='slideText'>건설, 제조 시공시 생산되는 설계 Bim data, 도면들을 MXSPACE에 구성하여 설계와 실제 시공 단계를 검토 및 모니터링하며 다양한 업무 혁신을 이뤄낼 수 있습니다. </div>
                    </div>
                    <div className="bodySlideItem Slideimg1">
                        <Carousel autoplay>
                            <div className='bodyImg'>
                                <img alt='슬라이드1_1' className="" src={slide1_1}/>
                            </div>
                            <div className='bodyImg'>
                                <img alt='슬라이드1_2' className="" src={slide1_2}/>
                            </div>
                        </Carousel>
                    </div>
                </div>
                <div className="bodySlideRow">
                    <div className="bodySlideItem Slideimg1">
                        <Carousel autoplay>
                            <div className='bodyImg'>
                                <img alt='슬라이드2_1' className="" src={slide2_1}/>
                            </div>
                            <div className='bodyImg'>
                                <img alt='슬라이드2_2' className="" src={slide2_2}/>
                            </div>
                        </Carousel>
                    </div>
                    <div className="bodySlideItem textItem2 bodySlideBg widePc">
                        <div className='slideMainText'>Online의 메타버스에서 3D 기기를 조작하면 Offline의 실제 기기가 제어됩니다. </div>
                        <div className='slideBlueText'>진정한 원격관리와 통제가 가능해집니다</div>
                        <div className='slideText'>Thingworx RTDB기반으로 실물기기를 원격 제어가 가능해집니다. 각 공장별 다양한 기기들을 커스텀  설정 후 메타버스 배치하여 사용 가능합니다. </div>
                    </div>
                    <div className="bodySlideItem textItem2 bodySlideBg ipadMinif">
                        <div className='slideMainText'>Online의 메타버스에서<br/>3D 기기를 조작하면<br/>Offline의 실제 기기가 제어됩니다. </div>
                        <div className='slideBlueText'>진정한 원격관리와 통제가 가능해집니다</div>
                        <div className='slideText'>Thingworx RTDB기반으로 실물기기를 원격 제어가<br/>가능해집니다 각 공장별 다양한 기기들을 커스텀<br/>설정 후 메타버스 배치하여 사용 가능합니다. </div>
                    </div>
                    <div className="bodySlideItem textItem2 bodySlideBg wideMobilef">
                        <div className='slideMainText slideMainText2'>Online의 메타버스에서<br/>3D 기기를 조작하면<br/>Offline의 실제 기기가 제어됩니다. </div>
                        <div className='slideBlueText'>진정한 원격관리와<br/>통제가 가능해집니다</div>
                        <div className='slideText'>Thingworx RTDB기반으로 실물기기를<br/>원격 제어가 가능해집니다. 각 공장별 다양한<br/>기기들을 커스텀 설정 후 메타버스 배치하여<br/>사용 가능합니다. </div>
                    </div>
                </div>
                <div className="bodySlideRow"> 
                    <div className="bodySlideItem textItem2 textItem3 bodySlideBg">
                        <div className='slideMainText widePc'>현실 공간의 다양한 컨셉을<br/>메타버스의 3D월드로 커스텀 제작이 가능합니다.</div>
                        <div className='slideMainText ipadMinif'>현실 공간의 다양한 컨셉을 메타버스의<br/>3D월드로 커스텀 제작이 가능합니다.</div>
                        <div className='slideMainText wideMobile'>현실 공간의 다양한 컨셉을<br/>메타버스의 3D월드로<br/>커스텀 제작이 가능합니다.</div>
                        <div className='slideBlueText widePc'>공장, 사무실, 강의실, 스튜디오 등 현실공간을 구현하세요</div>
                        <div className='slideBlueText ipadMinif'>공장, 사무실, 강의실, 스튜디오 등 현실공간을 구현하세요</div>
                        <div className='slideBlueText wideMobile'>공장, 사무실, 강의실, 스튜디오 등<br/>현실공간을 구현하세요</div>
                        <div className='slideText widePc'>기본 제공되는 3D월드 이외에 원하는 컨셉의 3D 공간을 커스텀 제작하여 메타버스 공간으로 활용할 수 있습니다. 메타포트 3D 카메라로 촬영한 데이터를 즉시 3D 월드 공간으로 생성 가능합니다.</div>
                        <div className='slideText ipadMinif'>기본 제공되는 3D월드 이외에 원하는 컨셉의 3D 공간을<br/>커스텀 제작하여 메타버스 공간으로 활용할 수 있습니다.<br/>메타포트 3D 카메라로 촬영한 데이터를 즉시 3D 월드<br/> 공간으로 생성 가능합니다.</div>
                        <div className='slideText slideText2 wideMobile'>기본 제공되는 3D월드 이외에 원하는 컨셉의<br/>3D 공간을 커스텀 제작하여 메타버스 공간으로<br/>활용할 수 있습니다. 메타포트 3D 카메라로 촬영한<br/>데이터를 즉시 3D 월드 공간으로 생성 가능합니다.</div>
                    </div>
                    <div className="bodySlideItem Slideimg1">
                        <Carousel autoplay>
                            <div className='bodyImg'>
                                <img alt='슬라이드3_1' className="" src={slide3_1}/>
                            </div>
                            <div className='bodyImg'>
                                <img alt='슬라이드3_2' className="" src={slide3_2}/>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
            <div className="communiContainer" ref={goodsTabs[2].element}>
                <div className="communiTitleGroup">
                    <div className="centerTitle EnFont">Communication</div>
                    <div className="centerText centerText3 flexCol widePc">MXSPACE안에서는 다수의 사람과<br/>다양한 형태로 커뮤니케이션이 가능합니다.</div>
                    <div className="centerText centerText3 flexCol ipadMinif">MXSPACE안에서는 다수의 사람과<br/>다양한 형태로 커뮤니케이션이 가능합니다.</div>
                    <div className="centerText centerText3 flexCol wideMobile">MXSPACE안에서는 다수의 사람과 다양한<br/>형태로 커뮤니케이션이 가능합니다.</div>
                </div>
                <div className="flexRow communiBody">
                    <div className="flexCol communicard">
                        <img className="" alt='커뮤니케이션 이미지1' src={comuniImg1}/>
                        <div className="communiTitle">양방향 커뮤니케이션 지원</div>
                        <div className="communiText">MXSPACE는 언제 어디서든  접속자들에게<br/>모든 음성, 개인/그룹별 대화 및<br/>문자 회의를 지원합니다. </div>
                    </div>
                    <div className="flexCol communicard">
                        <img className="" alt='커뮤니케이션 이미지2' src={comuniImg2}/>
                        <div className="communiTitle">다인 화상 회의</div>
                        <div className="communiText widePc">참여자들은 언제든지 화상 회의를 열어<br/> 
                        대화할 수 있습니다. MXSPACE의 화상 회의를 통해<br/> 협업자와의 의사소통 문제를 해결해보세요.</div>
                        <div className="communiText ipadMinif">참여자들은 언제든지 화상 회의를 열어<br/> 
                        대화할 수 있습니다. MXSPACE의 화상 회의를 통해<br/> 협업자와의 의사소통 문제를 해결해보세요.</div>
                        <div className="communiText wideMobile">참여자들은 언제든지 화상 회의를<br/>열어  
                        대화할 수 있습니다.<br/>MXSPACE의 화상 회의를 통해<br/>협업자와의 의사소통 문제를 해결해보세요.</div>
                    </div>
                    
                    <div className="flexCol communicard">
                        <img className="" alt='커뮤니케이션 이미지3' src={comuniImg3}/>
                        <div className="communiTitle">그룹 회의 기능</div>
                        <div className="communiText">목적에 맞는 특정 참여 인원만 선택하여<br/> 
                        그룹을 만들어 소통할 수 있습니다.</div>     
                    </div>
                </div> 
            </div>
            <div className="platformTitleWrapper" ref={goodsTabs[3].element}>
                <div className="platformTitleGroup">
                    <div className="centerTitle centerTitle4 EnFont ">a variety of platforms</div>
                    <div className="centerText centerText3  centerText4 flexCol">
                    모바일 및 PC 다양한 플랫폼에서 지원가능합니다.
                    </div>
                </div>
                <img alt='플랫폼 이미지1' className="plaform_Desk widePc" src={plaform_Desk}/>
                <img alt='플랫폼 이미지2' className="plaform_Desk ipadMini" src={plaform_tablet}/>
                <img alt='플랫폼 이미지3' className="plaform_Desk wideMobile" src={plaform_mobile}/>
            </div>

            <MXSPACEwebFooter showInquiryBtn={true}/>
            
        </div>
    )
}