import { MailOutlined, AppstoreOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as ROUTES from '../../../constants/routes';
import { getCurrentVersion } from '../../../_actions/SideMenuAction';

const SideUiLayout = styled.ul`
    margin: 0px;
    width: 256px;
    height: 40px;
    padding-left: 25px;
    list-style: none;
    line-height: 40px;
    list-style-position: inside;
    list-style-type: disc;
    box-sizing: border-box;
    font-variant: tabular-nums;
    text-align: left;
`;
const SideLiLayout = styled.li`
    margin: 0px;
    width: 256px;
    height: 45px;
    list-style: none;
    line-height: 40px;
    list-style-position: inside;
    padding-left: 50px;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    background-color: #fafafa;
    outline: none;
    cursor: pointer;
    a {
        color: rgba(0, 0, 0, 0.85);
    }
    a:hover {
        color: #1890ff;
    }
`;

export default function Sider() {
    const [currentVersion, setCurrentVersion] = useState();
    useEffect(() => {
        getCurrentVersion().then((res) => {
            setCurrentVersion(res.data);
        });
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                borderRight: '1px solid #f4f4f4',
            }}
        >
            <div>
                <img
                    src="/images/logo_mxspace.png"
                    style={{
                        width: '180px',
                        padding: '20px 5px 20px 5px',
                        backgroundColor: 'white',
                    }}
                    alt=""
                />

                <SideUiLayout>
                    <MailOutlined style={{ paddingRight: 10 }} /> 서비스 계정 관리
                </SideUiLayout>
                <SideLiLayout>
                    <Link to={ROUTES.ADMIN_REGISTCOMPANY_F}>신규 사용자 회사등록</Link>
                </SideLiLayout>
                <SideLiLayout>
                    <Link to={ROUTES.ADMIN_REGISTWAITLIST_F}>신규 회사 등록 관리</Link>
                </SideLiLayout>

                <SideLiLayout>
                    <Link to={ROUTES.ADMIN_MANAGECOMPANY_F}>계정 관리</Link>
                </SideLiLayout>
                {/* <SideLiLayout>
                    <Link to={ROUTES.ADMIN_SPACE_F}>스페이스 관리</Link>
                </SideLiLayout> */}
                <SideLiLayout>
                    <Link to={ROUTES.ADMIN_APPKEY_F}>AppKey 관리</Link>
                </SideLiLayout>
                {/* <SideLiLayout>
                <Link to={ROUTES.ADMIN_APPVERSION_F}>AppVersion 관리</Link>
            </SideLiLayout>*/}
                <SideLiLayout>
                    <Link to={ROUTES.ADMIN_LOBBYSETTING_F}>Lobby Setting</Link>
                </SideLiLayout>
                <SideLiLayout>
                    <Link to={ROUTES.ADMIN_MAPSETTING_F}>Map Setting</Link>
                </SideLiLayout>
                <SideLiLayout>
                    <Link to={ROUTES.ADMIN_MAPLIST_F}>Map List</Link>
                </SideLiLayout>

                <SideUiLayout>
                    <AppstoreOutlined style={{ paddingRight: 10 }} /> 모니터링
                </SideUiLayout>
                <SideLiLayout>
                    <Link to={ROUTES.ADMIN_DASHBOARD_F}>Dash board</Link>
                </SideLiLayout>
                <SideLiLayout>
                    <Link to={ROUTES.ADMIN_MANAGEGUEST_F}>게스트 관리</Link>
                </SideLiLayout>
                {/* <SideLiLayout>
                    <Link to={ROUTES.ADMIN_NOTICE_F}>공지 관리</Link>
                </SideLiLayout> */}
                <div style={{ position: 'fixed', bottom: '0', marginLeft: '5px' }}>
                    version {currentVersion}
                </div>
            </div>
        </div>
    );
}
