import { useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router';

import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { SearchBar, SearchBarColumns, PaginationTable } from 'components/elements';

import { DEFAULT_PAGE_SIZE, usePagination } from 'hooks';

import { RequestGetNoticeList, ResponseNoticeList, NoticeListDetail } from 'types/notice';

import { convertApiDate } from 'consts';

import { StyledHeaderTitle } from './styles';

export const Notice = () => {
    const navigate = useNavigate();

    const [noticeList, setNoticeList] = useState<ResponseNoticeList>({
        page: 0,
        size: DEFAULT_PAGE_SIZE,
        first: true,
        last: true,
        totalPages: 0,
        totalElements: 0,
        content: [],
    });

    const [requestGetNoticeList, setRequestGetNoticeList] = useState<RequestGetNoticeList>({
        page: 1,
        size: DEFAULT_PAGE_SIZE,
    });

    const searchData: SearchBarColumns[] = useMemo(() => {
        return [
            {
                key: 'searchDate',
                label: '등록일',
                type: 'date',
                span: 24,
            },
            {
                key: 'searchType',
                label: '검색 구분',
                type: 'select',
                span: 6,
                customProps: { style: { width: 100 } },
                initValue: 'TITLE',
                options: [
                    { label: '제목', value: 'TITLE' },
                    { label: '등록자', value: 'WRITER' },
                ],
            },
            {
                key: 'keyword',
                label: '검색어',
                disableLabel: true,
                type: 'input',
                span: 18,
                placeholder: '검색어를 입력해주세요.',
                customProps: { style: { width: 360 } },
            },
        ];
    }, []);

    const columns: ColumnsType<NoticeListDetail> = [
        {
            title: '제목',
            key: 'title',
            dataIndex: 'title',
            align: 'center' as 'center',
        },
        {
            title: '내용',
            key: 'contents',
            dataIndex: 'contents',
            align: 'center' as 'center',
        },
        {
            title: '공지 기간',
            key: 'exposeDate',
            dataIndex: 'exposeDate',
            align: 'center' as 'center',
        },
        {
            title: '등록 일시',
            key: 'createDate',
            dataIndex: 'createDate',
            align: 'center' as 'center',
        },
        {
            title: '등록자',
            key: 'writer',
            dataIndex: 'writer',
            align: 'center' as 'center',
        },
        {
            title: '상세보기',
            key: 'nid',
            align: 'center',
            render: (_, record) => (
                <Button onClick={() => navigate(`/admin/notice/edit/${record.nid}`)}>
                    상세보기
                </Button>
            ),
        },
    ];

    const onSearch = (values: any) => {
        const { searchDate, searchType, keyword } = values;
        setRequestGetNoticeList({
            ...requestGetNoticeList,
            ...(searchDate.value && {
                searchDateStarted: convertApiDate(searchDate.value[0]),
                searchDateEnded: convertApiDate(searchDate.value[1]),
            }),
            keyword: keyword.value,
            searchType: searchType.value,
            page: 1,
        });
    };

    const onSearchReset = () => {
        setRequestGetNoticeList({
            page: 1,
            size: requestGetNoticeList.size,
        });
    };

    const pagination = usePagination({
        total: noticeList.totalElements,
        page: requestGetNoticeList.page,
        pageSize: requestGetNoticeList.size,
        updateFilter: (page, size) => {
            setRequestGetNoticeList({ page, size });
        },
    });

    const handleGetNoticeList = useCallback((requestGetNoticeList: RequestGetNoticeList) => {
        console.log(requestGetNoticeList);
        //공지 리스트 api 조회 영역
        setNoticeList({
            first: false,
            last: true,
            page: 1,
            size: 10,
            totalElements: 1390,
            totalPages: 139,
            content: [
                {
                    nid: 1,
                    title: '서버 점검 안내',
                    contents: '서버 점검 진행으로 접속 불가합니다.',
                    exposeDate: '2024-04-01 09:00 ~ 2024-04-01 10:00',
                    createDate: '2024-04-01 08:00',
                    writer: 'admin',
                },
                {
                    nid: 2,
                    title: 'UpdateNote 1.3.17',
                    contents: 'hi',
                    exposeDate: '2024-04-01 09:00 ~ 2024-04-01 10:00',
                    createDate: '2024-04-01 08:00',
                    writer: 'admin',
                },
                {
                    nid: 3,
                    title: '임시 점검 안내',
                    contents: '점검으로 잠시 서비스 이용이 제한됩니다.',
                    exposeDate: '2024-04-01 09:00 ~ 2024-04-01 10:00',
                    createDate: '2024-04-01 08:00',
                    writer: 'admin',
                },
                {
                    nid: 4,
                    title: '업데이트 안내',
                    contents: '오전 9시부터 오전 10시까지 업데이트...',
                    exposeDate: '2024-04-01 09:00 ~ 2024-04-01 10:00',
                    createDate: '2024-04-01 08:00',
                    writer: 'admin',
                },
                {
                    nid: 5,
                    title: '서버 점검 안내',
                    contents: '서버 점검 진행으로 접속 불가합니다.',
                    exposeDate: '2024-04-01 09:00 ~ 2024-04-01 10:00',
                    createDate: '2024-04-01 08:00',
                    writer: 'admin',
                },
                {
                    nid: 6,
                    title: 'UpdateNote 1.3.17',
                    contents: 'hi',
                    exposeDate: '2024-04-01 09:00 ~ 2024-04-01 10:00',
                    createDate: '2024-04-01 08:00',
                    writer: 'admin',
                },
                {
                    nid: 7,
                    title: '임시 점검 안내',
                    contents: '점검으로 잠시 서비스 이용이 제한됩니다.',
                    exposeDate: '2024-04-01 09:00 ~ 2024-04-01 10:00',
                    createDate: '2024-04-01 08:00',
                    writer: 'admin',
                },
                {
                    nid: 8,
                    title: '업데이트 안내',
                    contents: '오전 9시부터 오전 10시까지 업데이트...',
                    exposeDate: '2024-04-01 09:00 ~ 2024-04-01 10:00',
                    createDate: '2024-04-01 08:00',
                    writer: 'admin',
                },
                {
                    nid: 9,
                    title: '임시 점검 안내',
                    contents: '점검으로 잠시 서비스 이용이 제한됩니다.',
                    exposeDate: '2024-04-01 09:00 ~ 2024-04-01 10:00',
                    createDate: '2024-04-01 08:00',
                    writer: 'admin',
                },
                {
                    nid: 10,
                    title: '업데이트 안내',
                    contents: '오전 9시부터 오전 10시까지 업데이트...',
                    exposeDate: '2024-04-01 09:00 ~ 2024-04-01 10:00',
                    createDate: '2024-04-01 08:00',
                    writer: 'admin',
                },
            ],
        });
    }, []);

    useEffect(() => {
        handleGetNoticeList(requestGetNoticeList);
    }, [requestGetNoticeList]);

    return (
        <div style={{ marginLeft: '2%' }}>
            <StyledHeaderTitle>공지 관리</StyledHeaderTitle>
            <SearchBar columns={searchData} onSearch={onSearch} onReset={onSearchReset} />
            <PaginationTable
                customRight={
                    <Button onClick={() => navigate(`/admin/notice/create`)}>공지 등록</Button>
                }
                pagination={pagination}
                columns={columns}
                showPageSize={true}
                dataSource={noticeList.content || []}
            />
        </div>
    );
};
