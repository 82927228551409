import axios from 'axios';

export async function uploadProfileImage(dataToSubmit: any, uid:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/user/profile/upload?uid=${uid}`, dataToSubmit);
}
 
export async function deleteProfileImage(uid:any) {
    return await axios.delete(`${process.env.REACT_APP_SERVER_BASE_URL}/user/profile/delete?uid=${uid}`);
}

export function getProfileImage(filehash: any, uid:number)  {
    return `${process.env.REACT_APP_SERVER_BASE_URL}/user/profile/${uid}`;
}