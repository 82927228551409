// libraries
import styled from 'styled-components';

export const StyledHeaderTitle = styled.h3`
    border-bottom: 2px solid red;
    margin-right: 65%;
    padding-bottom: 2px;
    font-weight: 700;
    margin-bottom: 50px;
`;

export const StyledNoticePopupWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    p {
        margin: 0;
    }

    .popupWrapper {
        height: fit-content;
        display: flex;
        gap: 10px;
    }

    .popupBorder {
        background: #fff;
        border-radius: 10px;
        min-width: 314px;
    }

    .title {
        font-family: Noto Sans KR;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        padding: 20px 50.5px 10px;
    }

    .contents {
        font-family: Noto Sans KR;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        padding: 10px 40px;
    }

    .popupCloseBtn {
        background: #00a9ff;
        color: #fff;
        font-family: Noto Sans KR;
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: -0.5px;
        border-radius: 0 0 10px 10px;
        padding: 15px 0;
        margin-top: 20px;
        cursor: pointer;
    }
`;
