const FILETYPE : { [key:string] : number[] } = {
    // 대분류
    // ---------- 중분류

    // 일반 3D
    "3d":[1000],

    "3d_Asset_Group":[1100],// ---------- 공유 3D 에셋 
    "3d_Asset_All":[1101, 1102, 1103, 1104, 1105, 1106, 1107, 1108],
    "3d_Asset_fbx":[1101], 
    "3d_Asset_obj":[1102],
    "3d_Asset_gltf2":[1103],
    "3d_Asset_gltf":[1103],
    "3d_Asset_stl":[1104],
    "3d_Asset_ply":[1105],
    "3d_Asset_3mf":[1106],
    "3d_Asset_glb":[1107],
    "3d_Asset_zip":[1108],

    // 일반 2D
    "2d":[2000],
    "2d_Doc_Group":[2100], // ---------- 공유 문서 파일
    "2d_Doc_pdf":[2101],
    
    "2d_Video_Group":[2200], // ---------- 공유 동영상 파일
    "2d_Video_mp4":[2201],
    
    "2d_Image_Group":[2300], // ---------- 공유 이미지 파일
    "2d_Image_All":[2301, 2302, 2303],

    "2d_Image_jpg":[2301],
    "2d_Image_jpeg":[2302],
    "2d_Image_png":[2303],
    
    "2d_Profile_Group":[2400], // ---------- 프로필 이미지 파일
    "2d_Profile_jpg":[2401],
    "2d_Profile_jpeg":[2402],
    "2d_Profile_png":[2403],

    "map_thumbnail_Group":[2500], // ---------- 맵썸네일 이미지 파일
    "map_thumbnail_jpg":[2501],
    "map_thumbnail_jpeg":[2502],
    "map_thumbnail_png":[2503],

    // 앱 리소스
    "app":[3000],
    
    "app_And_Group":[3100], // ---------- 안드로이드 리소스
    "app_And_mtz":[3101],

    // 로비 관리
    "lobby":[4000],
    
    "lobby_BannerVideo_Group":[4100], // ---------- 배너 동영상 파일
    "lobby_BannerVideo_mp4":[4101],
    
    "lobby_BannerImage_Group":[4200], // ---------- 배너 이미지 파일
    "lobby_BannerImage_jpg":[4201],
    "lobby_BannerImage_jpeg":[4202],
    "lobby_BannerImage_png":[4203],

    "lobby_3DBunndle_Group":[4300], // ---------- 배너 3D 번들 파일
    "lobby_3DBunndle_bunddle":[4301],

    // LGES 진척도
    "lges":[3000],
    
    "lges_Progress_Group":[3100], // ---------- 진척도 표출 동영상
    "lges_Progress_mp4":[3101],
}

const FILEEXTENTION : { [key:string] : string[] } = {

    "fileUpload_3d":[".FBX", ".OBJ", "GLTF", "GLTF2", ".STL", ".PLY", ".3MF", ".GLB", ".ZIP"],
    "fileUpload_2d":[".PDF", ".MP4", "JPEG", ".PNG", ".JPG"],
    "fileUpload_doc":[".PDF"],
    "fileUpload_video":[".MP4"],
    "fileUpload_image":["JPEG", ".PNG", ".JPG"]

}

export {FILETYPE, FILEEXTENTION};