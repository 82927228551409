import React, { useState, useCallback, useEffect } from 'react';
import { loginUser } from '../../../_actions/UserAction'; 
import { useNavigate } from "react-router-dom";
import { Button, Input } from 'antd';
import { useDispatch } from "react-redux";
import { alertMessage } from '../../utils/utils'
import * as ROUTES from "../../../constants/routes"
import { useSelector } from 'react-redux';
import { RootState } from '../../../_reducers';
import AUTH from '../../../constants/auth';

function Login(props: { history: string[]; }) {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [userid, setUserid] = useState("")
    const [password, setpassword] = useState("")
    const loginUserCallback = useCallback(async (body : any) => dispatch(await loginUser(body)),
    [dispatch]
    );
    const {userData, loginStatus} = useSelector((state : RootState) => state.userReducer);

    const loginHeaderStyle = {
        display: "flex", 
        height: "45px", 
        paddingLeft: "20px",
        justifyContent:"flex-start",
        alignContent: "center",
        alignItems: "center",
        borderBottom: "1px solid #DBDBDB",
        color: "#8F8F8F"
    }

    const loginBodyStyle = {
        display: 'grid', 
        gridTemplateRows: '50px, [1fr], 50px',
        justifyContent: 'center', 
        alignContent: "center",
        width: '100%', 
        height: 'calc(100vh - 100px)', 
    }

    const loginInputStyle = {
        background: "#F5F5F5",
        borderRadius: "10px",
        marginTop: "10px",
        height: "45px"
    }

    const loginButtonStyle = {
        background: "#2264FF",
        borderRadius: "10px",
        height: "45px"
    }

    const loginImageStyle = {
        width: "50%",
        marginBottom: "10px",
        alignSelf: "end",
        justifySelf: "center"
    }

    const onUseridHandler = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setUserid(event.currentTarget.value)
    }

    const onPasswordHandler = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setpassword(event.currentTarget.value)
    }

    const onSubmitHandler = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        
        let body = {
            userid: userid,
            password: password
        }
        
        loginUserCallback(body).then(((response:any)=>{
            if(response.payload.loginStatus){
                if(response.payload.userData.grade >= AUTH.indexOf("사용자관리자")){
                    navigate(ROUTES.USER_USERLIST_F)
                } else if(response.payload.userData.grade <= AUTH.indexOf("서브관리자")){
                    navigate(ROUTES.USER_APPLINK_F)
                } else {
                    alertMessage("관리자에게 문의하세요.");  
                }
            }
        }))
    }
    useEffect(() => {
        if(loginStatus && userData.grade >= AUTH.indexOf("사용자관리자")){
            navigate(ROUTES.USER_USERLIST_F)
        } else if(loginStatus && userData.grade <= AUTH.indexOf("서브관리자")){
            navigate(ROUTES.USER_APPLINK_F)
        }
    }, [userData])
    return (
        <>
            <div style={loginHeaderStyle}>
                <img src="/images/logo_mxspace2.png" style={{height: "22px"}} alt="" />
                <span style={{marginLeft: "10px", fontWeight: "400"}}>Meta eXperience Space</span>
            </div>
            <div style={loginBodyStyle}>
                <div style={{textAlign: "center"}}>
                    <img src="/images/logo_mxspace.png" style={loginImageStyle} alt="" />

                    <form style={{ display: 'flex', flexDirection: 'column', padding: '0 7% 0' }}
                        onSubmit={onSubmitHandler}
                    >
                        <Input  type="text" value={userid} onChange={onUseridHandler} 
                            placeholder="아이디" style={loginInputStyle} />
                        <Input  type="password" value={password} onChange={onPasswordHandler} 
                            placeholder="비밀번호" style={loginInputStyle} />
                        <br />
                        <Button type="primary" htmlType="submit" style={loginButtonStyle}>
                            로그인
                        </Button>
                    </form>
                </div>
            </div>
            <div style={{textAlign: 'center'}}>
                    <img src="/images/ui_logo@3x.png" style={{height: "20px", alignSelf: "start"}} alt=""/>
            </div>

        </>
    )
}

export default Login 