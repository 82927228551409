export enum DateRange {
    ALL = 'ALL',
    SELECT = 'SELECT',
    TODAY = 'TODAY',
    RECENT_THREE_DAYS = 'RECENT_THREE_DAYS',
    RECENT_WEEK = 'RECENT_WEEK',
    RECENT_TWO_WEEK = 'RECENT_TWO_WEEK',
    RECENT_MONTH = 'RECENT_MONTH',
    RECENT_THREE_MONTH = 'RECENT_THREE_MONTH',
    RECENT_SIX_MONTH = 'RECENT_SIX_MONTH',
    RECENT_ONE_YEAR = 'RECENT_ONE_YEAR',
    DEFAULT = 'DEFAULT',
}

export const DateRangeMethods = {
    getLabel: (key: string) => {
        switch (key) {
            case DateRange.ALL:
                return '전체';
            case DateRange.SELECT:
                return '기간 설정';
            case DateRange.TODAY:
                return '오늘';
            case DateRange.RECENT_THREE_DAYS:
                return '최근 3일';
            case DateRange.RECENT_WEEK:
                return '최근 7일';
            case DateRange.RECENT_TWO_WEEK:
                return '최근 14일';
            case DateRange.RECENT_MONTH:
                return '최근 1개월';
            case DateRange.RECENT_THREE_MONTH:
                return '최근 3개월';
            case DateRange.RECENT_SIX_MONTH:
                return '최근 6개월';
            case DateRange.RECENT_ONE_YEAR:
                return '최근 1년';
            default:
                return '';
        }
    },
};
