export const DEFAULT_PAGE_SIZE = 10;

export interface usePaginationProps {
    total: number;
    page?: number;
    pageSize?: number;
    updateFilter?: (page: number, pageSize: number) => void;
}

export const usePagination = ({
    total = 0,
    page = 1,
    pageSize = DEFAULT_PAGE_SIZE,
    updateFilter,
}: usePaginationProps) => {
    return {
        total: total || 0,
        current: parseInt(`${page}`),
        pageSize: parseInt(`${pageSize}`),
        showSizeChanger: false,
        onChange: (_page: number, _pageSize: number) => {
            updateFilter ? updateFilter(_page, _pageSize) : console.log("");
        },
        onShowSizeChange: (_page: number, _pageSize: number) => {
            updateFilter ? updateFilter(_page, _pageSize) : console.log("");
        },
    };
};
