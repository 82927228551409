import { useEffect, useState } from 'react';
import { Modal, Input, Button, Upload, Select, Checkbox } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import styled from "styled-components";
import { MapType } from './MapSetting';
import { alertMessage, infoMessage } from "../../utils/utils";
import { editCompanyMap } from '../../../_actions/MapAction'
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import CODE from '../../../constants/code';
// import { fileObject } from '../../../file/FileManage';

const { Option } = Select;

const ModalBodyLayout = styled.div`
    display: grid; 
    grid-template-columns: 25% auto; 
    grid-template-rows: 50px 200px 120px 70px;
    align-items: center;
`;

const FlexCenterLayout = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
const FlexCenterLayoutColumn = styled.div`
    display: flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items: flex-start;
    form{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
`;
export default function MapSettingEditModal(props:any) {
    const [isModalVisible, setIsModalVisible] = useState(true);
    const selectedMapList:MapType[] = [...props.selectedCompanyMapList.maplist, ...props.selectedCompanyMapList.restMapList];
    const [selectedMapId, setSelectedMapId] = useState(-1);
    const [selectedMapItem, setSelectedMapItem] = useState({} as MapType);
    const [backupItem, setBackupItem] = useState({} as MapType);
    let {mapid, order, status, assign,
        mapname, thumbnail, thumbnail_p
    } = selectedMapItem
    useEffect(()=>{
        if(selectedMapId>-1){
            let temp = selectedMapList.slice()
            temp = temp.filter(c => c.mapid === selectedMapId)
            setSelectedMapItem(temp[0])
            setBackupItem(temp[0])
        }
    }, [selectedMapId])

    const handleCancel = (changedData : any) => {
        setIsModalVisible(false);
        props.closeEditModal(changedData);
    };
    const onCheckFunc = (e:CheckboxChangeEvent) => {   
        setSelectedMapItem({...selectedMapItem, assign : e.target.value})  
      };
    const MapItemToFileInfo = (type:string) => {
         let env = `${process.env.REACT_APP_SERVER_BASE_URL}`
         let fileList :UploadFile<any>[] = type==='none' ? 
         [{
            uid: `-1`,
            name: `${mapname}`,
            status: 'error'        }]
         : [{
            uid: `-1`,
            name: `${mapname}`,
            status: type==='none' ? 'error' : 'done',
            url : `${type==='aos' ? env+'/'+thumbnail_p : type==='win' ? env+'/'+thumbnail : ''}`
        }]
            return fileList
    }
const handleOk = () => {
    if(mapid !== undefined && backupItem.assign !== assign){
        const dataToSubmit = {
            "companyuid": props.selectedCompanyMapList.companyuid,
            "mapid" : mapid,
            "order" : order,
            "assign" : assign
        }
        editCompanyMap(dataToSubmit)
        .then((res:any)=>{
            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                infoMessage('수정 성공')
                props.changeReload()
                handleCancel({companyuid : props.selectedCompanyMapList.companyuid, mapid, assign})
            }
        })
    } else {
        handleCancel(false)
    }

    
};
    return (
        <>
        <Modal title="기본 룸 Setting" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}
            footer={[
                <Button key="ok" onClick={handleOk}>
                    OK
                </Button>
            ]}>
            <ModalBodyLayout>
                <div>MAP 이름:</div>
                <div>
                    <Select style={{ width: '100%'}} placeholder="선택해주세요" onChange={(value)=>setSelectedMapId(value)}>
                    {selectedMapList.length>0 && selectedMapList.map((item:any)=>{
                        // console.log(item)
                       return (<Option key={item.mapid} value={item.mapid} >
                        {item.mapname}
                        </Option>)
                    })}
                    </Select>
                </div>
                {selectedMapId >-1 && // 
                <>
                <div>MAP 썸네일 :<br/>(AOS)</div>
                <FlexCenterLayoutColumn>
                    <Upload    
                        listType="picture-card"
                        fileList={thumbnail_p ==='' || thumbnail_p === undefined ? MapItemToFileInfo('none') : MapItemToFileInfo('aos')}
                        showUploadList={{showRemoveIcon : false, showPreviewIcon : false}}
                        >
                    </Upload>
                </FlexCenterLayoutColumn>
                <div>MAP 썸네일 :<br/>(Windows)</div>
                <FlexCenterLayoutColumn>
                    <Upload    
                        listType="picture-card"
                        fileList={thumbnail ==='' || thumbnail === undefined ? MapItemToFileInfo('none') : MapItemToFileInfo('win')}
                        showUploadList={{showRemoveIcon : false, showPreviewIcon : false}}
                        >
                    </Upload>
                </FlexCenterLayoutColumn>
                <div>MAP 인덱스:</div>
                <FlexCenterLayout>
                    <div style={{width: "80%"}}>
                        <Input type='text' value={mapid} readOnly/>
                    </div>
                </FlexCenterLayout>
                <div>state: </div>
                <FlexCenterLayout>
                {[{label : '할당', value : 1}, {label : '비할당', value : 0}].map((item) => {
                            return (
                            <div className="col-sm-12 px-3 py-2 InquiryCheckboxItem" key={item.value}>
                                <Checkbox
                                key={item.value}
                                onChange={onCheckFunc}
                                checked={item.value === assign}
                                value={item.value}
                                disabled={!!status}
                                >
                                {item.label}
                                </Checkbox>
                                
                            </div>
                            );
                        })}
                {!!status && <span>기본 맵입니다.</span>}
                </FlexCenterLayout>
                </>}
            </ModalBodyLayout>
        </Modal>
        </>
    );
};