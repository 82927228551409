import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import HeaderUsers from './layout/HeaderUsers';
import SiderUsers from './layout/SideMenuUsers';
import Footer from '../Footer';

import ProtectedRoutes from './ProtectedUsersRoutes';
import UserList from './userManage/UserList';
import GroupList from './userGroup/GroupList';

import * as ROUTES from '../../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../_reducers';
import { useCallback, useEffect, useState } from 'react';
import { menuVisibleOption } from '../../_actions/SideMenuAction';
import axios from 'axios';
import CODE from '../../constants/code';
import { alertMessage } from '../utils/utils';

import Main from './UserMain';
import AUTH from '../../constants/auth';
import UserManage from './userManage/UserManage';
import AppLink from './appLink/AppLink';
import { logoutUser } from '../../_actions/UserAction';
import ModelFileManage from './modelFileManage/ModelFileManage';

const BodyLayout = styled.div`
    display: grid;
    grid-template-rows: 50px auto 50px;
    width: 100%;
    height: 100vh;
    overflow: hidden;
`;
const MainLayout = styled.div`
    padding: 1%;
    overflow-y: auto; // 2022.11.24 dev/pwj_videoManaging 영상관리 페이지 스크롤 위해서 추가
`;

export default function HomeUsers() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const logoutUserCallback = useCallback(async () => dispatch(await logoutUser()), [dispatch]);
    axios.interceptors.response.use(
        function (response) {
            // 응답 200번대 status일 때 응답 성공 직전 호출
            // 3. 이 작업 이후 .then()으로 이어진다
            return response;
        },
        function (error) {
            if (error.response && error.response.status) {
                switch (error.response.status) {
                    case Number(CODE.RCV_UNAUTHORIZED):
                        if (uid > -1) logoutUserCallback();
                        navigate(ROUTES.USER_LOGINPAGE);
                        break;
                    case 403:
                        if (uid > -1) logoutUserCallback();
                        navigate(ROUTES.USER_LOGINPAGE);
                        break;
                }
            }
            return Promise.reject(error);
        }
    );

    const { userData } = useSelector((state: RootState) => state.userReducer);
    const { uid, grade } = userData;
    const [menuVisibleList, setMenuVisibleList] = useState<any>([]);
    useEffect(() => {
        if (uid > 0) {
            menuVisibleOption().then((res) => {
                setMenuVisibleList(res.data.visibleOptions);
            });
        }
    }, []);

    //1. 영상 관리
    const [isMapVideoAllowed, setIsMapVideoAllowed] = useState<boolean>(false);
    //2. 비밀번호 리셋
    const [isPwResetAllowed, setIsPwResetAllowed] = useState<boolean>(false);
    //한기대 전용 메뉴 세팅
    const [koreatechMenu, setKoreatechMenu] = useState<boolean>(true);

    useEffect(() => {
        if (menuVisibleList.length > 0) {
            for (let i = 0; i < menuVisibleList.length; i++) {
                // 서버에서 사용자 관리자만 200 넘어오도록 추가
                if (menuVisibleList[i].type === 201)
                    setIsMapVideoAllowed(!!Number(menuVisibleList[i].value)); //1. 영상 관리
                if (menuVisibleList[i].type === 202)
                    setIsPwResetAllowed(!!Number(menuVisibleList[i].value)); //2. 비밀번호 리셋 // 현재 계정 관리 페이지가 계정등급:관리자에게만 오픈
                if (menuVisibleList[i].type === 220)
                    setKoreatechMenu(!!Number(menuVisibleList[i].value))//앱링크, 파일관리 메뉴 숨김처리 - 한기대용
            }
        }
    }, [menuVisibleList]);
    return (
        // A, B, C 체크

        <div style={{ display: 'flex' }}>
            {/* ---------------------- A. 사이드메뉴 control 필요*/}
            <SiderUsers isMapVideoAllowed={isMapVideoAllowed} koreatechMenu={koreatechMenu}/>
            <BodyLayout>
                <HeaderUsers />
                <MainLayout>
                    {/* ---------------------- B. 페이지 진입 control 필요*/}
                    <Routes>
                        {/* 유효한 계정 && 사용자 관리자 계정 */}
                        <Route
                            element={
                                <ProtectedRoutes
                                    isAllowed={grade >= AUTH.indexOf('사용자관리자')}
                                />
                            }
                        >
                            <Route
                                path={ROUTES.USER_USERLIST}
                                element={<UserList isPwResetAllowed={isPwResetAllowed} />}
                            />

                            <Route path={ROUTES.USER_GROUPLIST} element={<GroupList />} />
                        </Route>
                        {/* 유효한 계정*/}
                        <Route element={<ProtectedRoutes isAllowed={true} />}>
                            <Route path={ROUTES.USER_APPLINK} element={<AppLink />} />
                            <Route
                                path={ROUTES.USER_MODELFILEMANAGE}
                                element={<ModelFileManage />}
                            />
                        </Route>
                        {/* 유효한 계정 && 영상 관리 메뉴가 허용된 계정*/}
                        <Route element={<ProtectedRoutes isAllowed={isMapVideoAllowed} />}>
                            {/* <Route path={ROUTES.USER_MAPVIDEO_MANAGING} element={<MapVideo />} /> */}
                        </Route>
                        {/* ---------------------- C. grade 별로 페이지 진입 control 필요*/}
                        {/* <Route path="/*" element={<Navigate replace to={grade >= AUTH.indexOf("사용자관리자") ? ROUTES.USER_USERLIST_F : ROUTES.USER_APPLINK_F}/>} /> */}
                        <Route path="/*" element={<Main />} />
                    </Routes>
                </MainLayout>
                <Footer />
            </BodyLayout>
        </div>
    );
}
