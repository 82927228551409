// libraries
import styled from 'styled-components';
import { Button } from 'antd';

export const StyledSearchBarWrapper = styled.div`
    width: 1200px;
    .ant-form {
        position: relative;

        > div {
            align-items: center;
        }

        &-item {
            margin-bottom: 0;
        }
    }
`;

export const StyledSearchbarLabel = styled.div`
    display: flex;
    align-items: center;
    margin-right: 2rem;
    color: #1e1e1e;
    font-weight: 700;
    min-width: 7rem;
`;

export const StyledSearchbarContent = styled.div`
    width: 100%;

    .ant-form label {
        font-size: 1.3rem;
    }
    .ant-checkbox-wrapper {
        font-size: 1.3rem;
    }
`;

export const StyledSearchDatePickerWrapper = styled.div`
    display: flex;

    .ant-picker {
        border: 0;
        border-bottom: 1px solid #ddd;
        box-shadow: unset !important;
        height: 32px;
        line-height: 1;
    }
`;
