export const checkResultCodeSuccess = (rc:any) => {
    return !!(Number(rc) === 200 )
};
export type AdminData = {
    loginStatus : boolean,
    adminData : {
        uid: number,
        adminid: String,
        grade:number
    }
}
export type UserData = {
        loginStatus : boolean,
        userData : {
            uid: number,
            companyuid: number,
            licenseuid: number,
            userid: String,
            nickname: String,
            gender: number,
            grade: number,
            profileimage: String,
            usertype: number,
            createdate: String,
            updatedate: String
        }
}
export type CompanyData = {
    isCheck : boolean
}
//MENU
export type SidemenuData = {isChanged : boolean}
export const PAGEMOVE_SUCCESS = 'PAGEMOVE_SUCCESS';
export const PAGEMOVE_FAIL = 'PAGEMOVE_FAIL';

//USER
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_AUTHENTICATION = "USER_AUTHENTICATION";
export const USER_REGISTER = "USER_REGISTER";

//AUTH USER
export const AUTH_USER = "AUTH_USER";
export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";
export const ADMIN_REGISTER= "ADMIN_REGISTER";
export const ADMIN_AUTH= "ADMIN_AUTH";
export const ADMIN_AUTHENTICATION= "ADMIN_AUTHENTICATION";

//FILE
export const LIST_FILE = "LIST_FILE"

//COMPANY
export const COMPANY_CHECK_TEMPORARY_REGISTER = "COMPANY_CHECK_TEMPORARY_REGISTER";


