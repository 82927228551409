// base
import React, { useEffect, useRef, useState } from 'react';

// packages
import moment from 'moment';

// libraries
import { DatePicker, Segmented } from 'antd';

// enums
import { DateRange } from 'enums';

// styles
import { StyledSearchDatePickerWrapper } from './styles';

type MomentDateRange = [moment.Moment, moment.Moment];

export interface SearchDatePickerProps {
    value?: MomentDateRange;
    onChange?: (value: any) => void;
    isPopupPage?: boolean;
    className?: string;
    isStatistics?: boolean;
}

export const SearchDatePicker = (props: SearchDatePickerProps) => {
    const { value, onChange, className, isStatistics } = props;
    const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(DateRange.SELECT);

    const container = useRef<HTMLDivElement>(null);

    const handleChange = (values: MomentDateRange) => {
        values[0] = values[0].startOf('days');
        values[1] = values[1].endOf('days');
        if (onChange) {
            onChange(values);
        }
    };

    const handleChangeShortcut = (key: DateRange) => {
        setSelectedDateRange(key);

        let started: moment.Moment | undefined = moment()
            .add(isStatistics ? -1 : 0, 'days')
            .startOf('days');
        let ended: moment.Moment | undefined = moment()
            .add(isStatistics ? -1 : 0, 'days')
            .endOf('days');

        if (onChange) {
            switch (key) {
                case DateRange.ALL:
                    started = undefined;
                    ended = undefined;
                    break;
                case DateRange.SELECT:
                    started = undefined;
                    ended = undefined;
                    break;

                case DateRange.RECENT_WEEK:
                    started.subtract(7, 'days');
                    break;

                case DateRange.RECENT_TWO_WEEK:
                    started.subtract(14, 'days');
                    break;

                case DateRange.RECENT_MONTH:
                    started.subtract(30, 'days');
                    break;
                default:
                    break;
            }

            if (!started && !ended) {
                onChange(undefined);
            } else {
                onChange([started, ended]);
            }
        }
    };

    useEffect(() => {
        handleChangeShortcut(DateRange.SELECT);
    }, []);

    return (
        <StyledSearchDatePickerWrapper>
            <div ref={container}>
                <DatePicker.RangePicker
                    className={className}
                    value={value}
                    onChange={(values) => handleChange(values as MomentDateRange)}
                    placeholder={['시작일', '종료일']}
                    format="YYYY.MM.DD"
                    disabled={selectedDateRange !== DateRange.SELECT}
                />
            </div>
            <div style={{ marginLeft: '1rem' }}>
                <ShortCutComponent
                    isStatistics={isStatistics}
                    handleChangeShortcut={handleChangeShortcut}
                />
            </div>
        </StyledSearchDatePickerWrapper>
    );
};

const ShortCutComponent: React.FC<{
    isStatistics?: boolean;
    handleChangeShortcut(key: DateRange): void;
}> = ({ isStatistics, handleChangeShortcut }) => {
    if (isStatistics) {
        return null;
    }

    return (
        <Segmented
            onChange={(e) => handleChangeShortcut(e as DateRange)}
            options={[
                { label: '기간 설정', value: DateRange.SELECT },
                { label: '전체', value: DateRange.ALL },
                // { label: '오늘', value: DateRange.TODAY },
                // {
                //     label: '최근 7일',
                //     value: DateRange.RECENT_WEEK,
                // },
                // {
                //     label: '최근 14일',
                //     value: DateRange.RECENT_TWO_WEEK,
                // },
                // {
                //     label: '최근 30일',
                //     value: DateRange.RECENT_MONTH,
                // },
            ]}
        />
    );
};
