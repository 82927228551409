import { useCallback, useEffect, useState } from 'react';

import { Modal, Input, Table, Descriptions, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import type { TableRowSelection } from 'antd/lib/table/interface';

import CODE from '../../../constants/code';

import { alertMessage, confirmMessage, infoMessage } from '../../utils/utils';

import { deleteGroup, editGroup, getDetailGroup } from '../../../_actions/FuncGroupAction';

import { TableLayout } from './style';

export interface GroupDetailType {
    funcgroupname: string;
    createdate: string;
    featureStatus: {
        idx: number;
        feature: string;
        permit: number;
    }[];
    funcgroupmember: {
        uid: number;
        userid: string;
        nickname: string;
        status: number;
    }[];
}

export default function GroupEditModal(props: any) {
    const { groupId, openModal, closeModal, refreshGroupList } = props;

    const [groupData, setGroupData] = useState<GroupDetailType>({
        funcgroupname: '',
        createdate: '',
        featureStatus: [],
        funcgroupmember: [],
    });

    const [groupRowKeys, setGroupRowKeys] = useState<React.Key[]>([]);
    const [userRowKeys, setUserRowKeys] = useState<React.Key[]>([]);

    const groupColumns: ColumnsType<any> = [
        {
            title: '그룹 기능 목록',
            key: 'feature',
            dataIndex: 'feature',
            align: 'center' as 'center',
        },
    ];

    const userColumns: ColumnsType<any> = [
        {
            title: '유저 리스트',
            key: 'uid',
            dataIndex: 'uid',
            align: 'center' as 'center',
            render: (_, record) => {
                return (
                    <>
                        {record.nickname}&nbsp;&nbsp; {record.userid}
                    </>
                );
            },
        },
    ];

    const onSelectChange = (type: string, newSelectedRowKeys: React.Key[]) => {
        type === 'group' ? setGroupRowKeys(newSelectedRowKeys) : setUserRowKeys(newSelectedRowKeys);
    };

    const groupSelection: TableRowSelection<GroupDetailType> = {
        selectedRowKeys: groupRowKeys,
        onChange: (e) => {
            onSelectChange('group', e);
        },
    };

    const userSelection: TableRowSelection<any> = {
        selectedRowKeys: userRowKeys,
        onChange: (e) => {
            onSelectChange('user', e);
        },
    };

    const updateForm = useCallback(
        (data: any) => {
            setGroupData({
                ...groupData,
                ...data,
            });
        },
        [groupData]
    );

    const handleDeleteGroup = () => {
        confirmMessage(`${groupData.funcgroupname}(을)를 삭제하시겠습니까?`, 'danger', () => {
            deleteGroup(groupId).then((res) => {
                if (Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)) {
                    infoMessage(`${groupData.funcgroupname}를 삭제했습니다.`);
                    closeModal();
                    refreshGroupList();
                } else {
                    alertMessage(res.data.resultMessage || '잠시 후 다시 시도해주세요.');
                }
            });
        });
    };

    const handleEditGroup = () => {
        const updatedFeatureStatus = groupData.featureStatus.map((feature) => ({
            idx: feature.idx,
            permit: groupRowKeys.includes(feature.idx) ? 1 : 0,
        }));

        const updatedGroupData = {
            fid: Number(groupId),
            funcgroupname: groupData.funcgroupname,
            featureStatus: updatedFeatureStatus,
            funcgroupmember: userRowKeys,
        };

        editGroup(updatedGroupData).then((res) => {
            if (Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)) {
                infoMessage(`${updatedGroupData.funcgroupname}(이)가 수정 되었습니다.`);
                closeModal();
                refreshGroupList();
            } else {
                alertMessage(res.data.resultMessage || '잠시 후 다시 시도해주세요.');
            }
        });
    };

    const getDetailGroupData = () => {
        getDetailGroup(groupId).then((res) => {
            if (Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)) {
                const permittedFeatureIdx = res.data.featureStatus
                    .filter((feature: any) => feature.permit === 1)
                    .map((feature: any) => feature.idx);

                setGroupRowKeys(permittedFeatureIdx);

                const activeMembersUid = res.data.funcgroupmember
                    .filter((member: any) => member.status === 1)
                    .map((member: any) => member.uid);

                setUserRowKeys(activeMembersUid);

                setGroupData(res.data);
            } else {
                alertMessage(res.data.resultMessage || '잠시 후 다시 시도해주세요.');
            }
        });
    };

    useEffect(() => {
        if (groupId) {
            getDetailGroupData();
        }
    }, [groupId]);

    return (
        <Modal
            title="그룹 수정"
            maskClosable={false}
            open={openModal}
            onCancel={closeModal}
            width={860}
            footer={[
                <Button key="submit" type="primary" onClick={handleEditGroup}>
                    수정
                </Button>,
                <Button key="back" onClick={handleDeleteGroup}>
                    그룹 삭제
                </Button>,
            ]}
        >
            <Descriptions column={4}>
                <Descriptions.Item label="그룹 명" span={4}>
                    <Input
                        value={groupData.funcgroupname || ''}
                        onChange={(e) => updateForm({ funcgroupname: e.target.value })}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="그룹 인원" span={1}>
                    <p>{`${userRowKeys.length} / 150`}</p>
                </Descriptions.Item>
                <Descriptions.Item label="그룹 생성 일자" span={3}>
                    <p>{groupData.createdate}</p>
                </Descriptions.Item>
            </Descriptions>
            <TableLayout>
                <Table
                    className="funcTable"
                    style={{ width: 400 }}
                    pagination={false}
                    scroll={{
                        y: 470,
                    }}
                    rowSelection={groupSelection}
                    columns={groupColumns}
                    dataSource={groupData.featureStatus}
                    rowKey={(render) => render.idx}
                />
                <Table
                    className="userTable"
                    pagination={false}
                    style={{ width: 400 }}
                    scroll={{
                        y: 470,
                    }}
                    rowSelection={userSelection}
                    columns={userColumns}
                    dataSource={groupData.funcgroupmember}
                    rowKey={(render) => render.uid}
                />
            </TableLayout>
        </Modal>
    );
}
