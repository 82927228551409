import axios from 'axios';
import {ADMIN_LOGIN, ADMIN_LOGOUT, checkResultCodeSuccess} from './types';
import { initialAdminDataState } from '../_reducers/AdminReducer';

export async function loginAdmin( dataToSubmit: any ) {
    const req = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/auth/login`, dataToSubmit);
    return {
        type : ADMIN_LOGIN,
        payload : {adminData : req.data.admin || initialAdminDataState , loginStatus : checkResultCodeSuccess(req.data.resultCode)}
    }
}
export async function logoutAdmin(){
    await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/auth/logout`);
    return {
        type : ADMIN_LOGOUT
    }
}

export async function getDashboard() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/monitoring/dashboard`);
}