import axios from 'axios';

export async function checkCompanyData(data: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/regist/checkCompanyData`, data);
}
export async function reqRegistCompany(data: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/regist/reqRegistCompany`, data);
}
export async function confirmCompanyName(data: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/regist/confirmName`, data);
}
export async function registCompany(data: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/regist/registCompany`, data);
}
export async function getRegistWaitList() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/manage/registWaitList`);
}
export async function getCompanyList() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/manage/companyList`);
}
export async function getCompanyData(campanyuid: string) {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/manage/companyData/`+ campanyuid);
}
export async function editCompany(companyuid: string, dataToSubmit: any ) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/manage/editCompany/`+ companyuid, dataToSubmit);
}
export async function getCompanyFeature(companyuid: string ) {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/manage/getFeatureList/${companyuid}`);
}
export async function updateCompanyFeature(dataToSubmit: any, companyuid: any ) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/manage/update/companyFeature/${companyuid}`, dataToSubmit);
}
export async function getFeatureList( ) {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/manage/getAllFeature`);
}
export async function updateFeatureList(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/manage/update/featureList`, dataToSubmit);
}