import { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';

import { Modal, Input, Table, Divider, Descriptions, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import type { TableRowSelection } from 'antd/lib/table/interface';

import { RootState } from '../../../_reducers';

import { alertMessage, infoMessage } from '../../utils/utils';
import { createGroup } from '../../../_actions/FuncGroupAction';
import CODE from '../../../constants/code';

export interface DataType {
    funcgroupname: string;
    featureIdxs: React.Key[];
}

export default function GroupAddModal(props: any) {
    const { companyFeature, openModal, closeModal, refreshGroupList } = props;

    const { userData } = useSelector((state: RootState) => state.userReducer);

    const [group, setGroup] = useState<DataType>({
        funcgroupname: '',
        featureIdxs: [],
    });

    const handleCreateGroup = () => {
        if (!group.funcgroupname) {
            return infoMessage('그룹 명을 입력해 주세요.');
        }

        const featureList = companyFeature.map((v: any) => {
            return {
                idx: Number(v.idx),
                permit: 0,
            };
        });

        const selectGroupFunction = group.featureIdxs;

        selectGroupFunction.forEach((idx) => {
            const item = featureList.find((ele: any) => ele.idx === idx);
            if (item) {
                item.permit = 1;
            }
        });

        const requestData = {
            companyuid: userData.companyuid,
            funcgroupname: group.funcgroupname,
            featureStatus: featureList,
        };

        createGroup(requestData).then((res) => {
            if (Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)) {
                infoMessage(`${group.funcgroupname}이 생성 되었습니다.`);
                closeModal();
                refreshGroupList();
            } else {
                alertMessage(res.data.resultMessage || '잠시 후 다시 시도해주세요.');
            }
        });
    };

    const handleCancel = () => {
        alertMessage('그룹 생성이 취소 되었습니다');
        closeModal();
    };

    const columns: ColumnsType<any> = [
        {
            title: '그룹 기능 목록',
            key: 'feature',
            dataIndex: 'feature',
            align: 'center' as 'center',
        },
    ];

    const updateGroup = useCallback(
        (data: any) => {
            setGroup({
                ...group,
                [data.target.name]: data.target.value,
            });
        },
        [group]
    );

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setGroup({ ...group, featureIdxs: newSelectedRowKeys });
    };

    const rowSelection: TableRowSelection<DataType> = {
        selectedRowKeys: group.featureIdxs,
        onChange: onSelectChange,
    };

    return (
        <Modal
            title="그룹 생성"
            maskClosable={false}
            open={openModal}
            onCancel={closeModal}
            footer={[
                <Button key="submit" type="primary" onClick={handleCreateGroup}>
                    생성
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    취소
                </Button>,
            ]}
        >
            <Descriptions>
                <Descriptions.Item label="그룹 명" span={1}>
                    <Input
                        name="funcgroupname"
                        onChange={(e) => {
                            updateGroup(e);
                        }}
                    />
                </Descriptions.Item>
            </Descriptions>
            <Divider />
            <p style={{ textAlign: 'center' }}>
                ※ 스페이스 내에서 사용할 기능을 선택해 주세요. <br />
                (기능 목록은 생성 후 추가 / 수정이 가능합니다.)
            </p>

            <Table
                pagination={false}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={companyFeature}
                rowKey={(render) => render.idx}
            />
        </Modal>
    );
}
