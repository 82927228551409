import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getReadableFileSize } from '../../utils/utils';
import { getDashboard } from '../../../_actions/AdminAction';
import CODE from '../../../constants/code';
// import { getDataList } from '../../../../../_actions/DashboardAction';

export interface dataObject {
    map(arg0: (item: any) => JSX.Element): import("react").ReactNode;
    officialversion_active_company_count: "", 
    testversion_active_company_count: "", 
    total_screentransmission_time_month: "", 
    total_videochattime_month: "", 
    total_access_account_count: "", 
    total_upload_files: "", 
    capacity_used: ""
}

const Title = styled.h3`
    border-bottom: 2px solid red;
    margin-right: 65%;
    padding-bottom: 2px;
    font-weight: 700;
    margin-bottom: 50px;
`;

const LineLayout = styled.div`
    display: grid; 
    grid-template-columns: 40px 250px auto;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
`;

const LineBody = styled.div`
    display: flex;
`;

export default function Dashboard() {
    const [dataList, setDataList] = useState<dataObject | null>();

    useEffect(() => {
        getDashboard().then((response) => {
            let json:any = response;
            if(Number(json.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                setDataList(json.data.dashboardData);
            }
        });
    }, []);

    return (
        <div style={{marginLeft: '2%'}}>
            <Title>MXSpace dashboard</Title>
            <LineLayout>
                <div>1.</div><div>정식버전 활성화 회사 수</div>
                <LineBody>
                    {dataList?.officialversion_active_company_count}
                </LineBody>
            </LineLayout>
            <LineLayout>
                <div>2.</div><div>테스트버전 활성화 회사 수</div>
                <LineBody>
                    {dataList?.testversion_active_company_count}
                </LineBody>
            </LineLayout>
            <LineLayout>
                <div>3.</div><div>전체 사용 화면송출 사용시간/월</div>
                <LineBody>
                    {dataList?.total_screentransmission_time_month}
                </LineBody>
            </LineLayout>
            <LineLayout>
                <div>4.</div><div>전체 사용 화상채팅 사용 시간/월</div>
                <LineBody>
                    {dataList?.total_videochattime_month}
                </LineBody>
            </LineLayout>
            <LineLayout>
                <div>5.</div><div>전체 접속자 계정 수</div>
                <LineBody>
                    {dataList?.total_access_account_count}
                </LineBody>
            </LineLayout>
            <LineLayout>
                <div>6.</div><div>업로드 파일 전체 수&사용 용량</div>
                <LineBody>
                    {dataList?.total_upload_files}/{getReadableFileSize(Number(dataList?.capacity_used))}
                </LineBody>
            </LineLayout>

        </div>
    )
}