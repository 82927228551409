import { Space, Table, Button } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import styled from "styled-components";
import AppLinkAddModal from './AppLinkAddModal';
import AppLinkEditModal from './AppLinkEditModal';
import { useSelector } from "react-redux";
import { fileObject } from '../../webview/file/FileManageCommon';
import { RootState } from '../../../_reducers';
import { alertMessage, confirmMessage, infoMessage } from '../../utils/utils';
import CODE from '../../../constants/code';
import { deleteAppLink, getAppLinkList, getModelFileList } from '../../../_actions/AppLinkAction';


const Title = styled.h3`
    border-bottom: 2px solid red;
    width: 25%;
    padding-bottom: 2px;
    font-weight: 700;
`;

export interface DataType {
    key: React.Key;
    appIndex: number;
    appLink: string;
    appName: string;
    appUuid: string;
    companyuid: string;
    fileuid: string;
}
// wj. 로컬 디비 사용하는 경우 앱 링크는 있지만 파일이 없어서 이슈 생길 수 있음
export default function AppLink(props:any) {
    const [isCreateModal, setIsCreateModal] = useState(false);
    const [isEditModal, setIsEditModal] = useState(false);
    const [appLinkList, setAppLinkList] = useState<DataType[]>([]);
    const [appLink, setAppLink] = useState<DataType>();
    const [isReload, setIsReload] = useState(false);
    const [fileList, setFileList] = useState<fileObject[]>();
    const [selectedFile, setSelectedFile] = useState<fileObject | null>();
    
    const columns: ColumnsType<DataType> = [
        {
            title: 'No',
            dataIndex: 'appIndex',
            key: 'appIndex',
            align: 'center',
            width: 75,
            render: (text, row, index) => {
                return(<div>{index+1}</div>)
            }
        },
        {
            title: 'APP Name',
            dataIndex: 'appName',
            key: 'appName',
            align: 'center',
            width: 200
        },
        {
            title: 'share Link 주소',
            dataIndex: 'appLink',
            key: 'appLink',
            align: 'center',
            render: (text, record) => (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                    {text}
                </div>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 160,
            render: (record) => (
                <Space size="small">
                    <Button onClick={()=>{openEditModal(record)}}>수정</Button>
                    <Button onClick={()=>{handleDeleteAppLink(record)}}>삭제</Button>
                </Space>
            )
        },
    ];

    useEffect(() => {
            getAppLinkList().then((res)=>{
                if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                    setAppLinkList(res.data.applinks);
                } else {
                    alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
                }
                
            });
            getModelFileList().then((res) => {
                if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                    let json:fileObject[] = res.data.modelList;
                    setFileList(json);
                } else {
                    alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
                }

                
            });
    }, [isReload])

    const handleDeleteAppLink = (data:DataType) => {
        confirmMessage("AppLink를 삭제하시겠습니까?", "danger", ()=>{
            deleteAppLink(data).then((res)=>{
                if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                    infoMessage("AppLink를 삭제했습니다.");
                changeReload();
                } else {
                    alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
                }                
            })
        })
    }

    const changeReload = () => {
        setIsReload(!isReload);
    }
    const openCreateModal = () => {
        setIsCreateModal(true);
    }
    const closeCreateModal = () => {
        setIsCreateModal(false);
    }
    const openEditModal = (data:any) => {
        setIsEditModal(true);
        setAppLink(data);
        let fu = data.fileuid;
        let file = fileList?.filter(() => {
            return fileList?.map((m) => m.uid).includes(fu);
        })
        setSelectedFile(file?.shift());
    }
    const closeEditModal = () => {
        setIsEditModal(false);
    }

    return (
        <div style={{marginLeft:'2%'}}>
            <Title>V/S APP Link 등록</Title>
            <div style={{display: "flex", justifyContent: "flex-end", marginBottom: "1rem"}}>
                <Button onClick={openCreateModal}>Create</Button>
            </div>
            <Table columns={columns} dataSource={appLinkList} rowKey={(render)=> render.appIndex} tableLayout={"auto"} />
             {isCreateModal && <AppLinkAddModal 
                closeCreateModal={closeCreateModal} 
                changeReload={changeReload} 
                fileListData={fileList}
            />}
            {isEditModal && <AppLinkEditModal 
                closeEditModal={closeEditModal} 
                changeReload={changeReload} 
                appLinkData={appLink} 
                selectedFile={selectedFile}
            />}
        </div>
    )
}