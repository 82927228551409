import { Modal } from 'antd';
import './utils.css';
import { LegacyButtonType } from 'antd/lib/button/button';

const { confirm } = Modal;

export function getReadableFileSize(fileSizeInBytes: number) {
    var i = -1;
    var byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0).toFixed(1) + byteUnits[i];
};

export function getReadableMBFileSize(fileSizeInBytes: number) {
    fileSizeInBytes = fileSizeInBytes / (1024 * 1024);
    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + 'MB';
};

export function confirmMessage(
    titleName:string | undefined, confirmType:LegacyButtonType | undefined, exceFunction:Function, cancelFunction?:Function
) {
    confirm({
        title: titleName,
        okText: '확인',
        okType: confirmType,
        cancelText: '취소',
        style : {top:'38%', whiteSpace:'pre-line', fontSize:'16px', fontWeight:500},
        onOk() {
            exceFunction();
        },
        onCancel() {
            cancelFunction && cancelFunction();
        },
    });
}
export function alertMessage(params:any) {
    Modal.error({
        content: params,
        okText:"확인",
        style : {top:'38%', whiteSpace:'pre-line', fontSize:'16px', fontWeight:500}
    });  
}

export function infoMessage(params:any) {
    Modal.info({
        title: params,
        okText:"확인",
        style : {top:'38%', whiteSpace:'pre-line', fontSize:'16px', fontWeight:500}
    });
}

export function logInfo(params:any) {
    if(['dev', 'dev-yt', 'prod-yt'].includes(`${process.env.REACT_APP_MODE}`)){
        console.log(params)
    }
}

export const infoModal = (contentText: any, okFunc: any) => {
    return (
        Modal.info({
            icon: null,
            className: "infoModal",
            content: <div style={{textAlign:'center'}}>{contentText || "서버 통신이 원활하지 않습니다."}</div>,
            okText: '확인',
            onOk() { if (okFunc) okFunc() },
            centered: true
        })
    )
}
