import { useEffect } from "react";
import { useNavigate, Outlet } from "react-router";
import * as ROUTES from '../../constants/routes'
import { useSelector } from "react-redux";
import { RootState } from "../../_reducers";
import AUTH from "../../constants/auth";
const ProtectedRoutes:any = ({children, isAllowed=false} : any) => {
    const navigate = useNavigate();   
    const {userData, loginStatus} = useSelector((state : RootState) => state.userReducer);
    useEffect(()=>{
        if(!isAllowed) navigate(ROUTES.FORBIDDEN_PAGE);
        if(!loginStatus || userData.grade < AUTH.indexOf("일반"))  navigate(ROUTES.USER_LOGINPAGE);
    }, [userData, loginStatus])
    return (
        children ? children : <Outlet/>
    )
};

export default ProtectedRoutes;