import { useCallback } from 'react';
import { useDispatch } from "react-redux";
import { logoutUser } from '../../../_actions/UserAction'; 

function Header() {
    const dispatch = useDispatch();
    const logoutUserCallback = useCallback(async () => dispatch(await logoutUser()), [dispatch]); 
    const handleLogout = () => {
        logoutUserCallback()
    }
    return (
        <div style={{ 
            display: 'flex',
            backgroundColor: '#3b5998',
            height: '50px',
            justifyContent: 'flex-end',
            color: 'white',
            alignItems:'center'
        }}>
            <div style={{paddingRight: '25px', color: 'white', cursor:'pointer'}} onClick={()=>handleLogout()}>
                😃 로그아웃
            </div>
        </div> 
    )
}

export default Header